import React, { useState, useEffect } from "react";
import TodolistWrapper from "./todolistWrapper.style";
import { addTodo, updateTodo } from "helper/services/todoService";
import TaskMenu from "components/projects/Menus/TaskMenu";
import { profileBackground } from "helper/methods/getUserAvatarColor";
import RoyTooltip from "components/common/RoyTooltip";
import { ReactComponent as RepeatSvgIcon } from "assets/images/recurrenceIcon.svg";
import { repeatTypeOptions } from "util/data/taskDetailsutilData";
import profileModelAction from "redux/profileModel/actions";
import moment from "moment";
import IconTint from "react-icon-tint";
import TaskDialog from "components/scrumboard/TaskDialog";
import Assignee from "components/assignee";
import AssigneeDropdown from "components/newTask/AssigneeDropdown";
import DatePickerNoInput from "components/DatePickers/DatePickerNoInput";
import InjectPlaceHolderMessage from "helper/methods/PlaceHolderText";
import {
  addTask,
  deleteTask,
  updateTask,
  joinRoom,
  archiveTask
} from "../../services/socket";
import { deleteTodo } from "helper/services/todoService";
import {
  allPriority,
  getPriorityPath,
  getPriorityValue,
  projectSocketRoomPrefix,
  taskSocketRoomPrefix
} from "constatnt";
import CustomAlert from "util/CustomAlert";
import { getColorCode } from "components/projects/utils/color";

import {
  addnew,
  commentnew,
  threeDot_Icon,
  assignnew,
  invite,
  datesnewmodal,
  iconTag,
  ListGridCheckImage,
  ListGridCircleImage,
  basecamp
} from "helper/constant";
import NewTask from "components/newTask/NewTask";
import { useToasts } from "react-toast-notifications";
import ApiCalendar from "react-google-calendar-api";
import ToastMessage from "components/notifications/ToastMessage";

import PriorityPopover from "components/priority/priorityPopover";
import { getCollabData, onChangeTaskAssign } from "helper/methods/QuickActions";
import { connect } from "react-redux";
import IntlMessages from "util/intlMessages";
const { open_profile_modal, close_profile_modal } = profileModelAction;

const TodoList = props => {
  const { addToast } = useToasts();
  const [taskinput, setTaskinput] = useState("");
  const [taskProject, setTaskProject] = useState("");

  const [sectionId, setSectionId] = useState(null);
  const [projectId, setProjectId] = useState(null);
  const [calenderId, setCalenderId] = useState(null);
  const [isCalendarIdUpdated, setIsCalendarIdUpdated] = useState();
  const [todoId, setTodoId] = useState(null);
  const [todo, SetTodo] = useState([]);
  const [taskModel, setTaskModel] = useState(false);
  const [isEditTask, setIsEditTask] = useState({
    status: false,
    data: "",
    action: ""
  });
  const [isQuickActionTask, setIsQuickActionTask] = useState({
    status: false,
    data: "",
    taskId: "",
    selectedData: "",
    action: "",
    target: ""
  });
  const [activeTaskDetail, setActiveTaskDetail] = useState(null);
  const [taskTitleList, setTaskTitleList] = useState("");
  const [taskMenu, setTaskMenu] = useState(false);
  const [activeTaskMenu, setActiveTaskMenu] = useState(null);
  const [isCustomAlert, setCustomAlert] = useState(false);
  const [isDataFlag, setIsDataFlag] = useState(false);
  // const [syncGoogle, setSyncGoogle] = useState(false);
  const [sectionalertMessage, setSectionAlertMessage] = useState(
    "Task will be deleted!"
  );
  const [collaboratorList, setCollaboratorList] = useState([]);
  const {
    setIsTaskinput,
    istaskinput,
    reqTaskId,
    updateLabelToParent,
    setIsGoogleCalenderData,
    isGoogleCalenderData,
    setSyncGoogle,
    syncGoogle,
    projectData,
    setProject
  } = props;

  useEffect(() => {
    if (props.todoList) {
      setSectionId(props.sectionId);
      setProjectId(props.projectId);
      SetTodo(props.todoList);
      setCollaboratorList(props.collaboratorList);
      if (reqTaskId && reqTaskId.status && reqTaskId?.id) {
        handleReqTaskProps(reqTaskId?.id);
      }
    }
    handleChangeProps();

    return () => {
      // setIsEditTask({ status: false, data: "" });
      setIsCalendarIdUpdated();
    };
  }, [props, reqTaskId]);

  const handleChangeProps = async () => {
    if (activeTaskDetail) {
      await Promise.all(
        todo.map((t, i) => {
          if (t.id == activeTaskDetail.id) {
            setActiveTaskDetail(t);
          }
        })
      );
    }
  };
  const handleReqTaskProps = async reqTaskId => {
    await Promise.all(
      todo.map((t, i) => {
        if (t.id == reqTaskId) {
          setTaskModel(true);
          setActiveTaskDetail(t);
        }
      })
    );
  };
  const doOpenTaskDetail = data => {
    props.history.push(`/task-details/${data.id}`);
    //   setActiveTaskDetail(data);
    //   setTodoId(data.id);
    //   // Join task socket room
    //   joinRoom(
    //     `${taskSocketRoomPrefix}${data.id}`
    //   );
    //   setTaskModel(true);
  };
  const updateisCompleted = async (taskStatus, todoId, data) => {
    props.updateIsCompleted(sectionId, todoId, taskStatus, data);
  };
  const handleKeyDown = e => {
    // if (e.keyCode == 13) {
    //   addTaskHandler();
    // }
    if (e.key === "Enter") {
      if (!e.shiftKey) {
        e.preventDefault();
        addTaskHandler();
      }
    }
  };
  const getTodoStyle = (id, i) => {
    if (id == isQuickActionTask.taskId && isQuickActionTask.status) {
      return {
        background: "white",
        borderTopRightRadius: i == 0 ? "10px" : "0px",
        borderTopLeftRadius: i == 0 ? "10px" : "0px",
        borderBottomRightRadius: todo.length - 1 == i ? "10px" : "0px",
        borderBottomLeftRadius: todo.length - 1 == i ? "10px" : "0px",
        paddingTop: i == 0 ? "20px" : "0px",
        paddingBottom: todo.length - 1 == i ? "20px" : "0px",
        boxShadow: "rgb(0 0 0 / 54%) 1px 4px 5px -2px",
        webkitBoxShadow: "rgb(0 0 0 / 54%) 1px 4px 5px -2px",
        mozBoxShadow: "rgb(0 0 0 / 54%) 1px 4px 5px -2px",
        border: "1px solid lightgray",
        transition: "box-shadow 0.3s ease-in-out"
      };
    }
    return {
      background: "white",
      borderTopRightRadius: i == 0 ? "10px" : "0px",
      borderTopLeftRadius: i == 0 ? "10px" : "0px",
      borderBottomRightRadius: todo.length - 1 == i ? "10px" : "0px",
      borderBottomLeftRadius: todo.length - 1 == i ? "10px" : "0px",

      paddingTop: i == 0 ? "20px" : "0px",
      paddingBottom: todo.length - 1 == i ? "20px" : "0px",
      transition: "box-shadow 0.3s ease-in-out"
    };
  };
  const SyncTasktoGoogle = async taskData => {
    if (taskData) console.log(moment(new Date(taskData.scheduledAt)));
    var eventFromNow = {
      summary: taskData?.title?.toString(),
      description: taskData?.description?.toString(),
      start: {
        dateTime: moment(new Date(taskData.scheduledAt)),
        timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone
      },
      end: {
        dateTime: moment(new Date(taskData.scheduledAt)),
        timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone
      }
    };

    if (isGoogleCalenderData == false) {
      return ApiCalendar.createEvent(eventFromNow).then(result => {
        if (result.error) {
          addToast(ToastMessage(result.error.message), {
            appearance: "error",
            autoDismiss: true
          });
        }
        addToast(ToastMessage("Sync to Google"), {
          appearance: "success",
          autoDismiss: true
        });
        return result.result.id;
      });
      // .catch(error => {
      //   addToast(ToastMessage("Something went wrong"), {
      //     appearance: "error",
      //     autoDismiss: true
      //   });
      // });
    } else {
      if (isEditTask && isEditTask?.data && isEditTask?.data?.calendar_id) {
        ApiCalendar.updateEvent(
          eventFromNow,
          isEditTask?.data?.calendar_id
        ).then(result => {
          if (result.error) {
            addToast(ToastMessage(result.error.message), {
              appearance: "error",
              autoDismiss: true
            });
          }
          addToast(ToastMessage("Event is Update"), {
            appearance: "success",
            autoDismiss: true
          });
          return true;
        });
        // .catch(error => {
        //   addToast(ToastMessage("Something went wrong"), {
        //     appearance: "error",
        //     autoDismiss: true
        //   });
        // });
      } else {
        return ApiCalendar.createEvent(eventFromNow).then(result => {
          if (result.error) {
            addToast(ToastMessage(result.error.message), {
              appearance: "error",
              autoDismiss: true
            });
          }
          addToast(ToastMessage("Sync to Google"), {
            appearance: "success",
            autoDismiss: true
          });
          return result.result.id;
        });
        // .catch(error => {
        //   addToast(ToastMessage("Something went wrong"), {
        //     appearance: "error",
        //     autoDismiss: true
        //   });
        // });
      }
    }
  };

  const addTaskHandler = async (payloadInput, syncGoogle) => {
    try {
      if (payloadInput && payloadInput.title) {
        let payload = {
          tempId: payloadInput?.tempId,
          title: payloadInput?.title,
          description: payloadInput?.description,
          label_ids: payloadInput?.label_ids,
          reminderAt: payloadInput?.reminderAt,
          priority: payloadInput?.priority,
          user_id: payloadInput?.user_id,
          scheduledAt: payloadInput?.scheduledAt,
          repeatType: "DO_NOT_REPEAT",
          project_id: props.projectId.toString(),
          section_id: props.sectionId ? props.sectionId.toString() : "",
          roomname: `${projectSocketRoomPrefix}${props.projectId}`,
          calendar_id: ""
        };
        let array = projectData;

        let menupuletedArray = array?.section?.map((item, key) => {
          if (item.id == payload.section_id) {
            let data = {
              ...payloadInput,
              orderId: item.task.length + 1,
              doneSectionId: `${item.id}`,
              id: payload.tempId
            };
            return { ...item, task: [...item.task, data] };
          } else {
            return item;
          }
        });
        let data = { ...projectData, section: menupuletedArray };
        setProject({ ...data });
        if (payloadInput && payloadInput.title && syncGoogle == true) {
          const calendarId = await SyncTasktoGoogle(payload);
          if (payloadInput && payloadInput.title && calendarId) {
            let payloads = {
              ...payloadInput,
              project_id: props.projectId.toString(),
              section_id: props.sectionId ? props.sectionId.toString() : "",
              roomname: `${projectSocketRoomPrefix}${props.projectId}`,
              calendar_id: calendarId ? calendarId : ""
            };
            addTask(payloads, cb => {
              if (cb.status == "Ok") {
                // setIsTaskinput({ status: false, id: null });
                if (
                  payloadInput?.scheduledAt &&
                  payloadInput?.scheduledAt != null &&
                  syncGoogle
                ) {
                  // SyncTasktoGoogle(payload);
                }
                setTaskinput("");
              } else {
                if (cb.status || cb.message) {
                  props.handleResponse(cb.message);
                }
                /**
                 * @TODO
                 * Handle error callback from socket
                 * sample error cb: { status: "Failed", message: "error message" };
                 */
                props.showNotifications();
              }
            });
          }
        } else {
          addTask(payload, cb => {
            if (cb.status == "Ok") {
              // setIsTaskinput({ status: false, id: null });
              if (
                payloadInput?.scheduledAt &&
                payloadInput?.scheduledAt != null &&
                syncGoogle
              ) {
                // SyncTasktoGoogle(payload);
              }
              setTaskinput("");
            }
            // else {
            //   debugger
            //   if (cb.status || cb.message) {
            //     props.handleResponse(cb.message);
            //   }
            //   /**
            //    * @TODO
            //    * Handle error callback from socket
            //    * sample error cb: { status: "Failed", message: "error message" };
            //    */
            //   props.showNotifications();
            // }
          });
        }
      }
    } catch (e) {}
  };
  const doArchiveTask = async id => {
    try {
      if (id) {
        let payload = {
          roomname: `${projectSocketRoomPrefix}${props.projectId}`,
          task_id: id.toString()
        };

        archiveTask(payload, cb => {
          if (cb.status == "Ok") {
            props.showNotifications(cb.message, "success");
            setIsEditTask({ status: false, data: "" });
          } else {
            if (cb.status || cb.message) {
              props.handleResponse(cb.message);
            }
            /**
             * @TODO
             * Handle error callback from socket
             * sample error cb: { status: "Failed", message: "error message" };
             */

            props.showNotifications();
          }
        });
      }
    } catch (e) {}
  };
  const doCloseQuickAction = () => {
    setIsQuickActionTask({
      status: false,
      data: "",
      target: "",
      selectedData: ""
    });
  };
  const doUpdateTaskPriority = async (updatedPriority, taskDetails) => {
    const handleResponse = response => {
      if (response && response?.status.toLowerCase() == "ok") {
        setIsQuickActionTask({
          status: false,
          taskId: "",
          data: "",
          selectedData: "",
          action: "",
          target: ""
        });
      }
    };
    if (updatedPriority) {
      let payload = {
        priority: updatedPriority,
        task_id: isQuickActionTask?.data.id.toString()
      };

      doUpdateTask(payload, handleResponse);
    }
  };
  const doUpdateTaskScheduleDate = async (updatedDueDate, taskDetails) => {
    const handleResponse = response => {
      if (response && response?.status.toLowerCase() == "ok") {
        setIsQuickActionTask({
          status: false,
          taskId: "",
          data: "",
          selectedData: "",
          action: "",
          target: ""
        });
      }
    };
    let payload = {
      scheduledAt: new Date(updatedDueDate),
      task_id: isQuickActionTask?.data.id.toString()
    };

    doUpdateTask(payload, handleResponse);
  };
  const doUpdateTaskAssign = async udpatedAssignee => {
    const handleResponse = response => {
      if (response && response?.status.toLowerCase() == "ok") {
        setIsQuickActionTask({
          status: false,
          taskId: "",
          data: "",
          selectedData: "",
          action: "",
          target: ""
        });
      }
    };

    let payload = {
      user_id: udpatedAssignee,
      task_id: isQuickActionTask?.data.id.toString()
    };

    doUpdateTask(payload, handleResponse);
  };
  const doUpdateTask = (payloadInput, handleResponse) => {
    if (payloadInput) {
      try {
        let payload = {
          ...payloadInput,
          project_id: props.projectId.toString(),
          section_id: props.sectionId ? props.sectionId.toString() : "",
          roomname: `${projectSocketRoomPrefix}${props.projectId}`
        };

        if (!payloadInput.reminderAt && isQuickActionTask.data.reminderAt) {
          payload = {
            ...payload,
            reminderAt: isQuickActionTask.data.reminderAt
          };
        }

        if (
          !payloadInput.hasOwnProperty("scheduledAt") &&
          isQuickActionTask.data.scheduledAt
        ) {
          payload = {
            ...payload,
            scheduledAt: isQuickActionTask.data.scheduledAt
          };
        }

        updateTask(payload, cb => {
          if (cb.status == "Ok") {
            if (handleResponse) {
              handleResponse({
                status: cb.status
              });
            }
            setIsEditTask({ status: false, data: "" });
            if (
              payload?.scheduledAt &&
              payload?.scheduledAt != null &&
              syncGoogle
            ) {
              SyncTasktoGoogle(payload);
            }
            setTaskinput("");
          } else {
            if (cb.status || cb.message) {
              props.handleResponse(cb.message);
            }
            /**
             * @TODO
             * Handle error callback from socket
             * sample error cb: { status: "Failed", message: "error message" };
             */

            props.showNotifications();
          }
        });
      } catch (e) {}
    }
  };
  const updateTaskHandler = async (payloadInput, syncGoogle = false) => {
    try {
      // if (e) {
      //   e.preventDefault();
      // }
      let manupletadObj = {
        title: payloadInput?.title,
        description: payloadInput?.description,
        label_ids: payloadInput?.label_ids,
        reminderAt: payloadInput?.reminderAt,
        priority: payloadInput?.priority,
        user_id: payloadInput?.user_id,
        scheduledAt: payloadInput?.scheduledAt,
        section_id: payloadInput?.section_id,
        project_id: payloadInput?.project_id
      };
      if (manupletadObj.title && isEditTask?.data.id) {
        let payload = {
          // title: taskinput.toString(),
          // description: taskDescription.toString() ? taskDescription : " ",
          // project_id: props.projectId.toString(),
          // label_ids: ids ? ids.toString() : "",
          //  scheduledAt: taskDueDate ? taskDueDate : "" ,
          // reminderAt: taskReminder ? taskReminder : null,
          // priority: taskPriority ? taskPriority : "",
          // user_id: taskAssigned ? taskAssigned.user.id.toString() : "",
          ...manupletadObj,
          project_id: props.projectId.toString(),
          section_id: props.sectionId ? props.sectionId.toString() : "",
          roomname: `${projectSocketRoomPrefix}${props.projectId}`,
          task_id: isEditTask?.data.id.toString()
        };

        updateTask(payload, cb => {
          if (cb.status == "Ok") {
            setIsEditTask({ status: false, data: "" });
            if (
              payload?.scheduledAt &&
              payload?.scheduledAt != null &&
              syncGoogle
            ) {
              SyncTasktoGoogle(payload);
            }
            setTaskinput("");
          } else {
            if (cb.status || cb.message) {
              props.handleResponse(cb.message);
            }
            /**
             * @TODO
             * Handle error callback from socket
             * sample error cb: { status: "Failed", message: "error message" };
             */

            props.showNotifications();
          }
        });
      }
    } catch (e) {}
  };
  const doDeleteTask = async (id, projectId, deleteCalenderId) => {
    try {
      let payload = {
        project_id: projectId.toString(),
        isDeleted: true,
        task_id: id.toString(),
        roomname: `${projectSocketRoomPrefix}${projectId}`
      };
      if (deleteCalenderId && deleteCalenderId) {
        ApiCalendar.deleteEvent(deleteCalenderId).then(result => {
          if (result.error) {
            addToast(ToastMessage(result.error.message), {
              appearance: "error",
              autoDismiss: true
            });
          }
          addToast(ToastMessage("Event is deleted"), {
            appearance: "success",
            autoDismiss: true
          });
          return true;
        });
        // .catch(error => {
        //   addToast(ToastMessage("Something went wrong"), {
        //     appearance: "error",
        //     autoDismiss: true
        //   });
        // });

        deleteTask(payload, cb => {
          if (cb.status == "Ok") {
          } else {
            if (cb.status || cb.message) {
              props.handleResponse(cb.message);
            }
            /**
             * @TODO
             * Handle error callback from socket
             * sample error cb: { status: "Failed", message: "error message" };
             */
            props.showNotifications();
          }
        });
      } else {
        deleteTask(payload, cb => {
          if (cb.status == "Ok") {
          } else {
            if (cb.status || cb.message) {
              props.handleResponse(cb.message);
            }
            /**
             * @TODO
             * Handle error callback from socket
             * sample error cb: { status: "Failed", message: "error message" };
             */
            props.showNotifications();
          }
        });
      }
    } catch (e) {}

    // const Response = await deleteTodo(id, projectId);
    // if (Response) {
    //   if (Response.error) {
    //
    //   }
    //   if (Response.data) {
    //
    //     if (Response.data.isDeleted) {
    //       removeTaskFromList(Response.data.id);
    //       props.showNotifications("Task deleted");
    //     }
    //   }
    // }
  };

  const removeTaskFromList = id => {
    const Arr = todo;
    Arr.map((data, i) => {
      if (data.id == id) {
        Arr.splice(i, 1);
      }
    });

    SetTodo(Arr);
  };

  const refreshTodoList = newTodo => {
    let todoArr = todo;
    todoArr.map((item, index) => {
      if (item.id == newTodo.id) {
        todoArr[index] = newTodo;
      }
    });
    SetTodo(todoArr);
  };
  const updateCommentCount = (id, type) => {
    let todoArr = todo;
    todoArr.map((item, index) => {
      if (item.id == id) {
        let count = parseInt(todoArr[index].commentCount);
        if (type == "delete_comment") {
          todoArr[index].commentCount = count - 1;
        }
        if ((type = "new_comment")) {
          todoArr[index].commentCount = count + 1;
        }
      }
    });
    SetTodo(todoArr);
  };
  const toggleTaskMenu = (target, taskData) => {
    if (taskMenu) {
      setTaskMenu(!taskMenu);
      setActiveTaskMenu(null);
      setActiveTaskDetail(null);
    } else {
      setTaskMenu(!taskMenu);
      setActiveTaskMenu(target);
      setActiveTaskDetail(taskData);
    }
  };

  const LabelsComponent = labelsArr => {
    let sortLabels = [];
    sortLabels =
      labelsArr && labelsArr.sort((a, b) => a.name.localeCompare(b.name));
    return (
      <>
        {sortLabels &&
          sortLabels
            .sort((a, b) => a.name < b.name)
            .map((item, index) => {
              return (
                <>
                  <div
                    className="my-day-label flex-x  fs-14 nevy--text align-center overflow-hidden"
                    style={{
                      margin: "3px 25px  3px 0px"
                    }}
                  >
                    <div
                      style={{
                        height: 20,
                        width: 18,
                        marginRight: 5
                      }}
                    >
                      <IconTint
                        src={iconTag}
                        style={{
                          position: "relative",
                          top: "1px",
                          height: "18",
                          width: "18"
                        }}
                        color={getColorCode(item.color)}
                      />
                    </div>
                    {/* <i
                                        className="fa fa-tag"
                                        style={{
                                          color: getColorCode(item.color),
                                          position: "relative",
                                          top: "1px"
                                        }}
                                      /> */}
                    <span
                      style={{
                        // color: getColorCode(item.color),
                        color: "black",
                        paddingLeft: 5,
                        maxWidth: "300px",
                        whiteSpace: "nowrap",
                        textOverflow: " ellipsis",
                        overflow: "hidden"
                      }}
                    >
                      <span>{item.name}</span>
                    </span>
                  </div>
                </>
              );
            })}
      </>
    );
  };
  return (
    <div>
      <TodolistWrapper>
        <div className="todo-container flex-y" key={"ss"}>
          {todo &&
            todo.length > 0 &&
            todo
              .sort((a, b) => a.createdAt - b.createdAt)
              .map((data, i) => {
                if (!data.isArchived && !data.isDeleted) {
                  return (
                    <>
                      {isEditTask?.status &&
                      isEditTask?.data?.id === data.id ? (
                        <div
                          style={{
                            paddingTop: 5,
                            backgroundColor: "white",
                            borderRadius: "5px",
                            border: "1px solid #c6c2c2",
                            position: "relative"
                          }}
                          // onBlur={e => {
                          //
                          //
                          //   if (e.relatedTarget) {
                          //
                          //   } else {
                          //
                          //     setIsTaskinput(false);
                          //   }
                          // }}
                        >
                          <NewTask
                            updateTask
                            updateLabelToParent={updateLabelToParent}
                            action={isEditTask?.action || ""}
                            toggleAction={() =>
                              setIsEditTask({
                                ...isEditTask,
                                action: ""
                              })
                            }
                            collaboratorList={props.collaboratorList}
                            toggle={() => {
                              setIsEditTask({
                                status: false,
                                data: ""
                              });
                              setIsTaskinput({
                                status: false,
                                id: null
                              });
                            }}
                            taskData={isEditTask.data}
                            // onKeyDown={handleKeyDown}
                            addTaskHandler={updateTaskHandler}
                            taskProject={taskProject}
                            setTaskProject={data => setTaskProject(data)}
                            setIsGoogleCalenderData={setIsGoogleCalenderData}
                            setCalenderId={setCalenderId}
                            setSyncGoogle={setSyncGoogle}
                            syncGoogle={syncGoogle}
                          />
                        </div>
                      ) : (
                        <div
                          key={i}
                          className="flex-x my-todo align-center plr-30  taskHover"
                          style={getTodoStyle(data.id, i)}
                        >
                          <div
                            className="flex-1"
                            style={{
                              cursor: "pointer",
                              paddingBottom:
                                todo.length - 1 == i ? "0px" : "10px",
                              paddingTop: i == 0 ? "0px" : "10px",
                              borderBottom:
                                todo.length - 1 == i ? "" : "1px solid #ebebeb"
                            }}
                          >
                            <div
                              style={{
                                display: "flex"
                              }}
                            >
                              <div
                                className="flex-x"
                                style={{
                                  marginTop: 5
                                }}
                              >
                                <img
                                  alt=""
                                  onClick={e =>
                                    updateisCompleted(
                                      !data.isCompleted,
                                      data.id,
                                      data
                                    )
                                  }
                                  src={
                                    data.isCompleted
                                      ? ListGridCheckImage
                                      : ListGridCircleImage
                                  }
                                  style={{
                                    height: 18,
                                    width: 18,
                                    marginRight: 10
                                  }}
                                />
                              </div>
                              <div
                                className="flex-x flex-1 flex-wrap align-center fs-14"
                                onClick={() => {
                                  doOpenTaskDetail(data);
                                }}
                              >
                                <span
                                  title={data.title}
                                  className="All_Task_Title fs-14"
                                  style={{
                                    maxWidth: "50%",
                                    color: "black",
                                    fontSize: "14px",
                                    margin: "3px 25px  3px 0px",
                                    display: "-webkit-box",
                                    WebkitBoxOrient: "vertical",
                                    lineClamp: 1,
                                    textOverflow: "ellipsis",
                                    overflow: "hidden",
                                    WebkitLineClamp: 1,
                                    WebkitFontSmoothing: "antialiased"
                                  }}
                                  onClick={() => {
                                    doOpenTaskDetail(data);
                                  }}
                                >
                                  {data.title}
                                </span>
                                {data &&
                                  data.priority &&
                                  data.priority != "no_priority" && (
                                    <div
                                      style={{
                                        margin: "3px 25px  3px 0px"
                                      }}
                                      // id="PUncontrolledPopover"
                                      className={
                                        "my-day-label flex-x  fs-14 nevy--text align-center overflow-hidden"
                                      }
                                      onClick={() => {
                                        doOpenTaskDetail(data);
                                      }}
                                    >
                                      {data.priority !== "no_priority" && (
                                        <>
                                          <img
                                            src={getPriorityPath(data.priority)}
                                            height="17"
                                            width="18"
                                            className=""
                                            alt="Priority"
                                            style={{
                                              position: "relative"
                                              // top: "2px"
                                            }}
                                          />
                                          <span
                                            style={{
                                              cursor: "pointer",
                                              paddingLeft: "3px ",
                                              color: "black",
                                              position: "relative",
                                              top: "-1px"
                                            }}
                                          >
                                            {getPriorityValue(data.priority)}
                                          </span>
                                        </>
                                      )}
                                    </div>
                                  )}
                                {LabelsComponent(data.labels)}
                                <div
                                  style={{
                                    margin: "3px 25px  3px 0px"
                                  }}
                                  className={`my-day-label flex-x  fs-14 nevy--text align-center overflow-hidden ${
                                    data.labels && data.labels?.length
                                      ? "label"
                                      : ""
                                  }`}
                                  onClick={() => {
                                    doOpenTaskDetail(data);
                                  }}
                                >
                                  {/* <i
                          className="fa fa-comment-dots"
                          style={{ color: "grey",border:"1px solid red" }}
                        /> */}

                                  <img
                                    alt=""
                                    src={commentnew}
                                    style={{
                                      height: "14px",
                                      width: "14px",
                                      marginRight: "0px",
                                      marginTop: "0px",
                                      textAlign: "center",
                                      position: "relative",
                                      top: "1px"
                                    }}
                                  ></img>
                                  <span
                                    style={{
                                      color: "grey",
                                      paddingLeft: 5
                                    }}
                                  >
                                    {data.commentCount ? data.commentCount : 0}
                                  </span>
                                </div>
                                {/* {data.basecampTodoId != null ||
                                data.basecampTodoId != undefined ? (
                                  <div className="count-space">
                                    {" "}
                                    <img
                                      src={basecamp}
                                      style={{
                                        height: "15px",
                                        width: "15px",
                                        marginLeft: "-1.5rem",
                                        borderRadius: "5px"
                                      }}
                                    ></img>
                                  </div>
                                ) : (
                                  ""
                                )} */}
                                <div
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                    margin: "3px 0px  3px 0px"
                                  }}
                                  onClick={() => {
                                    doOpenTaskDetail(data);
                                  }}
                                >
                                  {data.scheduledAt && (
                                    <img
                                      alt=""
                                      src={datesnewmodal}
                                      height="15px"
                                      width="15px"
                                      style={{
                                        marginRight: 5
                                      }}
                                    />
                                  )}
                                  {data.scheduledAt &&
                                    data.hasOwnProperty("repeatTill") &&
                                    data.repeatType !==
                                      repeatTypeOptions[0].value && (
                                      <RepeatSvgIcon
                                        style={{
                                          fill: "green",
                                          height: "16px",
                                          width: "15px"
                                        }}
                                      />
                                    )}
                                  {data.scheduledAt && (
                                    <div
                                      className="due-date label"
                                      style={{
                                        marginLeft: 5,
                                        marginRight: 25
                                      }}
                                    >
                                      <IntlMessages id={"task-details.dueon"} />
                                      &nbsp;
                                      {moment(
                                        new Date(data.scheduledAt)
                                      ).format("DD MMM YYYY")}
                                    </div>
                                  )}
                                </div>
                                <div
                                  style={{
                                    display: "contents",
                                    alignItems: "center"
                                  }}
                                >
                                  {data.taskAssign &&
                                    data.taskAssign.length > 0 && (
                                      <>
                                        <div
                                          style={{
                                            display: "contents",
                                            alignItems: "center"
                                          }}
                                        >
                                          {data.taskAssign &&
                                            data.taskAssign.map(ta => {
                                              return (
                                                <>
                                                  <div
                                                    style={{
                                                      display: "flex",
                                                      alignItems: "center",
                                                      margin:
                                                        "3px 10px  3px 0px"
                                                    }}
                                                    // onClick={() => {
                                                    //   props.open_profile_modal({
                                                    //     profile: ta
                                                    //   });
                                                    // }}
                                                  >
                                                    {ta.thumbnail ? (
                                                      <img
                                                        alt=""
                                                        title={ta.name}
                                                        id="userAssigned"
                                                        src={
                                                          ta.thumbnail
                                                            ? ta.thumbnail
                                                            : null
                                                        }
                                                        style={{
                                                          height: 20,
                                                          width: 20,
                                                          borderRadius: "50%",
                                                          border:
                                                            "1px solid white",
                                                          objectFit: "cover"
                                                        }}
                                                        onClick={e => {
                                                          if (
                                                            e.target ==
                                                            e.currentTarget
                                                          ) {
                                                            e.stopPropagation();
                                                            props.open_profile_modal(
                                                              {
                                                                profile: ta
                                                              }
                                                            );
                                                          }
                                                        }}
                                                      />
                                                    ) : (
                                                      <div
                                                        title={ta.name}
                                                        style={profileBackground(
                                                          ta.name
                                                            ? ta.name[0]
                                                            : ta.email[0],
                                                          20,
                                                          20,
                                                          12
                                                        )}
                                                        onClick={e => {
                                                          if (
                                                            e.target ==
                                                            e.currentTarget
                                                          ) {
                                                            e.stopPropagation();
                                                            props.open_profile_modal(
                                                              {
                                                                profile: ta
                                                              }
                                                            );
                                                          }
                                                        }}
                                                      >
                                                        <div
                                                          onClick={e => {
                                                            if (
                                                              e.target ==
                                                              e.currentTarget
                                                            ) {
                                                              e.stopPropagation();
                                                              props.open_profile_modal(
                                                                {
                                                                  profile: ta
                                                                }
                                                              );
                                                            }
                                                          }}
                                                        >
                                                          {ta.name
                                                            ? ta.name[0].toUpperCase()
                                                            : ta.email[0].toUpperCase()}
                                                        </div>
                                                      </div>
                                                    )}
                                                    <div
                                                      className=" fs-12"
                                                      style={{
                                                        paddingLeft: 2,
                                                        display: "inline-block",
                                                        maxWidth: "115px",
                                                        whiteSpace: "nowrap",
                                                        overflow: "hidden",
                                                        textOverflow: "ellipsis"
                                                      }}
                                                      onClick={e => {
                                                        if (
                                                          e.target ==
                                                          e.currentTarget
                                                        ) {
                                                          e.stopPropagation();
                                                          props.open_profile_modal(
                                                            {
                                                              profile: ta
                                                            }
                                                          );
                                                        }
                                                      }}
                                                    >
                                                      {ta.name}
                                                    </div>
                                                  </div>
                                                </>
                                              );
                                            })}
                                        </div>
                                        {/* </RoyTooltip> */}
                                      </>
                                    )}
                                </div>
                              </div>{" "}
                              <div
                                style={{
                                  height: 1,
                                  width: "3%"
                                }}
                              >
                                <div>
                                  {/*     
                                    shadow for edit quick actions task
                                    box-shadow: 0px 3px 6px 1px rgb(0 0 0 / 54%);
    -webkit-box-shadow: 0px 4px 3px 0px rgb(0 0 0 / 54%);
    -moz-box-shadow: 0px 3px 6px 1px rgb(0 0 0 / */}
                                  <div id={`taskmenu-labels-${data.id}`} />
                                  <div id={`taskmenu-priority-${data.id}`} />

                                  {isQuickActionTask &&
                                    isQuickActionTask.status &&
                                    isQuickActionTask.action == "assign" &&
                                    isQuickActionTask.target ==
                                      `taskmenu-assign-${data.id}` && (
                                      <>
                                        <AssigneeDropdown
                                          isDisableDropDownIcon
                                          TargetId="assing-"
                                          setDropdownOpen={() => {
                                            doCloseQuickAction();
                                          }}
                                          businessId={
                                            props.auth.user.businessId
                                          }
                                          projectId={
                                            (isQuickActionTask.status &&
                                              isQuickActionTask.data.project
                                                ?.id) ||
                                            ""
                                          }
                                          dropdownOpen={
                                            isQuickActionTask &&
                                            isQuickActionTask.status
                                          }
                                          currentLoggedIn={props.auth?.user?.id}
                                          projectCollaborators={
                                            props.collaboratorList
                                          }
                                          userAssigned={
                                            isQuickActionTask.selectedData
                                              ? isQuickActionTask.selectedData
                                              : ""
                                          }
                                          setUserAssigned={data => {
                                            onChangeTaskAssign(
                                              data,
                                              doUpdateTaskAssign
                                            );
                                            // onChangeTaskAssign(data);
                                          }}
                                        />
                                      </>
                                    )}
                                  {isQuickActionTask &&
                                    isQuickActionTask.status &&
                                    isQuickActionTask.action == "schedule" &&
                                    isQuickActionTask.target ==
                                      `taskmenu-schedule-${data.id}` && (
                                      <div
                                        style={{
                                          position: "relative"
                                        }}
                                      >
                                        <DatePickerNoInput
                                          // AllowAddPreviousDateTask={AllowAddPreviousDateTask}
                                          dueDate={
                                            isQuickActionTask.selectedData
                                              ? isQuickActionTask.selectedData
                                              : ""
                                          }
                                          onUpdateDate={
                                            doUpdateTaskScheduleDate
                                          }
                                          toggle={doCloseQuickAction}
                                          isOpen={isQuickActionTask.status}
                                          PRIMARY_COLOR={props.PRIMARY_COLOR}
                                        />
                                      </div>
                                    )}
                                </div>
                                <div
                                  id={`taskmenu-${data.id}`}
                                  onClick={() => {
                                    toggleTaskMenu(`taskmenu-${data.id}`, data);
                                    setTaskTitleList(data);
                                  }}
                                  className="cursor-pointer taskOptions task-hover"
                                  style={{
                                    textAlign: "end",
                                    padding: "4px 30px 0px 9px",
                                    marginLeft: "11px",
                                    borderRadius: "5px"
                                  }}
                                >
                                  <img
                                    alt=""
                                    src={threeDot_Icon}
                                    style={{
                                      height: 6,
                                      width: 22,
                                      marginBottom: 6
                                    }}
                                  ></img>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}{" "}
                    </>
                  );
                }
              })}

          {istaskinput &&
          istaskinput.status &&
          props.sectionId &&
          istaskinput.id == props.sectionId ? (
            <div
              style={{
                paddingTop: 5,
                backgroundColor: "white",
                borderRadius: "5px",
                border: "1px solid #c6c2c2",
                position: "relative",
                marginTop: 15
              }}
              // onBlur={e => {
              //
              //
              //   if (e.relatedTarget) {
              //
              //   } else {
              //
              //     setIsTaskinput(false);
              //   }
              // }}
            >
              <NewTask
                hasClearEnabledOnAddTask
                setSyncGoogle={setSyncGoogle}
                syncGoogle={syncGoogle}
                updateLabelToParent={updateLabelToParent}
                action={isEditTask?.action || ""}
                toggleAction={() =>
                  setIsEditTask({ ...isEditTask, action: "" })
                }
                collaboratorList={props.collaboratorList}
                toggle={() => {
                  setIsEditTask({ status: false, data: "" });
                  setIsTaskinput({ status: false, id: null });
                }}
                // taskData={isEditTask.data}
                // onKeyDown={handleKeyDown}
                addTaskHandler={addTaskHandler}
                taskProject={taskProject}
                setTaskProject={data => setTaskProject(data)}
                setIsGoogleCalenderData={setIsGoogleCalenderData}
                setCalenderId={setCalenderId}
              />
            </div>
          ) : (
            <div
              className="list-name flex-x align-center  todo-topborder cursor-pointer"
              style={{
                display: "flex",
                alignItems: "baseline",
                padding: "10px 30px 0px"
              }}
            >
              <div className="mr-6 flex-x icon cursor-pointer">
                <img
                  alt=""
                  src={addnew}
                  style={{
                    height: "10px",
                    width: "10px"
                  }}
                ></img>
              </div>

              <div className="mr-10 flex-1 cursor-pointer">
                <input
                  // value={taskinput}
                  onFocus={() =>
                    setIsTaskinput({
                      status: true,
                      id: props.sectionId
                    })
                  }
                  onChange={e => {
                    setTaskinput(e.target.value);
                    setIsTaskinput(false);
                    setIsTaskinput({
                      status: true,
                      id: props.sectionId
                    });
                  }}
                  className="task-add-input fs-16 fill-width cursor-pointer"
                  type="text"
                  placeholder={InjectPlaceHolderMessage(
                    "today.newtask",
                    props?.LanguageSwitcher?.language?.languageId
                  )}
                  onKeyDown={handleKeyDown}
                />
              </div>
            </div>
          )}
        </div>
      </TodolistWrapper>

      {activeTaskDetail && (
        <TaskDialog
          updateLabelToParent={updateLabelToParent}
          {...props}
          showTaskAssign={true}
          updateCommentCount={(count, id) => updateCommentCount(count, id)}
          taskModel={taskModel}
          setTaskModel={() => {
            setTaskModel(!taskModel);
            setActiveTaskDetail(null);
          }}
          taskDetail={activeTaskDetail}
          updateActiveTaskDetail={data => {
            setActiveTaskDetail(data);

            refreshTodoList(data);
          }}
          projectId={props.projectId}
          sectionId={props.sectionId}
          sectionTitle={props.sectionTitle}
          todoId={activeTaskDetail.id}
          updateTodoList={newTodo => refreshTodoList(newTodo)}
          collaboratorList={collaboratorList}
          showNotifications={() => props.showNotifications()}
        />
      )}
      <TaskMenu
        todo={todo}
        projectTask
        isMenu={taskMenu}
        activeTaskDetail={activeTaskDetail}
        toggleMenu={() => toggleTaskMenu()}
        viewTaskDetails={() => {
          // props.history.push(`/task-details/${activeTaskDetail.id}`);
          doOpenTaskDetail(activeTaskDetail);
          // setActiveTaskDetail({ ...activeTaskDetail, action: "taskactivity" });
          // setTaskModel(true);
          // setTaskMenu(false);
          // setActiveTaskMenu("");
        }}
        doArchiveTask={() => doArchiveTask(activeTaskDetail?.id)}
        target={activeTaskMenu}
        editLabels={() => {
          setIsEditTask({
            status: true,
            data: activeTaskDetail,
            action: "label"
          });
        }}
        editAssign={() => {
          // taskmenu-assign-
          if (activeTaskDetail && activeTaskDetail.id) {
            setIsQuickActionTask({
              status: true,
              action: "assign",
              taskId: activeTaskDetail.id,
              target: `taskmenu-assign-${activeTaskDetail.id}`,
              selectedData:
                activeTaskDetail && activeTaskDetail.taskAssign
                  ? { user: activeTaskDetail?.taskAssign }
                  : "",
              data: activeTaskDetail
            });
          }
          // setIsEditTask({
          //   status: true,
          //   data: activeTaskDetail,
          //   action: "taskassign"
          // });
        }}
        editPriority={() => {
          if (activeTaskDetail && activeTaskDetail.id) {
            setIsQuickActionTask({
              status: true,
              taskId: activeTaskDetail.id,
              action: "priority",
              target: `taskmenu-priority-${activeTaskDetail.id}`,
              selectedData: activeTaskDetail && activeTaskDetail.priority,
              data: activeTaskDetail
            });
          }
          // setIsEditTask({
          //   status: true,
          //   data: activeTaskDetail,
          //   action: "priority"
          // });
        }}
        editDate={() => {
          setIsQuickActionTask({
            status: true,
            taskId: activeTaskDetail.id,
            action: "schedule",
            target: `taskmenu-schedule-${activeTaskDetail.id}`,
            selectedData: activeTaskDetail && activeTaskDetail.scheduledAt,
            data: activeTaskDetail
          });
        }}
        // setIsEditTask({
        //   status: true,
        //   data: activeTaskDetail,
        //   action: "schedule"
        // });
        // }}
        editTask={() => {
          if (taskMenu) {
            setIsEditTask({ status: true, data: activeTaskDetail });
          } else {
            setIsEditTask({ status: false, data: "" });
          }
          // setTaskModel(!taskModel);
          setTaskMenu(!taskMenu);
          // setActiveTaskDetail(null);
        }}
        deleteTask={id => {
          setTaskMenu(!taskMenu);
          setCustomAlert(true);
        }}
      />
      <CustomAlert
        CustomMessage={taskTitleList.title}
        action="delete"
        messageType="task"
        PRIMARY_COLOR={props.PRIMARY_COLOR}
        show={isCustomAlert}
        onConfirm={() => {
          doDeleteTask(
            activeTaskDetail.id,
            projectId,
            taskTitleList.calendar_id
          );
          setCustomAlert(false);
        }}
        onCancel={() => setCustomAlert(false)}
        alertMessage={sectionalertMessage}
      />
      {isQuickActionTask &&
        isQuickActionTask.status &&
        isQuickActionTask.action == "priority" && (
          <PriorityPopover
            isOpen={isQuickActionTask.status}
            toggle={doCloseQuickAction}
            target={
              isQuickActionTask.status && isQuickActionTask.target
                ? isQuickActionTask.target
                : "priority-task-details"
            }
            selectedPriority={
              isQuickActionTask?.status && isQuickActionTask.selectedData
                ? isQuickActionTask.selectedData
                : "no_priority"
            }
            updatePriority={doUpdateTaskPriority}
            PRIMARY_COLOR={props.PRIMARY_COLOR}
          />
        )}
      {/* {isQuickActionTask && isQuickActionTask.status && isQuickActionTask.action == "labels" &&
        <LabelsPopover
        isOpen={isQuickActionTask.status}
        toggle={doCloseQuickAction}
        target={isQuickActionTask.status && isQuickActionTask.target ? isQuickActionTask.target : "priority-task-details"}
        selectedLabels={isQuickActionTask?.status && isQuickActionTask.selectedData ? isQuickActionTask.selectedData :[]}
        selectLabelHandler={selectLabelHandler}
        updateLabelToParent={data => {
          if (updateLabelToParent) {
            updateLabelToParent(data);
          }
        }}
      />} */}
    </div>
  );
};

export default connect(null, { open_profile_modal })(TodoList);
