import handleGlobalSearchResponse, {
  encryptPayload
} from "./handleGlobalSearchResponse";
import { BASE_URL, project, REACT_APP_API_ENDPOINT } from "./apiConstants";
import { getHeader, getHeaderForProfile } from "./getHeaders";

export function retrieveSearch(query, token) {
  var givenToken = null;
  if (token) {
    givenToken = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`
    };
  } else {
    givenToken = getHeader();
  }

  const requestOptions = {
    method: "GET",
    headers: givenToken
  };
  return fetch(
    REACT_APP_API_ENDPOINT + project + `/search/${query}`,
    requestOptions
  )
    .then(handleGlobalSearchResponse)
    .then(data => {
      return data;
    });
}
export function retrieveGlobalSearch(data, token) {
  const encryptedData = encryptPayload(data);
  const encryptObj = { encPayload: encryptedData };
  var givenToken = null;
  if (token) {
    givenToken = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`
    };
  } else {
    givenToken = getHeader();
  }

  const requestOptions = {
    method: "POST",
    headers: givenToken,
    body: JSON.stringify(encryptObj)
  };
  return fetch(
    REACT_APP_API_ENDPOINT + project + `/global-search/`,
    requestOptions
  )
    .then(handleGlobalSearchResponse)
    .then(data => {
      return data;
    });
}

export function retrieveWorkspaceGlobalSearch(query, token, limit, page) {
  var givenToken = null;
  if (token) {
    givenToken = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`
    };
  } else {
    givenToken = getHeader();
  }

  const requestOptions = {
    method: "GET",
    headers: givenToken
  };
  return fetch(
    REACT_APP_API_ENDPOINT +
      project +
      `/workspace-global/search?searchstring=${query}&limit=${
        limit ? limit : 4
      }&page=${page ? page : 1}`,
    requestOptions
  )
    .then(handleGlobalSearchResponse)
    .then(data => {
      return data;
    });
}

export function retrieveProjectGlobalSearch(query, token, limit, page) {
  var givenToken = null;
  if (token) {
    givenToken = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`
    };
  } else {
    givenToken = getHeader();
  }

  const requestOptions = {
    method: "GET",
    headers: givenToken
  };
  return fetch(
    REACT_APP_API_ENDPOINT +
      project +
      `/project-global/search?searchstring=${query}&limit=${
        limit ? limit : 4
      }&page=${page ? page : 1}`,
    requestOptions
  )
    .then(item => handleGlobalSearchResponse(item))
    .then(data => {
      return data;
    });
}

export function retrieveTaskGlobalSearch(query, token, limit, page) {
  var givenToken = null;
  if (token) {
    givenToken = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`
    };
  } else {
    givenToken = getHeader();
  }

  const requestOptions = {
    method: "GET",
    headers: givenToken
  };
  return fetch(
    REACT_APP_API_ENDPOINT +
      project +
      `/task-global/search?searchstring=${query}&limit=${
        limit ? limit : 4
      }&page=${page ? page : 1}`,
    requestOptions
  )
    .then(item => handleGlobalSearchResponse(item))
    .then(data => {
      return data;
    });
}
export function retrieveBusinessFilter(data, token) {
  const encryptedData = encryptPayload(data);
  const encryptObj = { encPayload: encryptedData };
  var givenToken = null;
  if (token) {
    givenToken = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`
    };
  } else {
    givenToken = getHeader();
  }
  const requestOptions = {
    method: "POST",
    headers: givenToken,
    body: JSON.stringify(encryptObj)
  };
  return fetch(
    REACT_APP_API_ENDPOINT + project + `/global-search-business-filter`,
    requestOptions
  )
    .then(handleGlobalSearchResponse)
    .then(data => {
      return data;
    });
}
