import React, { useState } from "react";
import ProjectCardWrapper from "./ProjectCardWrapper.style";
import { archive, threeDot_Icon, CardTaskCalenderImage } from "helper/constant";
import IconTint from "react-icon-tint";
import { Popover, PopoverBody } from "reactstrap";
import { getColorCode } from "components/projects/utils/color.jsx";

import moment from "moment";
import IntlMessages from "util/intlMessages";
const ProjectCard = props => {
  const { project, handleArchive, handleGoTOProject } = props;
  const [optionMenu, setOptionMenu] = useState({ status: false, id: null });
  const getOverDueProjectDate = endDate => {
    if (endDate) {
      const checkDate = moment().diff(new Date(endDate), "days");
      if (checkDate > 0) {
        return (
          <div style={{ display: "flex", alignItems: "center" }}>
            <span
              style={{
                marginRight: 5
              }}
            >
              <IconTint
                maxHeight="12"
                maxWidth="12"
                src={CardTaskCalenderImage}
                color="red"
              />
            </span>
            {/* <img
              src={CardTaskCalenderImage}
              style={{ marginRight: 5, height: 12, width: 12 }}
            ></img> */}
            <div className="text-overdue">
              {checkDate}&nbsp;
              <IntlMessages id={"project.day"} />
              {checkDate == 1 ? "" : <IntlMessages id={"project.s"} />}&nbsp;
              <IntlMessages id={"project.overdue"} />
            </div>
            {/* <div className="text-overdue">{`${checkDate} day${
              checkDate == 1 ? "" : "s"
            } overdue`}</div> */}
          </div>
        );
      }
      if (checkDate < 0) {
        return "";
        // return (
        //   <div className="text-remaining">{`${Math.abs(
        //     checkDate
        //   )} days remaining`}</div>
        // );
      }
      if (checkDate == 0) {
        return (
          <div style={{ display: "flex", alignItems: "center" }}>
            <span
              style={{
                marginRight: 5
              }}
            >
              <IconTint
                maxHeight="12"
                maxWidth="12"
                src={CardTaskCalenderImage}
                color="orange"
              />
            </span>
            {/* <img
              src={CardTaskCalenderImage}
              style={{ marginRight: 5, height: 12, width: 12 }}
            ></img> */}
            <div className="text-due-today">Due today</div>
          </div>
        );
      }
    }
    return "";
  };
  return (
    <>
      {project && (
        <div
          onClick={handleGoTOProject}
          style={{ paddingBottom: 15, cursor: "pointer" }}
          className={"col-lg-4 col-sm-12 col-xs-12 col-md-4 "}
          // onClick={() => handleGoTOProject(project.id)}
        >
          <div
            className="project-card-container"
            style={{
              borderTop: `5px solid ${getColorCode(project.color)}`,
              padding: "15px 15px 10px 15px",
              borderRadius: "6px",
              boxShadow:
                "rgb(90 113 208 / 11%) 0px 0px 0px 0px, rgb(167 175 183 / 25%) 0px 4px 16px 0px",
              minHeight: "124px"
              // boxShadow: `${getColorCode(project.color)}  0px 0px 0px 0px,${getColorCode(project.color)} 1px 1px 5px -1px`
            }}
          >
            <div
              className={"row"}
              style={{ minHeight: 70, padding: "0px 15px" }}
            >
              {/* <div className={"col-lg-2 col-sm-2 col-xs-2 col-md-2 plr-5"}> */}
              {/* <div
                className="project-icon"
                style={{ padding: "0px 6px 0px 0px" }}
              >
                <i
                  className="fa fa-circle fs-10"
                  style={{
                    color: getColorCode(project.color),
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center"
                  }}
                /> */}
              {/* <div
                      style={{
                        position: "absolute",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        opacity: 0.5
                      }}
                    >
                      <div
                        style={{
                          height: 26,
                          width: 26,
                          backgroundColor: getColorCode(project.color),
                          borderRadius: "50%",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          marginTop: "1px"
                        }}
                      ></div>
                    </div> */}
              {/* </div>   */}
              {/* </div> */}
              <div
                className={"col-lg-10 col-sm-10 col-xs-10 col-md-10"}
                style={{
                  paddingLeft: 0,
                  paddingRight: 20,
                  position: "relative",
                  top: -7
                }}
              >
                <div
                  className="project-title"
                  // style={{ paddingLeft:15}}
                >
                  {project.name}
                </div>
                <ProjectCardWrapper>
                  <div
                    // style={{ paddingLeft:15}}
                    className="project-description"
                    dangerouslySetInnerHTML={{
                      __html:
                        project.description !== null &&
                        project.description !== "null"
                          ? project.description
                          : ""
                    }}
                  />
                </ProjectCardWrapper>
              </div>
            </div>
            <div
              className="row "
              style={{ paddingTop: 0, display: "flex", alignItems: "center" }}
            >
              <div className="col-lg-3 col-sm-3 col-xs-0 col-md-3">
                <div style={{ paddingLeft: 0 }}>
                  {project.isFavorite && (
                    <i
                      className="fa fa-heart fs-12"
                      style={{ color: "red" }}
                    ></i>
                  )}
                </div>
              </div>

              <div
                className="col-lg-9 col-sm-9 col-xs-12 col-md-9 fs-13"
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  padding: "0px 15px 0px 0px"
                }}
              >
                {/* <img
                  src={CardTaskCalenderImage}
                  style={{marginRight:5}}
                ></img> */}
                <div>{getOverDueProjectDate(project.endDate)}</div>
              </div>
            </div>
          </div>
          {project.isProjectOwner && (
            <>
              <div
                style={{ padding: "13px 10px 5px 5px" }}
                className="more-option"
                id={`project-a-${project.id}`}
                onClick={e => e.stopPropagation()}
              >
                <img alt="" src={threeDot_Icon}></img>
                {/* <i className="fas fa-ellipsis-h" style={{ color: "gray" }}></i> */}
              </div>

              <Popover
                style={{
                  position: "absolute",
                  backgroundColor: "white",
                  boxShadow: "rgb(0 0 0 / 10%) 0px 0px 15px",
                  minWidth: "170px",
                  borderRadius: 5,
                  right: 20,
                  top: -25
                }}
                hideArrow={true}
                trigger="legacy"
                className="roy-menu"
                innerClassName="roy-inner-content"
                placement="bottom"
                target={`project-a-${project.id}`}
                isOpen={optionMenu.status && optionMenu.id === project.id}
                toggle={() =>
                  setOptionMenu({
                    status: !optionMenu.status,
                    id: project.id
                  })
                }
              >
                <PopoverBody
                  style={{ borderRadius: 5, padding: 0, cursor: "pointer" }}
                  onClick={e => {
                    e.stopPropagation();
                    handleArchive(true, project.id);
                  }}
                >
                  {project.isProjectOwner && (
                    <div
                      className="roy-menu-list"
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        padding: "0px",
                        margin: "10px 15px",
                        height: "35px"
                      }}
                      onClick={e => {
                        e.stopPropagation();
                        handleArchive(true, project.id);
                      }}
                    >
                      <img
                        alt=""
                        src={archive}
                        style={{
                          paddingRight: 2,
                          height: 15,
                          width: 15,
                          marginRight: 5
                        }}
                      />
                      <span style={{ paddingLeft: 2 }}>
                        {<IntlMessages id={"project.archive_project"} />}
                      </span>
                    </div>
                  )}
                </PopoverBody>
              </Popover>
            </>
          )}
        </div>
      )}
    </>
  );
};

export default ProjectCard;
