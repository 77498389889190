import React, { useState, useRef, useMemo, useEffect } from "react";
import { ToastProvider, useToasts } from "react-toast-notifications";

import Sidebar from "components/sidebar/Sidebar";
import HorizontalSidebar from "components/horizontalsidebar/HorizontalSidebar";
import ThemeSetting from "components/themesetting/ThemeSetting";
import dashboardRoutes from "routes/dashboardRoutes";
import Header from "components/header/Header";
import Footer from "components/footer/Footer";
import themeActions from "redux/themeChanger/actions.js";
import settingactions from "redux/themeSettings/actions";
import profileModelAction from "redux/profileModel/actions";
import internetActions from "redux/network/actions";
import AppLocale from "languageProvider";
import fileDropActions from "redux//Filedrop/actions";

// import { KBarProvider } from "kbar";
import {
  subscribeToTopic,
  unSubscribeToTopic,
  getUnreadNotificationCount,
  search
} from "helper/services/notificationService";
import { Button } from "reactstrap";
import {
  drawerWidth,
  miniDrawerWidth,
  themeSettingDrawerWidth,
  noInternet
} from "helper/constant";
import { Route, Switch, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { IntlProvider } from "react-intl";
import { ProtectedRoute } from "./../routes/ProtectedRoute";
import GlobalWrapper from "./global.style";
import LayoutSettings from "components/layoutsetting/LayoutSettings";
import NotFound from "views/NotFound";
import MyCustomToast from "./MyCustomToast";
import UserProfileModel from "components/collaborators/UserProfileModel";
import { FileDrop } from "react-file-drop";
import { GLOBAL_FILE_DROP_ROUTES_ENABLED_CONSTANT } from "./file-drop-enabled-routes";
import moment from "moment";
import { getWebsiteDetails } from "helper/services/authServices";
const { changeTheme } = themeActions;
const { sidebarMini, sidebarTransParent } = settingactions;
const { changeInternetState } = internetActions;
const { open_profile_modal, close_profile_modal } = profileModelAction;
const {
  onFileDragEvent,
  onFileDragLeaveEvent,
  onFileDropEvent,
  doDisableFileDropEvent,
  doEnableFileDropEvent
} = fileDropActions;

const DashboardLayout = props => {
  const [unreadNotificationCount, setUnreadNotificationCount] = useState(null);

  useEffect(() => {
    if (props?.LanguageSwitcher?.language?.languageId == "german") {
      moment.locale("de");
    }
    if (props?.LanguageSwitcher?.language?.languageId == "dutch") {
      moment.locale("nl");
    }
    if (props?.LanguageSwitcher?.language?.languageId == "english") {
      moment.locale("en");
    }
    retrieveNotificationCount();
    doHandleSidebarState();
    if (props.open_profile_modal) {
      props.close_profile_modal();
    }
    doCheckFileDropAccessible();
    return () => {};
  }, [props.history.location, props.LanguageSwitcher]);

  useMemo(() => getWebsiteDetails(), []);
  const doHandleSidebarState = () => {
    if (
      props.location.pathname.includes("project") ||
      props.location.pathname.includes("home")
    ) {
      setMini(true);
      props.sidebarMini("on");
    }
  };

  const doCheckFileDropAccessible = () => {
    if (
      props.history.location.pathname.includes(
        GLOBAL_FILE_DROP_ROUTES_ENABLED_CONSTANT
      )
    ) {
      props.doEnableFileDropEvent();
    } else {
      props.doDisableFileDropEvent();
    }
  };

  const retrieveNotificationCount = async () => {
    try {
      const Response = await getUnreadNotificationCount(props.auth.accessToken);
      if (Response) {
        if (Response.error) {
        }
        if (Response.data) {
          setUnreadNotificationCount(Response.data.unreadNotificationCount);
          // const myBadgerOptions = {}; // See: constructor for customization options
          // const myBadger = new Badger(myBadgerOptions);

          // // Live update value example:
          // myBadger.value = Response.data.unreadNotificationCount;
        }
      }
    } catch (e) {}
  };
  const isInternetActivated = () => {
    let status = localStorage.getItem("isInternetActivated");
    if (status) {
      return true;
    }
    return false;
  };

  const [mini, setMini] = useState(
    props.themeSetting.sidebarMiniValue
      ? props.themeSetting.sidebarMiniValue === "off"
      : false
  );
  const [themeDrawer, setThemeDrawer] = useState(true);
  const [layoutSettingDrawer, setLayoutSettingDrawer] = useState(true);
  const [statedrawerWidth] = useState(drawerWidth);
  const [stateminiDrawerWidth, setStateminiDrawerWidth] = useState(
    miniDrawerWidth
  );

  const mainPanel = useRef(null);
  const mainPanelOne = useRef(null);
  const scrollbars = useRef(null);

  useMemo(() => {
    if (scrollbars && scrollbars.current) {
      scrollbars.current.scrollToTop(0);
    }
  }, []);
  useMemo(() => {
    if (props.userTheme) {
      props.sidebarTransParent("off");
      props.changeTheme("sidebarTheme", props.userTheme);
      props.changeTheme("layoutTheme", props.userTheme);
      props.changeTheme("footerTheme", props.userTheme);
      props.changeTheme("topbarTheme", props.userTheme);
    }

    if (props.userTheme == "dark") {
      props.sidebarTransParent("on");
      props.changeTheme("sidebarTheme", "dark");
      props.changeTheme("layoutTheme", "dark");
      props.changeTheme("footerTheme", "dark");
      props.changeTheme("topbarTheme", "dark");
    }
  }, [props.userTheme]);
  useMemo(() => {
    setMini(props.themeSetting.sidebarMiniValue === "on");
  }, [props.themeSetting.sidebarMiniValue]);

  let mainPanelWidth;
  let mainPanelWidthOne;

  const { layoutTheme, locale, themeSetting } = props;

  const currentAppLocale = AppLocale[locale];

  let routeArrowIcon = {
    backgroundColor: props.sidebarTheme.backgroundColor,
    color: props.sidebarTheme.textColor,
    borderBottomLeftRadius: 0,
    borderTopLeftRadius: 0,
    position: "fixed",
    zIndex: 1,
    fontSize: "18px",
    padding: "4px 5px"
  };

  if (themeSetting.layout === "vertical") {
    mainPanelWidth = {
      width: mini
        ? `calc(100% - ${miniDrawerWidth})`
        : `calc(100% - ${drawerWidth})`,
      // width: "100%",
      backgroundColor: layoutTheme.backgroundColor
    };
  } else if (themeSetting.layout === "horizontal") {
    mainPanelWidth = {
      width: "100%",
      backgroundColor: layoutTheme.backgroundColor
    };
  }
  if (themeSetting.layout === "vertical") {
    mainPanelWidthOne = {
      // width: mini
      //   ? `calc(100% - ${miniDrawerWidth})`
      //   : `calc(100% - ${drawerWidth})`,
      width: "100%",
      position: "fixed",
      backgroundColor: layoutTheme.backgroundColor
    };
  } else if (themeSetting.layout === "horizontal") {
    mainPanelWidthOne = {
      width: "100%",
      position: "fixed",
      backgroundColor: layoutTheme.backgroundColor
    };
  }

  const drawerMiniMethod = () => {
    if (mini) {
      setMini(false);
      props.sidebarMini("off");
    } else {
      setMini(true);
      props.sidebarMini("on");
    }
  };

  const themeSettingDrawer = () => {
    if (themeDrawer) {
      setThemeDrawer(false);
    } else {
      setThemeDrawer(true);
    }
  };

  const toggleLayoutSettingDrawer = () => {
    setLayoutSettingDrawer(!layoutSettingDrawer);
  };

  const mouseEnter = () => {
    if (mini) {
      setStateminiDrawerWidth(drawerWidth);
    }
  };

  const mouseLeave = () => {
    if (mini) {
      setStateminiDrawerWidth(miniDrawerWidth);
    }
  };

  const closeDrawer = () => {
    setMini(true);
  };
  return (
    <IntlProvider
      locale={currentAppLocale.locale}
      messages={currentAppLocale.messages}
    >
      <GlobalWrapper {...props}>
        <ToastProvider components={{ Toast: MyCustomToast }}>
          {/* {themeSetting.layout === "vertical" && (
            <Sidebar
              mini={mini}
              drawerWidth={statedrawerWidth}
              miniDrawerWidth={stateminiDrawerWidth}
              onMouseEnter={mouseEnter}
              onMouseLeave={mouseLeave}
              closeDrawer={() => closeDrawer}
              {...props}
            />
          )}
          {themeSetting.layout === "horizontal" && (
            <div className="hor_mobile_sidebar">
              <Sidebar
                mini={mini}
                drawerWidth={statedrawerWidth}
                miniDrawerWidth={stateminiDrawerWidth}
                onMouseEnter={mouseEnter}
                onMouseLeave={mouseLeave}
                closeDrawer={() => closeDrawer}
                {...props}
              />
            </div>
          )} */}
          {/* <Header
            drawerMiniMethod={drawerMiniMethod}
            mini={mini}
            drawerWidth={statedrawerWidth}
            miniDrawerWidth={stateminiDrawerWidth}
            layoutSettingDrawerToggle={toggleLayoutSettingDrawer}
            {...props}
          /> */}

          <ThemeSetting
            mini={themeDrawer}
            drawerWidth={themeSettingDrawerWidth}
            miniDrawerWidth={stateminiDrawerWidth}
            closeSettingDrawer={themeSettingDrawer}
            {...props}
          />

          <LayoutSettings
            mini={layoutSettingDrawer}
            drawerWidth={themeSettingDrawerWidth}
            miniDrawerWidth={stateminiDrawerWidth}
            closeSettingDrawer={toggleLayoutSettingDrawer}
            {...props}
          />

          <div
            id="main-panel-one"
            className="main-panel-one "
            ref={mainPanelOne}
            style={mainPanelWidthOne}
          >
            <div>
              {themeSetting.layout === "horizontal"
              // <HorizontalSidebar
              //   {...props}
              //   drawerMiniMethod={drawerMiniMethod}
              //   layoutSettingDrawerToggle={toggleLayoutSettingDrawer}
              // />
              }
              {themeSetting.layout === "vertical" &&
                props.themeSetting.toolbarDisplayValue === "show" && (
                  <Header
                    unreadNotificationCount={unreadNotificationCount}
                    setUnreadNotificationCount={setUnreadNotificationCount}
                    drawerMiniMethod={drawerMiniMethod}
                    mini={mini}
                    drawerWidth={statedrawerWidth}
                    miniDrawerWidth={stateminiDrawerWidth}
                    layoutSettingDrawerToggle={toggleLayoutSettingDrawer}
                    {...props}
                  />
                )}
            </div>
            {/* <Button
            className="setting-button"
            onClick={themeSettingDrawer}
            style={{
              backgroundColor: "white",
              color: "rgba(0,0,0,.87)"
            }}
          >
            <i className="fas fa-cogs" />
          </Button> */}
            <FileDrop
              onFrameDragEnter={event => {
                if (props?.fileDropActions?.isFileDropAllowed) {
                  props.onFileDragEvent();
                }
              }}
              onFrameDragLeave={event => {
                if (props?.fileDropActions?.isFileDropAllowed) {
                  props.onFileDragLeaveEvent();
                }
              }}
              onFrameDrop={event => {
                if (props?.fileDropActions?.isFileDropAllowed) {
                  props.onFileDragLeaveEvent();
                }
              }}
              onDragOver={event => {}}
              onDragLeave={event => {}}
              onDrop={async (files, event) => {
                if (props?.fileDropActions?.isFileDropAllowed) {
                  if (
                    event.target.tagName.toLocaleLowerCase() !==
                      "trix-editor" &&
                    event?.target?.className !== "trix-content" &&
                    event?.target?.className?.includes("trix-main") != true
                  ) {
                    props.onFileDropEvent(await files[0]);
                  }
                }
              }}
            >
              <div
                id="main-panel"
                className="main-panel flex-y"
                ref={mainPanel}
                style={mainPanelWidth}
              >
                {/* <div className="main-panel-hand-burger" onClick={() => drawerMiniMethod()}> &gt; </div> */}
                {themeSetting.toolbarDisplayValue !== "show" && (
                  <button
                    style={routeArrowIcon}
                    className="c-btn mini-drawer-menu-icon-hide-topbar"
                    onClick={drawerMiniMethod}
                  >
                    <i className="fas fa-bars" />
                  </button>
                )}
                {themeSetting.layout === "vertical" && (
                  <Sidebar
                    mini={mini}
                    drawerWidth={statedrawerWidth}
                    miniDrawerWidth={stateminiDrawerWidth}
                    onMouseEnter={mouseEnter}
                    onMouseLeave={mouseLeave}
                    closeDrawer={() => closeDrawer}
                    {...props}
                  />
                )}
                {themeSetting.layout === "horizontal" && (
                  <div className="hor_mobile_sidebar">
                    <Sidebar
                      mini={mini}
                      drawerWidth={statedrawerWidth}
                      miniDrawerWidth={stateminiDrawerWidth}
                      onMouseEnter={mouseEnter}
                      onMouseLeave={mouseLeave}
                      closeDrawer={() => closeDrawer}
                      {...props}
                    />
                  </div>
                )}
                {/* Route Layout Start*/}
                <div
                  className="route-height flex-1 overflow-y"
                  style={
                    ({
                      background: layoutTheme.backgroundColor
                    },
                    {
                      maxHeight: "92vh",
                      overflowX: "hidden",
                      background: "#F4F5F7"
                    })
                  }
                >
                  <Switch>
                    <ProtectedRoute {...props}>
                      {dashboardRoutes.map((prop, key) => {
                        return (
                          <Route
                            exact
                            path={prop.path}
                            component={prop.component}
                            key={key}
                          />
                        );
                      })}
                    </ProtectedRoute>
                    <Redirect to="/404" />
                    <Route path="*" component={NotFound} />
                    {/* <NotFound /> */}
                    {/* </Route> */}
                  </Switch>
                </div>
                {/* Route Layout Finish*/}
                <div>
                  {/* {props.InternetState.isInternetActivated && props.InternetState.isInternetActivated  && */}
                  {!navigator.onLine && (
                    <>
                      <div
                        id="foo"
                        style={{
                          position: "fixed",
                          bottom: 10,
                          right: 10,
                          height: 100,
                          width: 100,
                          background: "black",
                          borderRadius: 20,
                          border: "1px solid transparent",
                          color: "white",
                          opacity: 0.9,
                          display: " flex",
                          flexDirection: "column",
                          justifyContent: "center",
                          alignItems: "center"
                        }}
                      >
                        <img
                          alt=""
                          src={noInternet}
                          style={{
                            height: 40,
                            width: 40,
                            marginBottom: 5
                          }}
                        />
                        Offline
                      </div>
                    </>
                  )}
                  {themeSetting.footerDisplayValue === "show" && (
                    <Footer
                      {...props}
                      mini={mini}
                      drawerWidth={statedrawerWidth}
                      miniDrawerWidth={stateminiDrawerWidth}
                    />
                  )}
                </div>
              </div>
            </FileDrop>
          </div>
        </ToastProvider>
        <UserProfileModel />
      </GlobalWrapper>
    </IntlProvider>
  );
};

const mapStateToProps = state => {
  return {
    ...state.themeChanger,
    LanguageSwitcher: state.LanguageSwitcher,
    locale: state.LanguageSwitcher.language.locale,
    authData: {
      token: state.auth.accessToken,
      isLogin: state.auth.isLogin
    },
    themeSetting: {
      toolbarAlignValue: state.themeSetting.toolbarAlignValue,
      footerAlignValue: state.themeSetting.footerAlignValue,
      sidebarDisplayValue: state.themeSetting.sidebarDisplayValue,
      toolbarDisplayValue: state.themeSetting.toolbarDisplayValue,
      footerDisplayValue: state.themeSetting.footerDisplayValue,
      sidebarTransParentValue: state.themeSetting.sidebarTransParentValue,
      transparentImage: state.themeSetting.transparentImage,
      activeLinkStyle: state.themeSetting.activeLinkStyle,
      sidebarMiniValue: state.themeSetting.sidebarMiniValue,
      layout: state.themeSetting.layout,
      sidebarTransParentActiveBack:
        state.themeSetting.sidebarTransParentActiveBack,
      sidebarTransParentActiveColor:
        state.themeSetting.sidebarTransParentActiveColor
    },
    userTheme: state.auth.user?.userSetting?.theme,
    fileDropActions: state.fileDropActions
  };
};

export default connect(mapStateToProps, {
  changeTheme,
  sidebarMini,
  sidebarTransParent,
  changeInternetState,
  open_profile_modal,
  close_profile_modal,
  onFileDragEvent,
  onFileDragLeaveEvent,
  onFileDropEvent,
  doDisableFileDropEvent,
  doEnableFileDropEvent
})(DashboardLayout);
