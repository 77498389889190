/* TODO:CHECKHEADERS
    check if token header is present if present then return token with header else rediirect to login page
*/
export function getHeader(propsToken) {
  const token = getAuth() ? JSON.parse(getAuth().auth).accessToken : null;
  if (token !== null && token !== undefined) {
    return {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
      HostName: window.location.host
    };
  } else {
    if (propsToken) {
      return {
        "Content-Type": "application/json",
        Authorization: `Bearer ${propsToken}`,
        HostName: window.location.host
      };
    }
  }
}

export function getHeaderForProfile() {
  const token = JSON.parse(getAuth().auth).accessToken;
  if (token !== null && token !== undefined) {
    return {
      Authorization: `Bearer ${token}`
    };
  }
}

function getAuth() {
  const auth = JSON.parse(localStorage.getItem("persist:root"));
  return auth;
}
