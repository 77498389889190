import React, { useEffect, useState } from "react";
import {
  locakscreenBack,
  ProfileLockScreen,
  iconDemo,
  iconDemoBlue,
  EMAIL_VERIFICATION_SENT,
  USER_ALREADY_VERIFIED
} from "helper/constant";
import "./style.css";
import { connect } from "react-redux";
import { getWebsiteDetails, verifyEmail } from "helper/services/authServices";
import Notifications, { notify } from "react-notify-toast";
import Loader from "components/loader/Loader";
const EmailVerification = props => {
  const [isEmailVerified, setIsEmailVerified] = useState(false);
  const [isEmailSent, setIsEmailSent] = useState(false);
  const [isLoader, setIsLoader] = useState(false);
  const [userEmail, setUserEmail] = useState("");
  console.log(props);
  useEffect(() => {
    getWebsiteDetails();
    if (props.match?.params?.token) {
      handleEmailVerification(props.match?.params?.token);
    }

    if (props.location.state && props.location.state.email) {
      setUserEmail(props.location.state.email);
    }

    return () => {
      setIsEmailVerified(false);
    };
  }, []);
  const Container = {
    margin: "53px auto",
    maxWidth: 600,
    textAlign: "left",
    padding: 20,
    background: "#fff"
  };
  const handleEmailVerification = async token => {
    // resendEmailVerification
    if (token) {
      try {
        setIsLoader(true);
        const response = await verifyEmail(token);
        if (response) {
        }

        if (response.error) {
          setIsLoader(true);

          if (response.error.message == USER_ALREADY_VERIFIED) {
            notify.show(response.error.message, "error");
            setIsEmailVerified(true);
          } else {
            notify.show(response.error.message, "error");
          }
        }
        if (response.message) {
          setIsEmailSent(true);
        }

        setIsLoader(false);
      } catch (err) {
        setIsLoader(false);
        // notify.show(response.errors.message, "error")
      }
    }
  };
  const isEmailValidate = email => {
    const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
  };

  return (
    <div className="container-fluid" style={Container}>
      <Notifications />
      <p style={{ marginBottom: 60 }}>
        <div className="login-icon fs-25 text-bold" style={{ fontWeight: 600 }}>
          <img
            src={
              window.location.href.includes("workplate.nl")
                ? iconDemo
                : iconDemoBlue
            }
            alt="icon"
            height="50px"
            width="200px"
          />
        </div>
      </p>
      <div className="container">
        <div className="row" /* style={{ paddingTop: 40 }} */>
          <p
            className=" flex-x text-center "
            style={{
              alignContent: "center",
              alignItems: "center",
              justifyContent: "center"
            }}
          >
            {isLoader ? <Loader height="50px" width="50px" /> : ""}
          </p>
        </div>

        {isEmailVerified && (
          <div className="row">
            <h4
              className="flex-x"
              // style={{
              //   alignContent: "center",
              //   alignItems: "center",
              //   justifyContent: "center"
              // }}
            >
              <i
                className="fa fa-exclamation"
                style={{ color: "red", marginLeft: "8px", marginRight: "10px" }}
              />
              Email is already verified{" "}
              {/* <i className="fa fa-check pl-5" style={{ color: "green" }} /> */}
            </h4>
          </div>
        )}

        {isEmailSent && (
          <div className="row">
            <h1
              className=" flex-x"
              style={{
                color: "black"
              }}
            >
              Email verified successfully
              {/* <i
                className="fa fa-check pl-5"
                style={{ color: "green", marginLeft: "-35px" }}
              /> */}
            </h1>

            <div style={{ paddingTop: 40 }}>
              <p className="fs-20" style={{ color: "black" }}>
                Your email is successfully verified! Please login to access your
                account!
              </p>
            </div>
          </div>
        )}
        {!isLoader && (
          <div
            className="row text-center"
            style={{ paddingTop: 40, justifyContent: "center" }}
          >
            <div
              className=" flex-x text-center"
              style={{
                alignContent: "center",
                alignItems: "center",
                justifyContent: "center"
              }}
            >
              <div
                className="login-button"
                onClick={() => props.history.push("/login")}
                style={{ background: props?.auth?.webDetails?.appThemeColor }}
              >
                Login now
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

const mapStateToProps = state => {
  return {
    ...state.themeChanger,
    ...state,
    topbarTheme: { ...state.themeChanger.topbarTheme }
  };
};
export default connect(mapStateToProps, null)(EmailVerification);
