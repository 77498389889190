import React, { useState, useEffect } from "react";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { createLabel } from "helper/services/projectServices";
import { Alert } from "reactstrap";
import { connect } from "react-redux";
import Loader from "components/loader/Loader";
import ColorPopover from "components/projects/ProjectColorDropdown";
import {
  ProjectColors,
  ProjectColorsGn,
  ProjectColorsDut
} from "components/projects/utils/color";
import { updateBusiness } from "services/socket";
import { businessSocketRoomPrefix } from "constatnt";
import IntlMessages from "util/intlMessages";
import InjectPlaceHolderMessage from "helper/methods/PlaceHolderText";

const AddLabel = props => {
  const {
    modal,
    setmodal,
    sidebarData,
    updateLabelToRedux,
    topbarTheme,
    businessId,
    workspaceId,
    language
  } = props;
  const [isDisabled, setIsDisabled] = useState(true);
  const [visible, setVisible] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [isLoader, setIsLoader] = useState(false);
  const [selectedColor, setSelectedColor] = useState();
  const [options, setOption] = useState();

  useEffect(() => {
    if (props.language.locale == "de") {
      setOption(ProjectColorsGn);
      setSelectedColor(ProjectColorsGn[1].label);
    } else if (props.language.locale == "nl") {
      setOption(ProjectColorsDut);
      setSelectedColor(ProjectColorsDut[1].label);
    } else {
      setOption(ProjectColors);
      setSelectedColor(ProjectColors[1].label);
    }
  }, [props.language.locale]);

  const handleProjectSubmit = async e => {
    e.preventDefault();
    setVisible(false);
    if (e.target.name.value) {
      setIsLoader(true);

      try {
        const Response = await createLabel({
          name: e.target.name.value,
          color: selectedColor,
          isFavorite: false,
          businessId: workspaceId ? workspaceId : ""
        });

        if (Response) {
          setIsLoader(false);
          if (Response.error) {
            setVisible(true);
            setErrorMessage(Response.error.message);
          } else if (Response.errors) {
            setVisible(true);
            setErrorMessage(Response.errors.message);
          } else {
            setVisible(false);
            if (Response.data) {
              updateLabelToRedux(Response.data);
              let payload = {
                roomname: `${businessSocketRoomPrefix}${businessId}`
              };

              updateBusiness(payload, cb => {
                if (cb.status == "Ok") {
                }
              });
              sidebarData.map((data, i) => {
                if (data.name === "sidebar.labels") {
                  data.child = [];
                  data.child.push({
                    id: Response.data.id,
                    listname: Response.data.name,
                    routepath: `/label/${Response.data.id}`,
                    color: Response.data.color
                  });
                }
              });
            }
            setmodal();
          }
        }
      } catch (e) {
        setIsLoader(false);
      }
    }
  };

  return (
    <Modal
      isOpen={modal}
      // toggle={() => setmodal(!modal)}
      centered
      autoFocus={false}
    >
      <form onSubmit={handleProjectSubmit}>
        <ModalHeader toggle={() => setmodal(!modal)}>
          <IntlMessages id={"labels.addlabel"} />
        </ModalHeader>
        <Alert
          className="c-"
          style={{ backgroundColor: "RED ", color: "white" }}
          isOpen={visible}
          toggle={() => setVisible(false)}
        >
          {visible ? errorMessage : ""}
        </Alert>
        <ModalBody>
          <div className="form-group">
            <span style={{ fontSize: 14 }}>
              <label>
                <IntlMessages id={"labels.title"} />
              </label>
            </span>
            <input
              type="text"
              className="form-control react-form-input"
              id="name"
              onChange={e => {
                e.target.value.length > 0
                  ? setIsDisabled(false)
                  : setIsDisabled(true);
              }}
              placeholder={InjectPlaceHolderMessage(
                "labels.enter_label_title_place holder",
                props?.LanguageSwitcher?.language?.languageId
              )}
              autoFocus
            />
          </div>
          <div className="form-group">
            <span style={{ fontSize: 14 }}>
              <label style={{ paddingRight: 20 }}>
                <IntlMessages id={"labels.color"} />
              </label>
            </span>
            <br />

            <ColorPopover
              options={options}
              selectedColor={selectedColor && selectedColor}
              changeColor={value => {
                setSelectedColor(value);
              }}
              language={language}
            />
          </div>

          <br />
        </ModalBody>
        <ModalFooter>
          <Button
            className="button btn"
            onClick={() => setmodal(!modal)}
            style={{ background: "#FFF", border: 0, color: "#000" }}
          >
            <IntlMessages id={"project.cancel"} />
          </Button>
          {isLoader ? (
            <Loader height="24px" width="20px" />
          ) : (
            <Button
              type="submit"
              className="button-t btn"
              style={{
                backgroundColor: topbarTheme.buttonColor,
                border: "none"
              }}
              disabled={isDisabled}
            >
              <IntlMessages id={"labels.add_button"} />
            </Button>
          )}
        </ModalFooter>
      </form>
    </Modal>
  );
};

const mapStateToProps = state => {
  return {
    ...state,
    businessId: state.auth.user?.businessId,
    topbarTheme: { ...state.themeChanger.topbarTheme },
    language: { ...state.LanguageSwitcher.language }
  };
};

export default connect(mapStateToProps, null)(AddLabel);
