import React, { useEffect, useState } from "react";
import ProjectHeader from "components/ProjectDetails/ProjectDetailsHeader";
import "./project.style.css";
import { getProjectById } from "helper/services/projectServices";
import MemberTab from "components/ProjectDetails/MemberTab";
import SettingsTab from "components/ProjectDetails/settingsTab";
import { withRouter } from "react-router";
import { compose } from "redux";
import { connect } from "react-redux";
import participantEmailEnhance from "./pages/authentication/enhancer/participantEmailEnhance";
import projectActions from "redux/projects/actions";
import labelActions from "redux/labels/actions";
import authActions from "redux/auth/actions";
import EditProject from "components/projects/EditProject";
import { useToasts } from "react-toast-notifications";
import ToastMessage from "components/notifications/ToastMessage";
import { updateProject, getProject } from "helper/services/projectServices";
import { getCollaboratorsList } from "helper/services/businessCollaboratorsServices";
import updateProjectActions from "redux/projectAction/actions";
import { removeCollaborators } from "helper/services/projectServices";
import alertActions from "redux/globalAlert/actions";
import IntlMessages from "util/intlMessages";
const { open_workpsace_alert, open_project_alert, close_alert } = alertActions;
const { do_update_project, close_update_project } = updateProjectActions;
const { update_token, update_business } = authActions;
const {
  set_projects,
  update_Project,
  update_projects_existing,
  remove_Project
} = projectActions;

const { set_labels } = labelActions;
const ProjectDetails = props => {
  const tabs = ["member", "settings"];
  const [activeTab, setActiveTab] = useState(tabs[0]);
  const [projectDetails, setProjectDetails] = useState("");
  const [isArchiveLoader, setIsArchiveloader] = useState(false);
  const [isDeleteLoader, setIsDeleteloader] = useState(false);
  const [collaboratorArr, setCollaboratorArr] = useState([]);
  const [isEditProject, setIsEditProject] = useState(false);

  const [isOwner, setIsOwner] = useState(false);

  const [archivedProjectArr, setArchivedProjectArr] = useState([]);
  const [isLoader, setIsloader] = useState(false);
  const [archiveProject, setArchiveProject] = useState(false);
  const [workspace, setWorkspace] = useState("");
  const [collaboratorationId, setCollaboratorationId] = useState(null);
  const [isArchived, setIsArchived] = useState(false);
  // workspaceMembersOnly are array which includes only workspace member and doesn't include project members
  const [workspaceMembersOnly, setWorkspaceMembersOnly] = useState([]);
  const { id } = props.match.params;

  const { addToast } = useToasts();

  const toggletab = id => {
    setActiveTab(id);
  };
  React.useEffect(() => {
    if (props.updateProjectActions?.status && id) {
      fetchProjects(false);
      props.close_update_project();
    }
  });
  useEffect(() => {
    fetchProjects(id);
    return () => {
      setActiveTab(tabs[0]);
      setProjectDetails("");
      setIsArchiveloader(false);
      setIsDeleteloader(false);
      setCollaboratorArr([]);
      setIsEditProject(false);
      setIsOwner(false);
      setArchivedProjectArr([]);
      setIsloader(false);
      setArchiveProject(false);
      setWorkspace("");
      setCollaboratorationId(null);
      setIsArchived(false);
      setWorkspaceMembersOnly([]);
    };
  }, [id]);
  const handleResponse = message => {
    try {
      if (message == "NOT_WORKSPACE_COLLABORATOR") {
        // status:action.alert.status, message:action.alert.message,action:'open_businesslist'

        props.open_workpsace_alert({
          status: true,
          message: "workspace"
        });
      }

      if (message == "USER_NOT_PROJECT_COLABORATOR") {
        props.open_project_alert({
          status: true,
          message: "project"
        });
        props.remove_Project(id);
      }
    } catch (e) {}
  };
  const handleArchiveProject = async () => {
    try {
      setIsArchiveloader(true);
      const Response = await updateProject(
        {
          isArchived: !isArchived
        },
        id
      );
      if (Response) {
        if (Response.error) {
          handleResponse(Response.error.message);
          showNotifications();
        }
        // on archived project it should get removed from sidebar and push to archived projects
        if (Response.data) {
          setIsArchived(Response?.data?.isArchived);
          console.log(Response?.data);

          setProjectDetails({ ...projectDetails, ...Response?.data });

          updateProjectToRedux({ ...projectDetails, ...Response?.data });

          if (Response?.data?.isArchived) {
            showNotifications(
              <IntlMessages id={"workspace.project_archived"} />,
              "success"
            );
          } else {
            showNotifications(
              <IntlMessages id={"workspace.project_unarchived"} />,
              "success"
            );
          }
        }
        setIsArchiveloader(false);
      }
    } catch (e) {
      showNotifications();
    }
  };
  const handleLeaveProject = async id => {
    if (collaboratorationId) {
      try {
        setIsDeleteloader(true);
        const response = await removeCollaborators({
          collaboration_id: collaboratorationId
        });
        if (response) {
          if (response.error) {
            handleResponse(response.error.message);
            addToast(ToastMessage(Response.error.message), {
              appearance: "error",
              autoDismiss: true
            });
          }
          if (response.message) {
            // retriveBusiness();
            addToast(ToastMessage(Response.message), {
              appearance: "success",
              autoDismiss: true
            });
            props.remove_Project(id);
            if (projectDetails?.business?.id) {
              props.history.push(`/business/${projectDetails?.business?.id}`);
            }
            setIsDeleteloader(false);
          }
        }
      } catch (e) {
        setIsDeleteloader(false);
      }
    }
  };
  const fetchParticipants = async (businessId, collaboratorArr) => {
    // setIsParticipantsLoader(true);
    const result = await getCollaboratorsList(businessId);
    if (result) {
      if (result.error) {
        showNotifications(result.error.message);
        handleResponse(result.error.message);
      }
      if (result.data) {
        //  setCollaboratorArr(result.data);

        handleParticipants(result.data, collaboratorArr);
      }
      //  setIsParticipantsLoader(false);
    }
  };
  const isProjectOwnerAndGetCollaboratorId = collaboratorArr => {
    if (collaboratorArr) {
      collaboratorArr.map(data => {
        if (
          data &&
          data.role.toLowerCase() == "owner" &&
          data.user.id == props.auth.user.id
        ) {
          setCollaboratorationId(data.id);
          setIsOwner(true);
        }
        if (data && data.user.id == props.auth.user.id) {
          setCollaboratorationId(data.id);
        }
      });
    }
  };
  const doRemoveProjectFromSidebar = () => {
    props.remove_Project(id);
    props.history.push(`/business/${projectDetails?.business?.id}`);
  };
  const handleParticipants = async (data, collaboratorArr) => {
    // handle data for to check request are accepted and are collaborator
    // also if they are part of project than dont display
    var updatedData = [];
    await Promise.all(
      data.map(data => {
        if (data.requestStatus.toLowerCase() == "accepted") {
          updatedData.push({
            ...data,
            label: `${data.user.name} (${data.user.email})`,
            chipLabel: `${data.user.email} `,
            value: data.user.email
          });
        }
      })
    );

    let finalDatarr = [];
    collaboratorArr &&
      (await Promise.all(
        updatedData.map(async item => {
          let arr1 = await Promise.all(
            collaboratorArr.filter(item2 => item.user.id == item2.user.id)
          );
          if (arr1.length == 0) {
            finalDatarr.push(item);
          }
        })
      ));
    // collaboratorList.sort((a, b) => a.id - b.id);
    // change up datwoa sorting
    setWorkspaceMembersOnly(finalDatarr);
  };
  const fetchProjects = async (isLoader = true) => {
    setIsloader(isLoader);
    const Response = await getProjectById(id);
    if (Response) {
      if (Response.error) {
        setIsloader(false);
        handleResponse(Response.error.message);
        if (Response.error.message == "Project not found") {
          // props.history.push("/inbox");
          props.history.push("/today");
        }
      }
      if (Response.data) {
        setIsArchived(Response?.data?.isArchived);
        fetchParticipants(
          Response.data?.business?.id,
          Response.data.collaborator
        );
        setProjectDetails(Response.data);
        setCollaboratorArr(Response.data.collaborator);
        isProjectOwnerAndGetCollaboratorId(Response.data.collaborator);
        setWorkspace(Response.data?.business);
        setIsloader(false);
      }
    }
  };
  const updateProjectToRedux = data => {
    props.update_projects_existing({ projects: data });
  };

  const showNotifications = (message, status) => {
    addToast(ToastMessage(message), {
      appearance: status ? status : "error",
      autoDismiss: true
    });
  };

  // need to make own member tab for projects
  const handleActiveTabs = tabId => {
    if (tabId == "member") {
      return (
        <MemberTab
          partcipantsArr={workspaceMembersOnly}
          projectName={projectDetails.name}
          setParticipantsArr={data => setWorkspaceMembersOnly(data)}
          isLoader={isLoader}
          isOwner={isOwner}
          {...props}
          id={id}
          projectId={projectDetails.id}
          currentLoggedInUser={props?.auth?.user}
          collaboratorArr={collaboratorArr}
          setCollaboratorArr={data => setCollaboratorArr(data)}
          removeAndRedirect={doRemoveProjectFromSidebar}
          handleResponse={handleResponse}
          topbarTheme={props.topbarTheme}
        />
      );
    }
    if (tabId == "settings") {
      return (
        <SettingsTab
          projectDetails={projectDetails}
          isLoader={isArchiveLoader}
          isDeleteLoader={isDeleteLoader}
          id={id}
          isOwner={isOwner}
          isArchived={isArchived}
          showNotifications={showNotifications}
          archiveProject={handleArchiveProject}
          leaveProject={handleLeaveProject}
          topbarTheme={props.topbarTheme}
        />
      );
    }
  };
  return (
    <div>
      <div>
        <ProjectHeader
          isArchived={isArchived}
          id={id}
          isOwner={isOwner}
          isLoader={isLoader}
          isArchiveLoader={isArchiveLoader}
          projectDetails={projectDetails}
          tabs={tabs}
          activeTab={activeTab}
          toggletab={id => toggletab(id)}
          toggleEditProject={() => setIsEditProject(!isEditProject)}
          archiveProject={handleArchiveProject}
        />
        <div style={{ width: "100%", background: "white" }}>
          <div className="container">
            <div className="row" style={{ paddingBottom: "10%" }}>
              <div className="col-lg-1 col-md-2 col-sm-0 col-xs-0" />

              <div
                className="col-lg-10 col-md-8 col-sm-12 col-xs-12 "
                style={{ padding: 10 }}
              >
                {handleActiveTabs(activeTab)}
              </div>
              <div className="col-lg-1 col-md-2 col-sm-0 col-xs-0" />
            </div>
          </div>
        </div>
      </div>
      <EditProject
        modal={isEditProject}
        setmodal={() => setIsEditProject(!isEditProject)}
        project={projectDetails}
        setProject={data => {
          setProjectDetails({ ...projectDetails, ...data });
        }}
        defaultWorkspaceId={workspace?.id}
        updateProjectToRedux={data => updateProjectToRedux(data)}
        showNotifications={showNotifications}
        languageName={
          isEditProject && props.LanguageSwitcher.language.languageId
        }
      />
    </div>
  );
};
const mapStateToProps = state => {
  return {
    ...state,
    topbarTheme: { ...state.themeChanger.topbarTheme },
    language: { ...state.LanguageSwitcher.language }
  };
};

export default compose(
  withRouter,
  participantEmailEnhance,
  connect(mapStateToProps, {
    update_Project,
    update_token,
    set_projects,
    set_labels,
    update_business,
    update_projects_existing,
    remove_Project,
    open_workpsace_alert,
    open_project_alert,
    close_alert,
    do_update_project,
    close_update_project
  })
)(ProjectDetails);
