import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import HomePageTitleWrapper from "./HomePageTitleWrapper.style";

const HomePage = props => {
  return (
    <>
      <div className="Workspace-name-header pl-33 roy-menu">
        <div
          style={{
            display: "flex",
            alignItems: "center",
            // justifyContent: "center",
            cursor: "pointer",
            marginLeft: "-1rem"
          }}
          onClick={() => {
            props.history.push(`/home`);
          }}
        >
          <HomePageTitleWrapper {...props}>
            <div className="roy-menu-icon">
              <span style={{ marginLeft: "4px" }}>
                {/* {appName?.data?.appName} */}
                {props?.auth?.webDetails?.appName}
              </span>
            </div>
          </HomePageTitleWrapper>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = state => {
  return {
    ...state,
    topbarTheme: { ...state.themeChanger.topbarTheme }
  };
};
export default connect(mapStateToProps)(HomePage);
