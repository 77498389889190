// import handleResponse, { encryptPayload } from "./handleResponse";
import {
  BASE_URL,
  usersSettings,
  REACT_APP_API_ENDPOINT
} from "./apiConstants";
import { getHeader, getHeaderForProfile } from "./getHeaders";
import handleAuthServiceResponse, {
  encryptPayload
} from "./handleAuthServiceResponse";

export function updateUserSettings(data) {
  const encryptedData = encryptPayload(data);
  const encryptObj = { encPayload: encryptedData };
  const requestOptions = {
    method: "PUT",
    headers: getHeader(),
    body: JSON.stringify(encryptObj)
  };
  return fetch(REACT_APP_API_ENDPOINT + usersSettings, requestOptions)
    .then(handleAuthServiceResponse)
    .then(data => {
      return data;
    });
}
