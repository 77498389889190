import React, { useMemo } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { Link, withRouter } from "react-router-dom";
import { getWebsiteDetails } from "helper/services/authServices";
import "./PrivacyPolicy.style.css";

const PrivacyPolicy = props => {
  useMemo(() => getWebsiteDetails(), []);

  return (
    <>
      <div style={{ height: "100vh", overflowY: "scroll" }}>
        <nav
          className="navbar navbar-expand-md navbar-dark fixed-top inner-page "
          style={{ backgroundColor: "#fff", padding: "15px" }}
        >
          <div className="container">
            <a className="navbar-brand logo" href="javascript:void(0);">
              <h1
                style={{
                  fontWeight: "700",
                  color: props?.auth?.webDetails?.appThemeColor
                }}
              >
                Privacy Policy
              </h1>
            </a>
          </div>
        </nav>

        <main>
          <section id="steps-to-cancle">
            <div class="container">
              <div class="entry-content">
                <p style={{ margin: "1rem 0rem 1rem 0rem" }}>
                  We care about protecting your personal information. This
                  Privacy Policy (“Policy”) explains how Workplate and its
                  subsidiaries and affiliated companies ("Workplate”, “we”, or
                  “us”) collect, use, and share your personal information when
                  you access or use the Workplate web application (“Workplate”)
                  or any associated features, content, or pages owned or
                  operated by Workplate (collectively, “Services”). This Policy
                  does not address the privacy practices of any third parties
                  that Workplate does not own or control.
                </p>

                <p>
                  Please read this Policy and the Terms and Conditions
                  carefully. By choosing to use the app or the Services, you
                  acknowledge and agree that your personal information may be
                  used pursuant to this Policy. If you do not feel comfortable
                  with any part of this Privacy Policy, you must not use or
                  access our Services.
                </p>

                <p>
                  We reserve the right to update this Privacy Policy from time
                  to time. If we make a material change to this Policy, we will
                  strive to notify you by displaying a notice on our Services.
                  By continuing to use the Services after the changes become
                  effective, you are agreeing to be bound by the revised Policy.
                </p>

                <h3>Personal Information That We Collect</h3>

                <p>
                  Personal information means information that, alone or in
                  combination with other information, may be used to identify
                  you. Personal information includes, but is not limited to,
                  name, user name, email address, mailing address, and phone
                  number. When you use our Services, we may collect the
                  following information about you:
                </p>

                <h4>Account Information</h4>

                <p>
                  When you use our Services, you may register an account with
                  the app to track your progress and to sync your activity
                  across devices. To register an account, you may be asked to
                  provide certain personal information to us, including your
                  name, email address, and password. You are solely responsible
                  for ensuring your account information is correct and
                  up-to-date.
                </p>

                <h4>Information From Social Media Platforms</h4>

                <p>
                  You may also choose to create an account with us using your
                  Facebook or Google account credentials. When you log in using
                  your Facebook or Google account, you authorize Facebook or
                  Google to provide us with your personal information such as
                  your name, profile picture, email address, language
                  preference, and other information as listed in the
                  authorization.
                </p>

                <h4>Information You Provide To Us</h4>

                <p>
                  We collect any personal information you provide to us when you
                  use our Services. For example, you may provide additional
                  account information, such as your language preferences. You
                  may also provide information when participating in surveys,
                  sweepstakes, contests, or events, such as your contact
                  information. You are solely responsible for ensuring the
                  information you provide to us is correct and up-to-date.
                </p>

                <h4>The App Data</h4>

                <p>
                  When you use the app’s features, we may collect information
                  such as your preferences within app, Server connection Logs on
                  the app, payment references, user meta data, and purchase
                  history.
                </p>

                <h4>Activity and Usage Data</h4>

                <p>
                  While accessing or using the Services, we may automatically
                  collect information about how you interact with the services.
                  Such information may include your Internet Protocol (“IP”)
                  address, device identifier, advertising identifier, MAC
                  address, operating system, browser type, language preferences,
                  referring and exiting pages, crash data, location data,
                  purchasing activity, and other data regarding your activities
                  on the Services. For more information, see the section.
                </p>

                <h4>Customer Service</h4>

                <p>
                  We collect the personal information you provide to us when you
                  contact us for customer service, to provide feedback, or to
                  request information.
                </p>

                <h3>How We Use Your Personal Information</h3>

                <p>
                  We shall use your personal information for the following
                  purposes:
                </p>

                <h4>To Provide Our Services</h4>

                <p>
                  We use your personal information to provide you with the web
                  application and any other Services you request. This may
                  include processing transactions, providing you with the
                  requested features, syncing your app specific data or history
                  across your devices logged into your account, customer
                  service, etc.
                </p>

                <h4>To Personalize Your Experience</h4>

                <p>
                  We may process your information to personalize the Services
                  for you. For example, we use your language preferences and
                  country location to display the correct language and time zone
                  in the Services. We also use certain information about your
                  device (such as operating system version and screen resolution
                  information) to optimize the display of pictures on your
                  screen.
                </p>

                <h4>To Send You Service-Related Communications</h4>

                <p>
                  We will use your information (such as contact information) to
                  send you communications about your account, requested
                  transactions, and changes in our policies. Please note that
                  you may not opt out of such communications, which are
                  necessary to keep you informed about important updates about
                  your account and the Services.
                </p>

                <h4>To Conduct Analytics and Other Internal Purposes</h4>

                <p>
                  We may use your personal information to conduct analytics.
                  This can be used for quality control, internal auditing, and
                  to measure how the web application and ad campaigns are
                  performing.
                </p>

                <h4>To Ensure Security of our Services</h4>

                <p>
                  While we are offering services, it is used for the purposes of
                  identity verification, customer service, security protection,
                  fraud monitoring, archiving and backups, ensuring the security
                  of the products and services that we provide to you.
                </p>

                <h4>To Conduct Research and Development</h4>

                <p>
                  We may process your personal information to improve our
                  existing Services and help us design new ones. When we do so,
                  we may implement security measures and safeguards to protect
                  the privacy of your personal information. For example, we may
                  institute technical safeguards or administrative safeguards,
                  such as restricting access to personal information to only
                  those who need access to conduct the research and development
                  functions.
                </p>

                <h4>To Promote Our Services</h4>

                <p>
                  We use your personal information for our marketing activities.
                  For example, we may conduct targeted marketing and send you
                  information about our and our partners’ products, events, and
                  market studies that may be of interest to you.
                </p>

                <h4>To Maintain Legal and Regulatory Compliance</h4>

                <p>
                  Our Services are subject to certain laws and regulations which
                  may require us to process your personal information. For
                  example, we process your personal information to pay our
                  taxes, to fulfill our business obligations, and to manage risk
                  as required under applicable law.
                </p>

                <h3>How We Share Your Personal Information</h3>

                <p>
                  The app may share your personal information in the following
                  circumstances.
                </p>

                <h4>Affiliates</h4>

                <p>
                  Workplate is a multinational organization, with cross-border
                  legal entities and management structures. We may share your
                  personal information within our corporate organization to
                  facilitate the Services; however, we will require that your
                  personal information still be handled in the manner described
                  herein.
                </p>

                <h4>Third-Party Service Providers</h4>

                <p>
                  We may use third parties to perform certain functions on our
                  behalf to provide you with the Services such as:
                </p>

                <ul>
                  <li>
                    to process payments and complete transactions you request,
                    such as payments for our subscription service, which is
                    processed by Apple (App Store) or Google (Google Play
                    Store);
                  </li>
                  <li>
                    to process and store data, including your personal
                    information;
                  </li>
                  <li>to provide security for our Services; and</li>
                  <li>to conduct market research.</li>
                </ul>

                <p>
                  We have agreements with third-party service providers in place
                  to ensure they use adequate safeguards when processing your
                  personal information.
                </p>

                <h4>Merger, Acquisition, or Other Business Transaction</h4>

                <p>
                  We may disclose and transfer your personal information to a
                  subsequent owner, co-owner, or operator of the app or the
                  Services, or in connection with a merger, acquisition,
                  consolidation, restructuring, or the sale of substantially all
                  our interests and/or assets (i.e., a bankruptcy proceeding).
                  We shall rehire the subsequent owner, co-owner, or operator in
                  possession of your personal information to continue to be
                  subject to this Privacy Policy; otherwise, we shall request
                  that they obtain the necessary consent from you.
                </p>

                <h4>Legal Obligations and Safety</h4>

                <p>
                  We may disclose your personal information if it is reasonably
                  necessary to:
                </p>

                <ul>
                  <li>
                    Comply with a valid legal process (e.g., subpoenas,
                    warrants, court orders, etc.);
                  </li>
                  <li>
                    Comply with valid requests or investigations by public
                    authorities;
                  </li>
                  <li>Comply with applicable laws or regulations;</li>
                  <li>
                    Enforce our policies, agreements, and terms, whether in
                    litigation, other disputes, or otherwise;
                  </li>
                  <li>
                    Protect our legitimate interests, including legal interests;
                  </li>
                  <li>Protect the security or integrity of the Services; or</li>
                  <li>
                    Protect the rights, property, or safety of you, other users,
                    employees, partners and affiliates, or other natural
                    persons.
                  </li>
                </ul>

                <p>
                  We will, at our discretion, provide you with advance notice if
                  we are compelled to disclose your personal information to law
                  enforcement, public authorities, or other governmental
                  entities, unless we are prohibited from doing so by
                  governmental entities or the law.
                </p>

                <h4>With Your Consent</h4>

                <p>
                  We may transfer your personal information for other reasons
                  after obtaining your consent.
                </p>

                <h3>
                  Cookies and Similar Technologies (&ldquo;Tracking
                  Technologies&rdquo;)&nbsp;
                </h3>

                <h4>What are cookies and tracking technologies?&nbsp;</h4>

                <p>
                  Cookies are small data files, typically of letters and
                  numbers, that are placed by a web server on your device. We
                  and companies we work with may use cookies and similar
                  technologies, including pixel tags, web beacons, mobile
                  analytics features, and mobile identifiers (collectively,
                  &ldquo;tracking technologies&rdquo;) to collect information
                  for many reasons, such as to keep you logged in to your
                  account, remember your preferences and settings, analyze usage
                  of our Services, measure the performance of our Services and
                  advertising campaigns, and ensure security on our Services.
                  Some of the functions performed by tracking technologies are
                  strictly necessary for us to be able to offer you our
                  Services, while others provide us with valuable insight about
                  how we can improve or market our Services.&nbsp;
                </p>

                <h4>How to manage tracking technologies&nbsp;</h4>

                <p>
                  We use third-party tracking technologies that allow us to
                  automatically collect information through our Services that
                  can recognize your device both when it visits our Services and
                  when it visits other websites or mobile apps. We encourage you
                  to check the websites of any third-party tracking technologies
                  providers for more information about how they use the
                  information they collect.&nbsp;
                </p>

                <p>
                  Below we describe how you can manage tracking technologies on
                  our Services:&nbsp;
                </p>

                <h4>Through Your Browser/Mobile Settings</h4>

                <p>
                  You can enable, disable or delete tracking technologies via
                  your browser settings. To do this, follow the instructions
                  provided by your browser, usually located within the “Help”,
                  “Tools” or “Edit” settings of your browser or mobile device.
                  Please be aware that if you set ‘disable tracking
                  technologies’ on your browser, you may not be able to access
                  secure areas of the Services and parts of the Services may
                  also not work properly (e.g., preferences, referral links).
                </p>

                <h3>Marketing</h3>

                <p>
                  We may use your personal information to send you messages
                  about new products and services, events, or other information
                  that may be of interest to you, whether through email or other
                  methods. If you do not wish to receive such information, you
                  can change your account settings or click the
                  &ldquo;unsubscribe&rdquo; button in email communications.
                  Please note that you will not be able to opt out of receiving
                  transactional communications; otherwise, you may miss
                  important information about your account or the
                  Services.&nbsp;
                </p>

                <h3>Security</h3>

                <p>
                  The app takes the security of personal information seriously.
                  We adopt standard practices in the internet industry to
                  protect your personal information from unauthorized access,
                  disclosure, use, alteration, and corruption or loss. We shall
                  take all reasonable and feasible measures to protect your
                  personal information. We shall do everything possible to take
                  appropriate technical measures to ensure the accuracy and
                  timely update of the personal information collected about
                  you.&nbsp;
                </p>

                <p>
                  Please understand that due to the nature of the internet
                  industry, it is impossible to ensure that systems are 100%
                  secure. You should understand that the systems may encounter
                  issues due to the factors beyond our control, such as
                  malicious actors. Although the security of your personal
                  information is our highest priority, there will always be a
                  risk that your Personal Information gets compromised. We also
                  depend on you to keep your account information and other
                  personal information secure by keeping your password
                  confidential and taking precautions to keep others from
                  accessing your account.&nbsp;
                </p>

                <h3>Transfers of Your Personal Information&nbsp;</h3>

                <p>
                  The app is an international organization operating from India,
                  Singapore and the US. When you access or use our Services,
                  your personal information may be processed in the servers and
                  services located in Indian, Singaporean or US servers, or any
                  other country in which we, our Affiliates, or service
                  providers maintain facilities. Such countries or
                  juWorkplatestop accessing or using the Services.
                </p>

                <p>
                  If you are in the European Economic Area, United Kingdom, or
                  Switzerland, please refer to the section below entitled
                  Special Notice to Individuals in the European Economic Area,
                  United Kingdom, and Switzerland.
                </p>

                <h3>Access, Modification, and Deletion&nbsp;</h3>

                <p>
                  You may make changes to the information you provided in
                  conjunction with your account by logging in to your account
                  and changing the applicable information. If you are not able
                  to do so by logging into your account, or if you wish to
                  terminate and delete your account, you may contact us at{" "}
                  <a href={`mailto:${props?.auth?.webDetails?.website}`}>
                    {props?.auth?.webDetails?.website}
                  </a>
                  . Following receipt of a request from you, we will take
                  reasonable steps to make changes or delete your information as
                  required under the applicable law. &nbsp;
                </p>

                <h3>Retention</h3>

                <p>
                  In general, we keep your information for as long as necessary
                  to achieve the purposes stated in this Policy or to comply
                  with the applicable law. Otherwise we will try to delete your
                  personal information upon your request or when we no longer
                  need it for the purposes it was originally collected, although
                  we reserve the right to retain such information indefinitely
                  for the establishment or defense of legal claims, audit, or
                  fraud and/or crime prevention purposes. We will not delete any
                  personal information that also relates to other individuals,
                  unless such other individuals also wish to delete their
                  personal information at the same time.&nbsp;
                </p>

                <p>
                  We recognize that retention requirements can vary between
                  jurisdictions, but we generally apply the retention periods
                  described below.&nbsp;
                </p>

                <ul>
                  <li>
                    <strong>Account information:</strong> We will retain
                    information associated with your account as long as your
                    account remains active or as necessary to provide you with
                    the requested services.&nbsp;
                  </li>
                  <li>
                    <strong>
                      Information collected from tracking technologies:{" "}
                    </strong>
                    we generally retain such information for as long as
                    necessary for usage analytics, the establishment or defense
                    of legal claims, audit, or fraud and/or crime prevention
                    purposes. &nbsp;
                  </li>
                  <li>
                    <strong>Marketing data: </strong>Any contact information you
                    provide to us for marketing purposes, such as your name,
                    email address, and phone number, is retained on an ongoing
                    basis until you unsubscribe from our marketing
                    communications. If you unsubscribe, we will add your contact
                    information to our do-not-contact list to respect your
                    unsubscribe request.&nbsp;
                  </li>
                </ul>

                <h3>Children</h3>

                <p>
                  Our Services are not intended for use by children under the
                  age of sixteen (16). We will not knowingly collect personal
                  information directly from children under the age of sixteen
                  (16) without parental consent. If you are under the age of
                  sixteen (16), please do not provide us with information of any
                  kind whatsoever. We encourage parents and guardians to spend
                  time with their children online and to be familiar with the
                  websites they visit. If you have reason to believe that we may
                  have accidentally received information from a child under the
                  age of sixteen (16), please contact us immediately at{" "}
                  <a href={`mailto:${props?.auth?.webDetails?.website}`}>
                    {props?.auth?.webDetails?.website}
                  </a>
                  .&nbsp;
                </p>

                <h3>Do Not Track&nbsp;</h3>

                <p>
                  Some Internet browsers - like Internet Explorer, Firefox, and
                  Safari - include the ability to transmit &ldquo;Do Not
                  Track&rdquo; (&ldquo;DNT&rdquo;) signals. Since uniform
                  standards for DNT signals have not been adopted, we currently
                  do not process or respond to DNT signals on our
                  Services.&nbsp;
                </p>

                <h3>Your California Privacy Rights&nbsp;</h3>

                <p>
                  If you live in California, you can ask us for a list of third
                  parties we share your Personal Information with for marketing
                  purposes.&nbsp;
                </p>

                <p>
                  California law permits California residents to ask us for a
                  notice that identifies the categories of personal information
                  that we share with our affiliates and/or third parties for
                  marketing purposes, and that provides contact information for
                  such affiliates and/or third parties. If you are a California
                  resident and would like a copy of this notice, please submit a
                  written request to us with your name, account information, and
                  proof of California residence at{" "}
                  <a href={`mailto:${props?.auth?.webDetails?.website}`}>
                    {props?.auth?.webDetails?.website}
                  </a>
                  with the subject line &ldquo;My California Privacy
                  Rights.&rdquo;&nbsp;
                </p>

                <h3>
                  Special Notice to Individuals in the European Economic Area,
                  United Kingdom, and Switzerland&nbsp;
                </h3>

                <p>
                  This section only applies to individuals located in the
                  European Economic Area, United Kingdom, or Switzerland
                  (collectively, the &ldquo;Designated Countries&rdquo;) at the
                  time of data collection.&nbsp;
                </p>

                <p>
                  We may ask you to identify which country you are in when you
                  use or access some of the Services, or we may rely on your IP
                  address to identify which country you are located in. When we
                  rely on your IP address, we cannot apply the terms of this
                  section to any individual that masks or otherwise hides their
                  location information from us so as not to appear located in
                  the Designated Countries. If any terms in this section
                  conflict with other terms contained in this Policy, the terms
                  in this section shall apply to individuals in the Designated
                  Countries.&nbsp;
                </p>

                <h3>Our Relationship to You&nbsp;</h3>

                <p>
                  The app is a data controller with respect to any personal
                  information collected from individuals accessing or using its
                  Services. A &ldquo;data controller&rdquo; is an entity that
                  determines the purposes for which and the manner in which any
                  personal information is processed. Any third parties that act
                  as our service providers or are otherwise acting on our behalf
                  are &ldquo;data processors&rdquo; that handle your personal
                  information in accordance with our instructions.&nbsp;
                </p>

                <p>
                  We will only contact you if you are located in the Designated
                  Countries by electronic means (including email or SMS) based
                  on our legitimate interests, as permitted by applicable law or
                  your consent. If you do not want us to use your Personal
                  Information in this way, please click the unsubscribe link at
                  the bottom of any of our email messages to you or contact us
                  at the email at the bottom of this page. You can object to
                  direct marketing at any time and free of charge.&nbsp;
                </p>

                <h3>Transfer of Your Personal Information&nbsp;</h3>

                <p>
                  We transfer your personal information subject to appropriate
                  safeguards as permitted under the relevant data protection
                  laws. The transfer of your personal information out of the
                  Designated Countries is necessary for the performance of our
                  contract with you, or the performance of a contract between us
                  and another party that we entered into for your interest, or
                  for important reasons of public interest. We may also rely on
                  legal transfer mechanisms such as Standard Contractual
                  Clauses.&nbsp;
                </p>

                <h3>Individual Rights&nbsp;</h3>

                <p>
                  We provide you with the rights described below when you use
                  our Services. We may limit your individual rights requests:
                  (a) where denial of access is required or authorized by law;
                  (b) when granting access would have a negative impact on
                  other's privacy; (c) to protect our rights and properties; and
                  (d) where the request is frivolous or burdensome. If you would
                  like to exercise your rights under applicable law, please
                  contact us at the contact information provided below. Please
                  note, we may seek to verify your identity when we receive an
                  individual rights request from you to ensure the security of
                  your personal information. When we fulfill your individual
                  rights requests for rectification, erasure or restriction of
                  processing, we will notify third parties also handling the
                  relevant personal information unless this proves impossible or
                  involves disproportionate effort.
                </p>

                <ul>
                  <li>
                    <strong>Right of Access &amp; Portability.</strong> You may
                    request a copy of your personal information in our files
                    without undue delay and free of charge, unless we are
                    permitted by law to charge a fee. In certain situations, you
                    may request to receive your personal information in a
                    structured, commonly used and machine-readable format, and
                    to have us transfer your personal information directly to
                    another &ldquo;controller,&rdquo; where technically
                    feasible.&nbsp;
                  </li>
                  <li>
                    <strong>Right to Rectification</strong>. You may request to
                    correct or update any of your personal information in our
                    files.&nbsp;
                  </li>
                  <li>
                    <strong>Right to Erasure</strong>. In certain situations,
                    you have a right to have your personal information in our
                    files deleted.&nbsp;
                  </li>
                  <li>
                    <strong>Right to Restriction</strong>. Under certain
                    conditions, you have the right to restrict our processing of
                    your personal information.&nbsp;
                  </li>
                  <li>
                    <strong>Right to Object</strong>. You may object to our
                    processing at any time and as permitted by applicable law if
                    we process your personal information on the legal bases of:
                    consent; contract; or legitimate interests.&nbsp;
                  </li>
                  <li>
                    <strong>
                      Automated Individual Decision-Making, Including Profiling
                    </strong>
                    . You have the right not to be subject to a decision based
                    solely on automated processing of your personal information,
                    including profiling, which produces legal or similarly
                    significant effects on you, unless an exception applies
                    under applicable law.&nbsp;
                  </li>
                  <li>
                    <strong>Right to Lodge Complaints</strong>. If you believe
                    that we have infringed or violated your privacy rights under
                    applicable law or this Policy, please contact us at the
                    contact information below so that we can work with you to
                    resolve your concerns. You have a right to lodge a complaint
                    with a competent supervisory authority situated in a Member
                    State of your habitual residence, place of work, or place of
                    alleged infringement.&nbsp;
                  </li>
                </ul>

                <h3>Contact Us&nbsp;</h3>

                <p>
                  For any questions regarding our Privacy Policy or our privacy
                  practices, please contact us at{" "}
                  <a href={`mailto:${props?.auth?.webDetails?.website}`}>
                    {props?.auth?.webDetails?.website}
                  </a>
                </p>
              </div>
            </div>
          </section>
        </main>

        <footer>
          <div className="container">
            <p> © 2023 Workplate. All rights reserved.</p>
          </div>
        </footer>
      </div>
    </>
  );
};
const mapStateToProps = state => {
  return {
    ...state,
    websiteDetails: { ...state.auth.webDetails }
  };
};

export default compose(withRouter, connect(mapStateToProps))(PrivacyPolicy);

// export default PrivacyPolicy;
