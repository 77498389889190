import handleResponse, { encryptPayload } from "./handleResponse";
import handleResponseAxios from "./handleResponseAxios";
import axios from "axios";
import {
  BASE_URL,
  project,
  favorite,
  invitecollaborators_,
  label_,
  comment_,
  removeParticipant,
  REACT_APP_API_ENDPOINT
} from "./apiConstants";
import { getHeader, getHeaderForProfile } from "./getHeaders";

export function createProject(data, token) {
  const encryptedData = encryptPayload(data);
  const encryptObj = { encPayload: encryptedData };
  var header = null;
  if (token) {
    header = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`
    };
  } else {
    header = getHeader();
  }
  const requestOptions = {
    method: "POST",
    headers: header,
    body: JSON.stringify(encryptObj)
  };

  return fetch(REACT_APP_API_ENDPOINT + project, requestOptions)
    .then(item => handleResponse(item))
    .then(data => {
      return data;
    });
}
export function moveProject(data, token) {
  const encryptedData = encryptPayload(data);
  const encryptObj = { encPayload: encryptedData };
  var header = null;
  if (token) {
    header = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`
    };
  } else {
    header = getHeader();
  }
  const requestOptions = {
    method: "POST",
    headers: header,
    body: JSON.stringify(encryptObj)
  };
  return fetch(REACT_APP_API_ENDPOINT + project + "/move", requestOptions)
    .then(handleResponse)
    .then(data => {
      return data;
    });
}
export function updateProject(data, id) {
  const encryptedData = encryptPayload(data);
  const encryptObj = { encPayload: encryptedData };
  const requestOptions = {
    method: "PUT",
    headers: getHeader(),
    body: JSON.stringify(encryptObj)
  };
  return fetch(REACT_APP_API_ENDPOINT + project + `/${id}`, requestOptions)
    .then(handleResponse)
    .then(data => {
      return data;
    });
}
export function deleteProject(id) {
  const flag = {
    isDeleted: true
  };

  const encryptedData = encryptPayload(flag);
  const encryptObj = { encPayload: encryptedData };
  const requestOptions = {
    method: "PUT",
    headers: getHeader(),
    body: JSON.stringify(encryptObj)
  };
  return fetch(
    REACT_APP_API_ENDPOINT + project + `/${id}`,

    requestOptions
  )
    .then(handleResponse)
    .then(data => {
      return data;
    });
}

export function getProject(token) {
  var givenToken = null;
  if (token) {
    givenToken = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`
    };
  } else {
    givenToken = getHeader();
  }

  const requestOptions = {
    method: "GET",
    headers: givenToken
  };
  return fetch(REACT_APP_API_ENDPOINT + project, requestOptions)
    .then(handleResponse)
    .then(data => {
      return data;
    });
}
export function getProjectById(id, token) {
  var givenToken = null;
  if (token) {
    givenToken = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`
    };
  } else {
    givenToken = getHeader();
  }

  const requestOptions = {
    method: "GET",
    headers: givenToken
  };
  return fetch(REACT_APP_API_ENDPOINT + project + "/" + id, requestOptions)
    .then(data => handleResponse(data))
    .then(data => {
      return data;
    });
}
export function inviteCollaborators(data) {
  const encryptedData = encryptPayload(data);
  const encryptObj = { encPayload: encryptedData };
  const requestOptions = {
    method: "POST",
    headers: getHeader(),
    body: JSON.stringify(encryptObj)
  };
  return fetch(REACT_APP_API_ENDPOINT + invitecollaborators_, requestOptions)
    .then(handleResponse)
    .then(data => {
      return data;
    });
}

export function removeCollaborators(data) {
  const encryptedData = encryptPayload(data);
  const encryptObj = { encPayload: encryptedData };
  const requestOptions = {
    method: "DELETE",
    headers: getHeader(),
    body: JSON.stringify(encryptObj)
  };
  return fetch(
    REACT_APP_API_ENDPOINT + project + "/" + removeParticipant,
    requestOptions
  )
    .then(handleResponse)
    .then(data => {
      return data;
    });
}
export function AddSection(data) {
  const encryptedData = encryptPayload(data);
  const encryptObj = { encPayload: encryptedData };
  const requestOptions = {
    method: "POST",
    headers: getHeader(),
    body: JSON.stringify(encryptObj)
  };
  return fetch(REACT_APP_API_ENDPOINT + "section", requestOptions)
    .then(handleResponse)
    .then(data => {
      return data;
    });
}
export function UpdateProjectSection(data, id) {
  const encryptedData = encryptPayload(data);
  const encryptObj = { encPayload: encryptedData };
  const requestOptions = {
    method: "PUT",
    headers: getHeader(),
    body: JSON.stringify(encryptObj)
  };
  return fetch(REACT_APP_API_ENDPOINT + `section/${id}`, requestOptions)
    .then(handleResponse)
    .then(data => {
      return data;
    });
}

export function getLables(token) {
  var givenToken = null;
  if (token) {
    givenToken = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`
    };
  } else {
    givenToken = getHeader();
  }

  const requestOptions = {
    method: "GET",
    headers: givenToken
  };
  return fetch(REACT_APP_API_ENDPOINT + label_, requestOptions)
    .then(handleResponse)
    .then(data => {
      return data;
    });
}

export function getFavoriteProjects(token) {
  var givenToken = null;
  if (token) {
    givenToken = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`
    };
  } else {
    givenToken = getHeader();
  }

  const requestOptions = {
    method: "GET",
    headers: givenToken
  };
  return fetch(REACT_APP_API_ENDPOINT + "project/favourite", requestOptions)
    .then(handleResponse)
    .then(data => {
      return data;
    });
}
export function createLabel(data) {
  const encryptedData = encryptPayload(data);
  const encryptObj = { encPayload: encryptedData };
  const requestOptions = {
    method: "POST",
    headers: getHeader(),
    body: JSON.stringify(encryptObj)
  };
  return fetch(REACT_APP_API_ENDPOINT + label_, requestOptions)
    .then(handleResponse)
    .then(data => data);
}

export function deleteProjectSection(data, id) {
  const encryptedData = encryptPayload(data);
  const encryptObj = { encPayload: encryptedData };
  const requestOptions = {
    method: "PUT",
    headers: getHeader(),
    body: JSON.stringify(encryptObj)
  };
  return fetch(REACT_APP_API_ENDPOINT + `section/${id}`, requestOptions)
    .then(handleResponse)
    .then(data => {
      return data;
    });
}
export function getcomments(id) {
  const requestOptions = {
    method: "GET",
    headers: getHeader()
  };
  return fetch(
    REACT_APP_API_ENDPOINT + project + "/" + comment_ + `${id}`,
    requestOptions
  )
    .then(handleResponse)
    .then(data => {
      return data;
    });
}
export function getAttachment(id, type = "task", page, limit) {
  const requestOptions = {
    method: "GET",
    headers: getHeader()
  };
  let isValidType = type === "task" || type === "project";
  return fetch(
    REACT_APP_API_ENDPOINT +
      project +
      `/attachments/${
        isValidType ? type : "task"
      }/${id}?limit=${limit}&page=${page}`,
    requestOptions
  )
    .then(handleResponse)
    .then(data => {
      return data;
    });
}
export function deleteComment(data, id) {
  const encryptedData = encryptPayload(data);
  const encryptObj = { encPayload: encryptedData };
  const requestOptions = {
    method: "POST",
    headers: getHeader(),
    body: JSON.stringify(encryptObj)
  };
  return fetch(
    REACT_APP_API_ENDPOINT + project + "/" + comment_ + `delete/${id}`,
    requestOptions
  )
    .then(handleResponse)
    .then(data => {
      return data;
    });
}

export async function addcomment(
  data,
  updateProgress,
  cancelToken,
  setCancelToken
) {
  const form = new FormData();

  if (data.attachment !== "" && data.attachment) {
    form.append("attachment", data.attachment);
  }

  form.append("comment", data.comment);
  form.append("project_id", data.projectId);

  const cancelTokenSource = axios.CancelToken.source();
  setCancelToken(cancelTokenSource);

  return axios({
    url: REACT_APP_API_ENDPOINT + `project/comment/`,
    method: "POST",
    headers: getHeaderForProfile(),
    data: form,
    onUploadProgress: progressEvent => {
      let percentComplete = progressEvent.loaded / progressEvent.total;
      percentComplete = parseInt(percentComplete * 100);
      // console.log(percentComplete);
      updateProgress(percentComplete);
    },
    onDownloadProgress: function(progressEvent) {
      // Do whatever you want with the native progress event
    },
    cancelToken: cancelTokenSource.token
  })
    .then(response => response.data)
    .catch(error => {
      if (error?.response?.data) {
        handleResponseAxios(error.response);
        return error.response.data;
      }
    });
}
