import handleResponse from "./handleResponse";
import handleResponseAxios from "./handleResponseAxios";
import { activity, REACT_APP_API_ENDPOINT } from "./apiConstants";
import { getHeader, getHeaderForProfile } from "./getHeaders";

export const getAllActivity = (
  limit = 10,
  page = 0,
  hasProjectId,
  projectId = null,
  businessId = null
) => {
  let requestOptions = {
    method: "GET",
    headers: getHeader()
  };

  let Url = REACT_APP_API_ENDPOINT + activity + `?limit=${limit}&page=${page}`;
  if (hasProjectId && projectId) {
    Url += `&p_Id=${projectId}`;
  }
  return fetch(Url, requestOptions)
    .then(handleResponse)
    .then(data => {
      return data;
    });
};
