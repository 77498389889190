import React from "react";
import { info } from "helper/constant";
import { connect } from "react-redux";

const BrowserNotSupported = props => {
  return (
    <>
      <div className="row">
        <div
          className="col-lg-12 col-md-12 col-sm-12 col-xs-12"
          style={{
            height: "100vh",
            width: "100vh",
            margin: " 50px auto"
          }}
        >
          <div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center"
              }}
            >
              <img
                alt=""
                src={info}
                style={{
                  height: 200,
                  width: 200
                }}
              ></img>
            </div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                fontSize: 30,
                fontWeight: "bold",
                color: "#808080"
              }}
            >
              Browser not supported
            </div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                fontSize: 20,
                color: "#808080"
              }}
            >
              Use Google Chrome to access {props?.auth?.webDetails?.appName} for
              Web experience.
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = state => {
  return {
    ...state.themeChanger,
    ...state,
    topbarTheme: { ...state.themeChanger.topbarTheme }
  };
};
export default connect(mapStateToProps, null)(BrowserNotSupported);
