import React, { useEffect, useState } from "react";
import {
  iconDemo,
  EMAIL_VERIFICATION_REQUIRED,
  EMAIL_VERIFICATION_SENT,
  iconDemoBlue
} from "helper/constant";

import { connect } from "react-redux";
import { compose } from "redux";
import { Link, withRouter } from "react-router-dom";
import dotenv from "dotenv";
import { Alert } from "reactstrap";
import AuthActions from "redux/auth/actions";
import LoginWrapper from "./LoginWrapper.style";
import enhancer from "./enhancer/LoginFormEnhancer";
import Loader from "components/loader/Loader";
import {
  loginWithEmail,
  loginWithGoogle,
  getWebsiteDetails
} from "helper/services/authServices";
import Notifications, { notify } from "react-notify-toast";
import GoogleAuth from "./GoogleAuth";
import { GoogleLogin, GoogleLogout } from "react-google-login";
import { isError } from "lodash-es";
import { resendEmailVerification } from "helper/services/authServices";
import { v4 as uuidv4 } from "uuid";
import { eyeSlash, eye } from "helper/constant";
import { updateUserSettings } from "helper/services/settingsServices";
import languageActions from "redux/languageSwitcher/actions";
import Select, { components } from "react-select";
import IntlMessages from "util/intlMessages";
import InjectPlaceHolderMessage from "helper/methods/PlaceHolderText";

const { login, website_details } = AuthActions;
const { changeLanguage } = languageActions;

const colourStyles = {
  control: (base, state) => ({
    ...base,
    background: "#ffff",
    fontSize: 13,
    borderColor: "#ddd",
    boxShadow: null,
    "&:hover": {
      borderColor: "#ddd"
    }
  }),

  option: (styles, { data, isDisabled, isFocused, isSelected }) => {
    return {
      ...styles,
      backgroundColor: isDisabled
        ? null
        : isSelected
          ? "#c5c5c5"
          : isFocused
            ? "#f6f6f6"
            : null,
      color: isDisabled
        ? "#ccc"
        : isSelected
          ? "white" > 2
            ? "white"
            : "black"
          : "#808080",
      cursor: isDisabled ? "not-allowed" : "default",

      ":active": {
        ...styles[":active"],
        backgroundColor: !isDisabled && (isSelected ? "#909090" : "#f6f6f6")
      }
    };
  },
  singleValue: styles => ({ ...styles, color: "#808080" })
};

dotenv.config();
const Login = props => {
  const [isLoader, setIsLoader] = useState(false);
  const [isDisabledResend, setIsDisabledResend] = useState(false);
  const [iserror, setIsError] = useState({ status: false, message: "" });
  const [show, setShow] = useState(false);
  const [countryList, setCountryList] = useState([
    {
      value: "english",
      label: "English"
    },
    {
      value: "german",
      label: "German"
    }
  ]);

  useEffect(() => {
    handleWebsiteDetails();

    return () => {
      setIsError({ status: false, message: "" });
      setIsLoader(false);
      setIsDisabledResend(false);
      localStorage.removeItem("fcmToken");
    };
  }, []);

  const handleWebsiteDetails = async () => {
    try {
      const response = await getWebsiteDetails();
      if (response) {
        props.website_details(response?.data);
      }
    } catch (error) {
      console.error("Error fetching website details:", error);
    }
  };

  const handleGoogleLogin = async gData => {
    if (gData) {
      localStorage.removeItem("fcmToken");
      const { googleId } = gData;
      const { email } = gData.profileObj;
      const { name } = gData.profileObj;
      if (googleId && email) {
        const deviceId = uuidv4();
        setIsError({ status: false, message: "" });
        try {
          const LoginResponse = await loginWithGoogle({
            name: name,
            email: email,
            google_id: googleId,
            deviceId
          });
          if (LoginResponse) {
            if (LoginResponse.error) {
            }

            if (LoginResponse.user) {
              props.login({
                user: LoginResponse.user,
                token: LoginResponse.token
              });
            }
          }
        } catch (e) { }
      }
    }
  };
  const handleLogin = async e => {
    e.preventDefault();
    let {
      values,
      handleSubmit,
      resetForm,
      handleReset,
      setErrors,
      setStatus,
      setSubmitting
    } = props;

    try {
      if (values.email && values.password && values.password.length >= 8) {
        localStorage.removeItem("fcmToken");
        setIsError({ status: false, message: "" });
        setIsLoader(true);
        const deviceId = uuidv4();
        const loginUser = await loginWithEmail({
          email: values.email,
          password: values.password,
          deviceId: deviceId
        });
        if (loginUser.error) {
          notify.show(loginUser.error.message, "error");
          if (loginUser.error.message == EMAIL_VERIFICATION_REQUIRED) {
            setIsError({
              status: true,
              message: loginUser.error.message,
              email: values.email
            });
            setSubmitting(false);
            setStatus({
              errors: {},
              touched: {}
            });

            resetForm();
          }
          setIsLoader(false);
          console.log(loginUser.error);
        }
        if (loginUser.errors) {
          console.log(loginUser.errors);
          notify.show(loginUser.errors.message, "error");
          if (loginUser.errors.message == EMAIL_VERIFICATION_REQUIRED) {
            setIsError({
              status: true,
              message: loginUser.errors.message,
              email: values.email
            });
            resetForm({ email: "", password: "" });
            setSubmitting(false);

            // clearing errors

            // handleReset({email:'',password:''})
          }
          setIsLoader(false);
          setErrors({});
        }
        if (loginUser.user && loginUser.token) {
          setIsLoader(false);

          // notify.show("Login Successful", "success");
          props.login({
            user: loginUser.user,
            token: loginUser.token,
            deviceId: deviceId
          });
          setTimeout(() => {
            if (loginUser?.user?.isNewUserBusinessAdded == false) {
              props.history.push("/today", { token: loginUser.token });
            }
            props.history.push("/home", { token: loginUser.token });
          }, 200);
        }
      }
    } catch (e) {
      console.log(e);
      if (e.response) {
        notify.show(e.response.error.message, "error");
      }
    }
    handleSubmit();
  };

  const handleShowChange = event => {
    setShow(!show);
  };

  const isEmailValidate = email => {
    const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
  };
  const handleResendEmailVerification = async userEmail => {
    // resendEmailVerification
    if (userEmail && isEmailValidate(userEmail)) {
      try {
        setIsLoader(true);
        setIsDisabledResend(true);
        const response = await resendEmailVerification({ email: userEmail });
        if (response) {
          console.log(response);
        }

        if (response.errors) {
          setIsLoader(false);
          notify.show(response.errors.message, "error");
        }
        if (response.message === EMAIL_VERIFICATION_SENT) {
          notify.show(response.message, "success");
          setIsDisabledResend(false);
          setIsError({ status: false, message: "" });
          setIsLoader(false);
        }
      } catch (err) {
        setIsDisabledResend(false);
        setIsLoader(false);
        // notify.show(response.errors.message, "error")
      }
    }
  };
  const {
    values,
    handleChange,
    handleBlur,
    errors,
    touched,
    submitCount
  } = props;

  const loginContainer = {
    backgroundColor: "rgb(250,250,250)",
    backgroundPosition: "center center",
    backgroundSize: "cover",
    position: "fixed",
    overflow: "auto",
    top: 0,
    bottom: 0,
    height: "100vh",
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  };

  const ErrorComponment = props => {
    const field1 = props.field;
    if ((errors[field1] && touched[field1]) || submitCount > 0) {
      return (
        <span className={props.class ? props.class : "error-msg"}>
          {errors[field1]}
        </span>
      );
    } else {
      return <span />;
    }
  };

  const customStyle = {
    height: "40px",
    width: "100%"
  };

  const changeLanguageHandler = async id => {
    props.changeLanguage(id);
  };

  return (
    <div className="container-fluid" style={loginContainer}>
      <Notifications />
      <div>
        <a
          href={
            window.location.href.includes("workplate.nl")
              ? "https://workplate.nl/"
              : "https://workplate.io/"
          }
          target=""
          rel="noopener noreferrer"
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              marginBottom: 40
            }}
          >
            <img
              src={
                window.location.href.includes("workplate.nl")
                  ? iconDemo
                  : iconDemoBlue
              }
              alt="icon"
              height="50px"
              style={{ marginRight: 10 }}
            />
            {/* <div style={{ fontSize: 50, fontWeight: "600" }}>
            <IntlMessages id={"login.fiinish"} />
          </div> */}
          </div>
        </a>
        <div className="form-container">
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              fontSize: 16,
              fontWeight: "600",
              color: "black",
              paddingTop: 40
            }}
          >
            <IntlMessages id={"login.logintofiinish"} /> &nbsp;
            {/* {appName?.data?.appName} */}
            {props?.auth?.webDetails?.appName}
          </div>
          {/* <div
            className="login-icon"
            style={{ paddingTop: 60, paddingBottom: 10 }}
          >
            <img src={iconDemo} alt="icon" height="55px" />
          </div>
          <div
            className="login-title"
            style={{ fontSize: 20, color: "black", fontWeight: "600" }}
          >
            Sign In
          </div> */}
          {iserror.status && (
            <div className="plr-24" style={{ paddingTop: 10 }}>
              <div className="alert alert-warning fs-14 alert alert-warning alert-dismissible fade show">
                <IntlMessages id={"login.pleaseverifyemail"} />
                <b> {iserror.email ? iserror.email : ""}</b>{" "}
                <IntlMessages id={"login.tosignindidn't"} />
                <IntlMessages id={"login.getverificationemail"} />{" "}
                <b
                  style={{
                    textDecoration: "underline",
                    //display: "flex",
                    cursor: isDisabledResend ? "not-allowed" : "pointer"
                  }}
                  onClick={() => {
                    if (isLoader) {
                      return;
                    }
                    !isDisabledResend &&
                      handleResendEmailVerification(iserror.email);
                  }}
                >
                  {isLoader ? "Sending" : "Resend"}.
                </b>
                <button
                  type="button"
                  className="close"
                  data-dismiss="alert"
                  aria-label="Close"
                  onClick={() => setIsError({ status: false, message: "" })}
                >
                  <span aria-hidden="true">×</span>
                </button>
                {/* {iserror.status && iserror.message ? iserror.message : ""} */}
              </div>
            </div>
          )}
          <form
            className="plr-60 pb-51 pt-40"
            style={{ paddingTop: "0px !important" }}
            onSubmit={handleLogin}
          >
            <div className="form-group">
              {/* <label
                style={{ fontSize: 14, color: "black", fontWeight: "600" }}
              >
                Email
              </label> */}
              <input
                type="email"
                style={{ height: "40px" }}
                className="form-control react-form-input"
                id="email"
                onChange={handleChange}
                value={values.email || ""}
                onBlur={handleBlur}
                placeholder={InjectPlaceHolderMessage(
                  "register.email",
                  props?.LanguageSwitcher?.language?.languageId
                )}
              />
              <ErrorComponment field="email" />
            </div>

            {/* <div className="form-group" style={{ marginBottom: "10px" }}> */}
            {/* <label
                style={{ fontSize: 14, color: "black", fontWeight: "600" }}
              >
                Password
              </label> */}
            {/* <input
                style={{ height: "40px" }}
                type="password"
                className="form-control react-form-input"
                id="password"
                value={values.password || ""}
                onChange={handleChange}
                onBlur={handleBlur}
                placeholder="Password"
              />
              <ErrorComponment field="password" />
            </div> */}
            <div
              className="form-group"
              style={{ marginBottom: "16px", position: "relative" }}
            >
              {/* <label
                style={{ fontSize: 14, color: "black", fontWeight: "600" }}
              >
                Password
              </label> */}
              <input
                style={{ height: "40px", paddingRight: 45 }}
                type={show ? "text" : "password"}
                className="form-control react-form-input"
                id="password"
                value={values.password || ""}
                onChange={handleChange}
                onBlur={handleBlur}
                placeholder={InjectPlaceHolderMessage(
                  "login.password",
                  props?.LanguageSwitcher?.language?.languageId
                )}
              />
              <img
                src={show ? eyeSlash : eye}
                width={20}
                height={20}
                alt="show password"
                style={{
                  position: "absolute",
                  top: 10,
                  right: 14,
                  cursor: "pointer"
                }}
                onClick={handleShowChange}
              />
              <ErrorComponment field="password" />
            </div>

            {/* <Select
              defaultValue={countryList[0]}
              styles={colourStyles}
              placeholder={
                InjectPlaceHolderMessage(
                  "languageSwitcher.select",
                  props?.LanguageSwitcher?.language?.languageId
                ) +
                " " +
                InjectPlaceHolderMessage(
                  "languageSwitcher.label",
                  props?.LanguageSwitcher?.language?.languageId
                )
              }
              id="select_language"
              name="Select Language"
              options={countryList}
              value={
                JSON.parse(
                  JSON.parse(localStorage.getItem("persist:root"))?.language
                )?.language?.languageId == "english"
                  ? countryList[0]
                  : countryList[1]
              }
              onChange={e => {
                changeLanguageHandler(e?.value);
              }}
              components={{
                IndicatorSeparator: () => null
              }}
              className="basic-multi-select"
              classNamePrefix="select"
            /> */}

            <div className="text-right " style={{ paddingBottom: "20px" }}>
              <span
                style={{ fontSize: 12, color: "#808080", fontWeight: 500 }}
                className="text-center link-label-1"
                onClick={() => props.history.push("/forgotPassword")}
              >
                <IntlMessages id={"register.forgotpassword"} />
              </span>
            </div>
            {isLoader ? (
              <Loader height={30} width={28} style={{ color: "#fff" }} />
            ) : (
              <button
                type="submit"
                className="btn form-button"
                style={{
                  borderRadius: "5px",
                  height: "40px",
                  background: props?.auth?.webDetails?.appThemeColor
                }}
              >
                <span
                  style={{
                    fontSize: "14px",
                    color: "white",
                    fontWeight: "600"
                  }}
                >
                  {" "}
                  <IntlMessages id={"login.login"} />
                </span>
              </button>
            )}

            {/* <LoginWrapper>
              <div
                className="text-center "
                style={{
                  paddingTop: 20
                }}
              >
                <GoogleLogin
                  clientId={process.env.REACT_APP_GOOGLE_ID}
                  buttonText="Sign in with Google"
                  onSuccess={handleGoogleLogin}
                  onFailure={data => console.log(data)}
                  cookiePolicy={"single_host_origin"}
                  isSignedIn={false}
                  className="googleStyle"
                >
                </GoogleLogin>
              </div>
            </LoginWrapper> */}
            <div
              className="text-center"
              style={{
                fontSize: 13,
                paddingTop: 20,
                color: "black",
                fontWeight: "600"
              }}
            >
              <IntlMessages id={"login.title"} />
              &nbsp;{props?.auth?.webDetails?.appName}?
              <span
                style={{
                  fontSize: 13,
                  color: props?.auth?.webDetails?.appThemeColor,
                  fontWeight: "600"
                }}
                className="link-label-1"
                onClick={() => props.history.push("/register")}
              >
                {" "}
                <IntlMessages id={"register.signup"} />
              </span>
            </div>
          </form>
        </div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            marginTop: 20
          }}
        >
          <span style={{ fontSize: 14, color: "#0073E6" }}>
            <Link
              to="/privacy-policy/"
              target="_blank"
              style={{ color: "#000", fontSize: 12 }}
            >
              <span>
                <IntlMessages id={"register.registerdecription6"} />
              </span>
            </Link>
          </span>
          &nbsp;&nbsp; • &nbsp;&nbsp;
          <span style={{ fontSize: 14 }}>
            <Link
              className="link-label-1"
              to="/terms-of-use/"
              target="_blank"
              style={{ color: "#000", fontSize: 12 }}
            >
              <span>
                <IntlMessages id={"register.registerdecription5"} />
              </span>
            </Link>
          </span>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = state => {
  return {
    ...state,
    LanguageSwitcher: { ...state.LanguageSwitcher }
  };
};

export default compose(
  withRouter,
  enhancer,
  connect(mapStateToProps, { login, website_details, changeLanguage })
)(Login);
