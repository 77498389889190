import { firebaseAPIKey } from "../../constatnt";
import handleResponse, { encryptPayload } from "./handleResponse";
import {
  BASE_URL,
  notification,
  unreadNotification,
  REACT_APP_API_ENDPOINT
} from "./apiConstants";
import { getHeader } from "./getHeaders";
import {
  collaborationInvitation,
  collaborationInvitationReject,
  collaborationBusinessInvitation,
  collaborationBusinessInvitationReject
} from "./apiConstants";

export const subscribeToTopic = (token, topic) => {
  const requestOptions = {
    method: "POST",
    headers: new Headers({
      Authorization: "key=" + firebaseAPIKey
    })
  };
  return fetch(
    "https://iid.googleapis.com/iid/v1/" + token + "/rel/topics/" + topic,
    requestOptions
  )
    .then(response => {
      if (response.status < 200 || response.status >= 400) {
        throw "Error subscribing to topic: " +
          response.status +
          " - " +
          response.text();
      }
    })
    .catch(error => {
      console.error(error);
    });
};
export const unSubscribeToTopic = (token, topic) => {
  const registrationtoken = {
    to: `/topics/${topic}`,
    registration_tokens: [`${token}`]
  };
  const encryptedData = encryptPayload(registrationtoken);
  const encryptObj = { encPayload: encryptedData };
  const requestOptions = {
    method: "POST",
    headers: new Headers({
      Authorization: "key=" + firebaseAPIKey
    }),
    body: JSON.stringify(encryptObj)
  };
  return fetch("https://iid.googleapis.com/iid/v1:batchRemove", requestOptions)
    .then(response => {
      if (response.status < 200 || response.status >= 400) {
        throw "Error subscribing to topic: " +
          response.status +
          " - " +
          response.text();
      }
    })
    .catch(error => {
      console.error(error);
    });
};

export const getNotifications = (token, page = 1, limit = 3) => {
  const requestOptions = {
    method: "GET",
    headers: getHeader(token)
  };
  // notification ? page = 1 & limit=10

  return fetch(
    REACT_APP_API_ENDPOINT + notification + `?page=${page}&limit=${limit}`,
    requestOptions
  )
    .then(handleResponse)
    .then(data => {
      return data;
    });
};

export const getUnreadNotificationCount = token => {
  const requestOptions = {
    method: "GET",
    headers: getHeader(token)
  };
  return fetch(REACT_APP_API_ENDPOINT + unreadNotification, requestOptions)
    .then(handleResponse)
    .then(data => {
      return data;
    });
};

export const acceptBusinessRequest = (collabsId, invitationCode) => {
  const requestOptions = {
    method: "GET"
  };
  return fetch(
    REACT_APP_API_ENDPOINT +
      `${collaborationBusinessInvitation}/${collabsId}/${invitationCode}`,
    requestOptions
  )
    .then(handleResponse)
    .then(data => {
      return data;
    });
};

export const rejectBusinessRequest = (collabsId, invitationCode) => {
  const requestOptions = {
    method: "GET"
  };
  return fetch(
    REACT_APP_API_ENDPOINT +
      `${collaborationBusinessInvitationReject}/${collabsId}/${invitationCode}`,
    requestOptions
  )
    .then(handleResponse)
    .then(data => {
      return data;
    });
};
