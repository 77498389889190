import React from "react";
import { iconDemo, iconDemoBlue } from "helper/constant";
import { connect } from "react-redux";

const CurrentLogin = props => {
  console.log("in");
  const Container = {
    margin: "53px auto",
    maxWidth: 800,
    textAlign: "left",
    padding: 20,
    background: "#fff"
  };

  return (
    <div className="container-fluid" style={Container}>
      <p style={{ marginBottom: 60 }}>
        <div className="login-icon fs-25 text-bold" style={{ fontWeight: 600 }}>
          <img
            src={
              window.location.href.includes("workplate.nl")
                ? iconDemo
                : iconDemoBlue
            }
            alt="icon"
            height="50px"
            width="200px"
          />
        </div>
      </p>
      <h4>
        You are currently logged in as{" "}
        <b>{props.auth?.user ? props.auth.user.email : "Someone"} </b>
        and it seems that you are the one who issued the invitation recently.
        You cannot accept your own invitation. Please re-login with another
        account to fix the issue.
      </h4>
    </div>
  );
};

const mapStateToProps = state => {
  return {
    ...state.themeChanger,
    ...state,
    topbarTheme: { ...state.themeChanger.topbarTheme }
  };
};
export default connect(mapStateToProps, null)(CurrentLogin);
