import handleResponse from "./handleResponse";
import {
  BASE_URL,
  _sharebleLink,
  REACT_APP_API_ENDPOINT
} from "./apiConstants";

export function retrivePublicTask(token) {
  const requestOptions = {
    method: "GET"
  };
  if (token) {
    return fetch(
      REACT_APP_API_ENDPOINT + _sharebleLink + `?token=${token}`,
      requestOptions
    ).then(respnose => {
      if (respnose.status !== 200) {
        return false;
      }
      return respnose.text();
    });
  }
}
