import handleResponse, { encryptPayload } from "./handleResponse";
import {
  BASE_URL,
  assign,
  project,
  Business,
  removeParticipant,
  REACT_APP_API_ENDPOINT
} from "./apiConstants";
import { getHeader, getHeaderForProfile } from "./getHeaders";

export function getCollaboratorsList(businessID) {
  const requestOptions = {
    method: "GET",
    headers: getHeader()
  };
  return fetch(
    REACT_APP_API_ENDPOINT + "business/collaborator/" + businessID,
    requestOptions
  )
    .then(handleResponse)
    .then(data => {
      return data;
    });
}

export function inviteCollaborators(data) {
  const encryptedData = encryptPayload(data);
  const encryptObj = { encPayload: encryptedData };
  const requestOptions = {
    method: "POST",
    headers: getHeader(),
    body: JSON.stringify(encryptObj)
  };
  return fetch(
    REACT_APP_API_ENDPOINT + "business/invite-collaborator/",
    requestOptions
  )
    .then(handleResponse)
    .then(data => {
      return data;
    });
}
export function resendInviteCollaborators(data) {
  const encryptedData = encryptPayload(data);
  const encryptObj = { encPayload: encryptedData };
  const requestOptions = {
    method: "POST",
    headers: getHeader(),
    body: JSON.stringify(encryptObj)
  };
  return fetch(
    REACT_APP_API_ENDPOINT + "business/resend-invitation/",
    requestOptions
  )
    .then(handleResponse)
    .then(data => {
      return data;
    });
}
export function acceptBusinessInvitation(data, id, code) {
  const requestOptions = {
    method: "GET",
    headers: getHeader()
  };
  return fetch(
    REACT_APP_API_ENDPOINT +
      `business/collaboration-invitaion-accept/${id}/${code}`,
    requestOptions
  )
    .then(handleResponse)
    .then(data => {
      return data;
    });
}
export function removeCollaborators(data) {
  const encryptedData = encryptPayload(data);
  const encryptObj = { encPayload: encryptedData };
  const requestOptions = {
    method: "DELETE",
    headers: getHeader(),
    body: JSON.stringify(encryptObj)
  };
  return fetch(
    process.env.REACT_APP_API_ENDPOINT + `${Business}${removeParticipant}`,
    requestOptions
  )
    .then(handleResponse)
    .then(data => {
      return data;
    });
}
