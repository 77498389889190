import authAction from "./actions";

const initState = {
  isLogin: localStorage.getItem("isLogin")
    ? localStorage.getItem("isLogin") === "true"
    : false,
  user: localStorage.getItem("user") ? localStorage.getItem("user") : null,
  accessToken: localStorage.getItem("accessToken")
};

export default function rootReducer(state = initState, action) {
  switch (action.type) {
    case authAction.LOGIN:
      return {
        ...state,
        isLogin: action.isLogin,
        user: action.user,
        accessToken: action.accessToken,
        accessId: action.accessId || ""
      };
    case authAction.LOGOUT:
      localStorage.removeItem("fcmToken");
      return {
        ...state,
        isLogin: action.isLogin,
        accessToken: null,
        user: null,
        accessId: null
      };
    case authAction.UPDATE_USER:
      return {
        ...state,
        user: action.user
      };
    case authAction.UPDATE_USER_TYPE:
      return {
        ...state,
        user: {
          ...state.user,
          newUser: action.newUser
        }
      };
    case authAction.UPDATE_USER_DETAILS:
      return {
        ...state,
        user: {
          ...state.user,
          name: action.user.name,
          userMeta: action.user?.userMeta ? action.user?.userMeta : null
        }
      };
    case authAction.UPDATE_SETTINGS:
      return {
        ...state,
        user: {
          ...state.user,
          userSetting: action.userSetting
        }
      };
    case authAction.UPDATE_TOKEN:
      return {
        ...state,
        accessToken: action.token
      };
    case authAction.UPDATE_BUSINESS_ARCHIVE:
      return {
        ...state,
        user: {
          ...state.user,
          isArchived: action.isArchived
        }
      };
    case authAction.UPDATE_BUSINESS:
      return {
        ...state,
        user: {
          ...state.user,
          businessId: action?.business.businessId,
          businessName: action.business.businessName,
          businessThumbnailImage: action?.business?.businessMeta?.url
            ? action.business.businessMeta.url
            : null
        }
      };
    case authAction.WEBSITE_DETAILS:
      return {
        ...state,
        webDetails: action.website
      };
    default:
      return state;
  }
}
