import styled from "styled-components";

const LoginWrapper = styled.div`
  .googleStyle {
    background-color: rgb(255, 255, 255) !important;
    display: inline-flex !important;
    align-items: center !important;
    color: rgba(0, 0, 0, 0.54) !important;
    box-shadow: none !important;
    padding: 0px !important;
    border-radius: 5px !important;
    color: black !important;
    font-size: 14px !important;
    font-weight: 600 !important;
    width: 100% !important;
    justify-content: center !important;
    border: 1px solid #e6e6e6 !important;
  }
`;

export default LoginWrapper;
