import handleAuthServiceResponse, {
  encryptPayload
} from "./handleAuthServiceResponse";
import {
  BASE_URL,
  siginwithEmail,
  sigupwithEmail,
  signinwithGoogle,
  ME,
  users,
  forgotpassword,
  changepassword,
  resetpassword,
  resentEmailVerification,
  emailVerification,
  _logout,
  _updateDeviceInformation,
  REACT_APP_API_ENDPOINT
} from "./apiConstants";
import { getHeader, getHeaderForProfile } from "./getHeaders";
import workplateNL from "../../assets/images/favicon.svg";
import workplateIO from "../../assets/images/workplateio.png";

export function loginWithEmail(data) {
  const encryptedData = encryptPayload(data);
  const encryptObj = { encPayload: encryptedData };
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(encryptObj)
  };

  return fetch(REACT_APP_API_ENDPOINT + siginwithEmail, requestOptions)
    .then(handleAuthServiceResponse)
    .then(user => {
      // store user details and jwt token in local storage to keep user logged in between page refreshes
      //
      //props.login
      return user;
    });
}
export function logoutWithEmail(data, token) {
  const encryptedData = encryptPayload(data);
  const encryptObj = { encPayload: encryptedData };
  var header = null;
  if (token) {
    header = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`
    };
  } else {
    header = getHeader();
  }
  const requestOptions = {
    method: "POST",
    headers: header,
    body: JSON.stringify(encryptObj)
  };

  return fetch(REACT_APP_API_ENDPOINT + _logout, requestOptions)
    .then(handleAuthServiceResponse)
    .then(user => {
      // store user details and jwt token in local storage to keep user logged in between page refreshes
      //
      //props.login
      return user;
    });
}
export function updateDeviceInformation(data, token) {
  const encryptedData = encryptPayload(data);
  const encryptObj = { encPayload: encryptedData };
  var header = null;
  if (token) {
    header = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`
    };
  } else {
    header = getHeader();
  }
  const requestOptions = {
    method: "PUT",
    headers: header,
    body: JSON.stringify(encryptObj)
  };

  return fetch(
    REACT_APP_API_ENDPOINT + _updateDeviceInformation,
    requestOptions
  )
    .then(handleAuthServiceResponse)
    .then(user => {
      // store user details and jwt token in local storage to keep user logged in between page refreshes
      //
      //props.login
      return user;
    });
}

export function loginWithGoogle(data) {
  const encryptedData = encryptPayload(data);
  const encryptObj = { encPayload: encryptedData };
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(encryptObj)
  };

  return fetch(REACT_APP_API_ENDPOINT + signinwithGoogle, requestOptions)
    .then(handleAuthServiceResponse)
    .then(user => {
      // store user details and jwt token in local storage to keep user logged in between page refreshes
      //
      //props.login
      return user;
    });
}

export function signUpwithEmail(data) {
  const encryptedData = encryptPayload(data);
  const encryptObj = { encPayload: encryptedData };
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      HostName: window.location.host
    },
    body: JSON.stringify(encryptObj)
  };

  return fetch(REACT_APP_API_ENDPOINT + sigupwithEmail, requestOptions)
    .then(handleAuthServiceResponse)
    .then(user => {
      // store user details and jwt token in local storage to keep user logged in between page refreshes
      //
      //props.login
      return user;
    });
}

export function forgotPassword(data) {
  const encryptedData = encryptPayload(data);
  const encryptObj = { encPayload: encryptedData };
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      HostName: window.location.host
    },
    body: JSON.stringify(encryptObj)
  };

  return fetch(REACT_APP_API_ENDPOINT + forgotpassword, requestOptions)
    .then(handleAuthServiceResponse)
    .then(user => {
      // store user details and jwt token in local storage to keep user logged in between page refreshes
      //
      //props.login
      return user;
    });
}

export function getUser(token) {
  var header = null;
  if (token) {
    header = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`
    };
  } else {
    header = getHeader();
  }

  const requestOptions = {
    method: "GET",
    headers: header
  };
  return fetch(REACT_APP_API_ENDPOINT + ME, requestOptions)
    .then(handleAuthServiceResponse)
    .then(data => {
      return data;
    });
}

export function updareUserProfile(data) {
  const form = new FormData();

  if (data.profilePic !== "") {
    form.append("profilePic", data.profilePic);
  }
  form.append("name", data.name);
  form.append("designation", data.designation);
  form.append("isDeleteProfilePic", data.isDeleteProfilePic);
  const requestOptions = {
    method: "PUT",
    headers: getHeaderForProfile(),
    body: form
  };
  return fetch(REACT_APP_API_ENDPOINT + users, requestOptions)
    .then(handleAuthServiceResponse)
    .then(data => {
      return data;
    });
}

export function updatePassword(data) {
  const encryptedData = encryptPayload(data);
  const encryptObj = { encPayload: encryptedData };
  const requestOptions = {
    method: "POST",
    headers: getHeader(),
    body: JSON.stringify(encryptObj)
  };

  return fetch(REACT_APP_API_ENDPOINT + changepassword, requestOptions)
    .then(handleAuthServiceResponse)
    .then(user => {
      return user;
    });
}

export function resetPassword(data, token) {
  const encryptedData = encryptPayload(data);
  const encryptObj = { encPayload: encryptedData };
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`
    },
    body: JSON.stringify(encryptObj)
  };
  return fetch(REACT_APP_API_ENDPOINT + resetpassword, requestOptions)
    .then(handleAuthServiceResponse)
    .then(data => {
      return data;
    });
}

export function resendEmailVerification(data, token) {
  const encryptedData = encryptPayload(data);
  const encryptObj = { encPayload: encryptedData };
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
      HostName: window.location.host
    },
    body: JSON.stringify(encryptObj)
  };
  return fetch(REACT_APP_API_ENDPOINT + resentEmailVerification, requestOptions)
    .then(handleAuthServiceResponse)
    .then(data => {
      return data;
    });
}
export function verifyEmail(token) {
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
      HostName: window.location.host
    }
  };
  return fetch(
    REACT_APP_API_ENDPOINT + emailVerification + token,
    requestOptions
  )
    .then(handleAuthServiceResponse)
    .then(data => {
      return data;
    });
}

export async function getWebsiteDetails() {
  try {
    const response = await fetch(REACT_APP_API_ENDPOINT + "users/appName", {
      headers: {
        HostName: window.location.host
      }
    });
    const data = await response?.json();
    const appName = data?.data?.appName;
    const email = data?.data?.email;
    const website = data?.data?.website;
    const icon = data?.data?.icon;
    const appThemeColor = data?.data?.appThemeColor;
    let workplatepng;

    if (window.location.href.includes("app.workplate.io")) {
      workplatepng = workplateIO;
    } else {
      workplatepng = workplateNL;
    }

    // localStorage.setItem("appname", JSON.stringify(data));

    const entryContent = document.getElementById("appSupport");
    entryContent.innerHTML = entryContent.innerHTML.replaceAll(
      /Workplate|Fiinish|workplate/g,
      appName
    );

    const appnameicon = document.getElementsByClassName("iconimg");

    for (let i_3 = 0; i_3 < appnameicon.length; i_3++) {
      appnameicon[i_3].href = workplatepng;
    }

    return data;
  } catch (error) {
    localStorage.setItem(
      "appname",
      JSON.stringify({ data: { appName: "Fiinish" } })
    );
    let getData = localStorage.getItem("appname");
    const data_1 = JSON.parse(getData);
    const appName_1 = data_1?.data?.appName;
    const email_1 = data_1?.data?.email;
    const website_1 = data_1?.data?.website;
    const icon_1 = data_1?.data?.icon;

    const entryContent_1 = document.getElementById("appSupport");
    entryContent_1.innerHTML = entryContent_1.innerHTML.replaceAll(
      /Workplate|Fiinish/g,
      appName_1
    );

    const appIconElement_1 = document.getElementById("appIcon");
    if (appIconElement_1) {
      appIconElement_1.src = icon_1;
    }
    console.log("Error fetching data from the API: ", error);
  }
}
