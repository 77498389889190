// export const BASE_URL= "https://web.theappauthors.com/a169-todo/api/";
// export const BASE_URL= "http://localhost:8081/api/";

/* Auth */
export const siginwithEmail = "auth/signin/email";

export const sigupwithEmail = "auth/signup/email";
export const signinwithGoogle = "auth/signin/google";
export const resentEmailVerification = "auth/resend-verification-link";
export const emailVerification = "auth/email-verification/";
export const _logout = "auth/logout";
export const _updateDeviceInformation = "auth/update-device-information";
// shareble link

export const _sharebleLink = "project/sharablelink/getdetails";
/*  forgot password*/
export const forgotpassword = "auth/forgot-password";
export const changepassword = "auth/change-password";
export const resetpassword = "auth/reset-password";

export const ME = "users/me";

/* User profile */
export const users = "users";
/* Settings */
export const usersSettings = "users/settings";

/* Collabrators */
export const invitecollaborators_ = "project/invite-collaborator";
export const assign = "assign/";
/* Label*/
export const label_ = "label";
export const label_filter = "label-filter/";
/* create project */
export const project = "project";
/* comment */
export const comment_ = "comment/";
export const attachments_ = "attachments/";
export const favorite = "/favourite";
/*  task */
export const task_ = "task/";
export const task_complete_uncomplete = "toggle-complete/";
export const date_filter = "date-filter";
export const activity = "activity/";
export const selfAssignTask = "self-assign";
export const selfAssignOverdueTask = "self-assign/overdue";
export const selfAssignCompletedTask = "self-assign-completed-task";
export const createdByMe = "assigned-by-me";
export const createdByMeOverdue = "assigned-by-me/overdue";
export const overdue = "overdue";

export const myAssignments = "my-assignments/";

// has upcomming task
export const hasUpcommingTask = "upcoming-task";

/* sub task */
export const subtask_ = "sub-task/";

/* priority */
export const priorityfilter = "priority-filter/";

/* notification */
export const notification = "notification";
export const unreadNotification = "notification/unread";

/* collaboration invitation */
export const collaborationInvitation =
  "project/collaboration-invitaion-accept/";
export const collaborationInvitationReject =
  "project/collaboration-invitaion-reject/";
/* collabarators Invitation business */

export const collaborationBusinessInvitation =
  "business/collaboration-invitaion-accept";
export const collaborationBusinessInvitationReject =
  "business/collaboration-invitaion-reject";
export const dashboard = "task/summary";

/* Business  */
export const Business = "business/";
/* remove participant in business  */
export const removeParticipant = "remove-collaborator/";

export const REACT_APP_SOCKET_ENDPOINT = window?.location?.href?.includes(
  "workplate.nl"
)
  ? process.env.REACT_APP_SOCKET_ENDPOINT_WORKPLATE_NL
  : process.env.REACT_APP_SOCKET_ENDPOINT_WORKPLATE_IO;

export const REACT_APP_API_ENDPOINT = window?.location?.href?.includes(
  "workplate.nl"
)
  ? process.env.REACT_APP_API_ENDPOINT_WORKPLATE_NL
  : process.env.REACT_APP_API_ENDPOINT_WORKPLATE_IO;


// Please uncommit below variables for local work.

// export const REACT_APP_SOCKET_ENDPOINT = window?.location?.href?.includes(
//   "workplate.nl"
// )
//   ? process.env.REACT_APP_SOCKET_ENDPOINT_WORKPLATE_NL
//   : process.env.REACT_APP_SOCKET_ENDPOINT_LOCAL;

// export const REACT_APP_API_ENDPOINT = window?.location?.href?.includes(
//   "workplate.nl"
// )
//   ? process.env.REACT_APP_API_ENDPOINT_WORKPLATE_NL
//   : process.env.REACT_APP_API_ENDPOINT_WORKPLATE_LOCAL;
