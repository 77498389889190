import handleResponse, { encryptPayload } from "./handleResponse";
import handleResponseAxios from "./handleResponseAxios";
import {
  selfAssignTask,
  task_,
  createdByMe,
  selfAssignOverdueTask,
  createdByMeOverdue,
  REACT_APP_API_ENDPOINT
} from "./apiConstants";
import { getHeader, getHeaderForProfile } from "./getHeaders";

export const getMyAssignments = (limit, page, isCompleted = false) => {
  const requestOptions = {
    method: "GET",
    headers: getHeader()
  };

  return fetch(
    REACT_APP_API_ENDPOINT +
      task_ +
      selfAssignTask +
      `?limit=${limit}&page=${page}&completed=${isCompleted}`,
    requestOptions
  )
    .then(handleResponse)
    .then(data => {
      return data;
    });
};

export const getMyOverdueAssignments = (date = new Date(), limit, page) => {
  const encryptedData = encryptPayload({
    date
  });
  const encryptObj = { encPayload: encryptedData };
  const requestOptions = {
    method: "POST",
    headers: getHeader(),
    body: JSON.stringify(encryptObj)
  };

  return fetch(
    REACT_APP_API_ENDPOINT +
      task_ +
      selfAssignOverdueTask +
      `?limit=${limit}&page=${page}`,
    requestOptions
  )
    .then(handleResponse)
    .then(data => {
      return data;
    });
};
export const getCreatedByMeAssignment = (limit, page, isCompleted) => {
  const requestOptions = {
    method: "GET",
    headers: getHeader()
  };

  return fetch(
    REACT_APP_API_ENDPOINT +
      task_ +
      createdByMe +
      `?limit=${limit}&page=${page}&completed=${isCompleted}`,
    requestOptions
  )
    .then(handleResponse)
    .then(data => {
      return data;
    });
};

export const getCreatedByMeOverdueAssignment = (
  date = new Date(),
  limit,
  page
) => {
  const encryptedData = encryptPayload({
    date
  });
  const encryptObj = { encPayload: encryptedData };
  const requestOptions = {
    method: "POST",
    headers: getHeader(),
    body: JSON.stringify(encryptObj)
  };

  return fetch(
    process.env.REACT_APP_API_ENDPOINT +
      task_ +
      createdByMeOverdue +
      `?limit=${limit}&page=${page}`,
    requestOptions
  )
    .then(handleResponse)
    .then(data => {
      return data;
    });
};
