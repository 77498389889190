export const AppName = "ToDo";
export const drawerWidth = "310px";
export const miniDrawerWidth = "0px";
export const themeSettingDrawerWidth = "300px";
export const chatDrawerWidth = "260px";
export const chatMiniDrawerWidth = "0px";
export const EMAIL_VERIFICATION_REQUIRED = "Please verify your email";
export const EMAIL_VERIFICATION_SENT = "Email Verification link has been sent";
export const USER_ALREADY_VERIFIED = "Error: User is already verified";
export const editPencil = require("assets/images/editPencil.svg");
export const editPencilIcon = require("assets/images/editPencilIcon.svg");
export const editProfilePic = require("assets/images/edit.svg");
export const recurrenceIcon = require("assets/images/recurrenceIcon.svg");
export const profilePlus = require("assets/images/NotificationUserInvitation.svg");
export const joined = require("assets/images/joined.svg");
export const added = require("assets/images/added.svg");
export const mentioned = require("assets/images/mention.svg");
export const assignNotification = require("assets/images/taskAssign.svg");
export const reminder = require("assets/images/reminder.svg");
export const overdueTask = require("assets/images/overdue.svg");
export const declained = require("assets/images/declained.svg");
export const acceptInvitation = require("assets/images/acceptInvitation.png");
export const search = require("assets/images/search.svg");
export const searchNotFound = require("assets/images/search-not-found.svg");
export const inviteuser = require("assets/images/inviteuser.svg");
export const archive = require("assets/images/archive.svg");

export const checked = require("assets/images/checked.svg");
export const checklistNotificationIcon = require("assets/images/checklistNotificationIcon.png");
export const dangerNotificationIcon = require("assets/images/dangerNotificationIcon.png");
export const pdfIcon = require("assets/images/pdficon.png");
export const filterGlobalSearch = require("assets/images/filterGlobalSearch.png");
export const filterGlobalSearchBlackImage = require("assets/images/filter.svg");

export const AssignPriorityIcons = require("assets/images/AssignPriorityIcons.svg");
export const zip = require("assets/images/zip.png");
export const html = require("assets/images/html.png");
export const pdf = require("assets/images/pdf.png");

export const businessImage = require("assets/images/businessLogo.png");
export const loginBack = require("assets/images/loginback.jpg");
export const iconDemo = require("assets/images/Login.svg");
export const iconDemoBlue = require("assets/images/workplate-logo-blue.svg");

export const plusIcon = require("assets/images/plusIcon.svg");
export const NotificationIcon = require("assets/images/Notification.svg");
export const iconClock = require("assets/images/icos/iconClock.png");
export const moreOptionsEllipsis = require("assets/images/moreOptionsEllipsis.svg");
export const iconTag = require("assets/images/labels.svg");
export const iconTagWhite = require("assets/images/icos/iconTagWhite.png");
export const profile = require("assets/images/profilenew.svg");

export const ForgotIcon = require("assets/images/forgotpassword.svg");
export const locakscreenBack = require("assets/images/lockscreen.jpg");
export const ProfileLockScreen = require("assets/images/profile.jpg");
export const errorback = require("assets/images/errorback.jpg");

export const page_not_found = require("assets/images/page-not-found.png");
export const clock = require("assets/images/clock.png");
export const clock_circular = require("assets/images/clock-circular-outline.png");
export const useradd = require("assets/images/useradd.svg");
export const user = require("assets/images/user.png");

export const todays = require("assets/images/todays.png");
export const labels = require("assets/images/labels.png");
export const labelsIcon = require("assets/images/labelsIcon.svg");
export const filters = require("assets/images/filters.png");
export const projects = require("assets/images/projects.png");
export const upcoming = require("assets/images/upcoming.png");
export const add = require("assets/images/add.png");
export const assign = require("assets/images/assign.png");
export const assignIcon = require("assets/images/assignIcon.svg");
export const alarm = require("assets/images/alarm.png");
export const google = require("assets/images/google.png");
export const priorityFlag = require("assets/images/priorityFlag.svg");
export const attachment = require("assets/images/attachmentnew.png");
export const share = require("assets/images/share.png");
export const dates = require("assets/images/dates.png");
export const checklist = require("assets/images/checklist.png");
export const labelsmodal = require("assets/images/labelsmodal.png");
export const prioritymodal = require("assets/images/prioritymodal.png");
export const txtIcon = require("assets/images/txtIcon.png");
export const filePlaceholder = require("assets/images/file.png");
export const noInternet = require("assets/images/no-internet.png");
export const label = require("assets/images/label.png");
export const commentnew = require("assets/images/commentnew.svg");
export const basecamp = require("assets/images/basecamp.png");

export const ProjectCollaboratorsCross = require("assets/images/ProjectCollaboratorsCross.svg");
export const SearchHeaderPriorityLableImage = require("assets/images/SearchHeaderPriorityLableImage.svg");
export const AssignTaskImage = require("assets/images/AssignTaskImage.svg");
export const LeftWeekDatePickerImage = require("assets/images/LeftWeekDatePickerImage.svg");
export const RightWeekDatePickerImage = require("assets/images/RightWeekDatePickerImage.svg");

export const ListGridCheckImage = require("assets/images/ListGridCheckImage.svg");
export const ListGridCircleImage = require("assets/images/ListGridCircleImage.svg");

export const TaskChecklistCardDeleteImage = require("assets/images/TaskChecklistCardDeleteImage.svg");
export const TaskChecklistCardEditPencilImage = require("assets/images/TaskChecklistCardEditPencilImage.svg");
export const TaskChecklistCardItemImage = require("assets/images/TaskChecklistCardItemImage.png");

export const completedCheck = require("assets/images/completedCheck.svg");
export const subtaskItemChecked = require("assets/images/subtaskItemChecked.svg");

export const TaskDialogDescriptionImage = require("assets/images/TaskDialogDescriptionImage.svg");
export const TaskDialogChecklistImage = require("assets/images/TaskDialogChecklistImage.svg");
export const TaskDialogAttachmentImage = require("assets/images/TaskDialogAttachmentImage.svg");
export const TaskDialogAssignTaskImage = require("assets/images/TaskDialogAssignTaskImage.svg");
export const TaskDialogCrossImage = require("assets/images/TaskDialogCrossImage.svg");
export const TaskModalEditIcon = require("assets/images/TaskModalEditIcon.svg");
export const TaskDialogActivityImage = require("assets/images/TaskDialogActivityImage.svg");
export const ReactionsPopoverImage = require("assets/images/ReactionsPopoverImage.svg");

export const CardTaskCalenderImage = require("assets/images/CardTaskCalenderImage.svg");
export const WorkspacePopoverCrossIcon = require("assets/images/WorkspacePopoverCrossIcon.svg");
export const HeaderDownArrowImage = require("assets/images/HeaderDownArrowImage.svg");

export const PtrojectPopOverArchiveImage = require("assets/images/PtrojectPopOverArchiveImage.svg");
export const PtrojectPopOverEditImage = require("assets/images/PtrojectPopOverEditImage.svg");
export const PtrojectPopOverFavouriteImage = require("assets/images/PtrojectPopOverFavouriteImage.svg");
export const PtrojectPopOverMoveImage = require("assets/images/PtrojectPopOverMoveImage.svg");
export const PtrojectPopOverShareImage = require("assets/images/PtrojectPopOverShareImage.svg");

export const svgPriority_1 = require("assets/images/red.svg");
export const svgPriority_2 = require("assets/images/orange.svg");
export const svgPriority_3 = require("assets/images/skyblue.svg");
export const svgPriority_4 = require("assets/images/green.svg");
export const svgNo_priority = require("assets/images/prioritynew.svg");

export const todaynew = require("assets/images/today.svg");
export const upcomingnew = require("assets/images/upcomingIcon.svg");
export const projectsnew = require("assets/images/projectsnew.svg");
export const labelsnew = require("assets/images/labels.svg");
export const filternew = require("assets/images/filters.svg");
export const addnew = require("assets/images/add.svg");

export const InfoIconsCoustomAlert = require("assets/images/InfoIconsCoustomAlert.svg");

export const SidebarDownArrow = require("assets/images/SidebarDownArrow.svg");
export const SidebarUpArrow = require("assets/images/SidebarUpArrow.svg");

export const assignnew = require("assets/images/assign.svg");
export const labelnew = require("assets/images/label.svg");
export const alarmnew = require("assets/images/alarm.svg");

export const AddSectionCardImage = require("assets/images/AddSectionCardImage.svg");

export const NewTaskPriorityCross = require("assets/images/NewTaskPriorityCross.svg");

export const hamburgerHeaderIcon = require("assets/images/hamburgerHeaderIcon.svg");
export const addHeaderIcon = require("assets/images/addHeaderIcon.svg");
export const NotificationHeaderIcon = require("assets/images/NotificationHeaderIcon.svg");
export const addProjectTabImage = require("assets/images/addProjectTabImage.svg");

export const settingsHeaderPopoverImage = require("assets/images/settingsHeaderPopoverImage.svg");
export const profileHeaderPopoverImage = require("assets/images/profileHeaderPopoverImage.svg");
export const logoutHeaderPopoverImage = require("assets/images/logoutHeaderPopoverImage.svg");

export const AddWorkspaceImage = require("assets/images/AddWorkspaceImage.svg");
export const EditIconModal = require("assets/images/EditIconModal.svg");
export const TreshImageCreateWorkSpace = require("assets/images/TreshImageCreateWorkSpace.svg");
export const crossIcon = require("assets/images/crossIcon.svg");

export const taskIcon = require("assets/images/task.svg");
export const projectIcon = require("assets/images/projectsIcon.svg");
export const group = require("assets/images/group.svg");
export const notebook = require("assets/images/notebook.svg");
export const invite = require("assets/images/invite.svg");

export const todayIcon = require("assets/images/todayIcon.svg");
export const singleCheckIcon = require("assets/images/singleCheckIcon.svg");

export const eyeSlash = require("assets/images/eye-slash.svg");
export const eye = require("assets/images/eye.svg");
export const project_clndr = require("assets/images/invite.svg");
export const workspaceIcon = require("assets/images/workspaceIcon.svg");
export const workspaceSymbolIcon = require("assets/images/workspaceSymbolIcon.svg");
export const sectionIcon = require("assets/images/sectionIcon.svg");
export const newProjectPlus = require("assets/images/newProjectPlus.svg");
export const messageIcon = require("assets/fontawesome/svgs/solid/messages.svg");

export const dotIcon = require("assets/images/dotIcon.svg");
export const MoreOptionsDotIcons = require("assets/images/MoreOptionsDotIcons.svg");
export const taskActivity = require("assets/images/taskActivity.svg");
export const cross = require("assets/images/cross.svg");

export const sharenew = require("assets/images/share.svg");
export const attachmentnew = require("assets/images/attachment.svg");
export const prioritynewmodal = require("assets/images/prioritymodal.svg");
export const labelsnewmodal = require("assets/images/labelsmodal.svg");
export const deleteProfile = require("assets/images/delete.svg");
export const deleteIcon = require("assets/images/deleteIcon.svg");
export const checklistnewmodal = require("assets/images/checklistnewmodal.png");
export const datesnewmodal = require("assets/images/dates.svg");

export const list = require("assets/images/list.svg");
export const gridicon = require("assets/images/gridicon.svg");
export const addusers = require("assets/images/addusers.png");
export const addGroup = require("assets/images/add-group .png");
export const clipBoard = require("assets/images/clipboard .png");
export const more = require("assets/images/more.png");

export const trash = require("assets/images/trash.svg");
export const DownloadAllAttachmentsImage = require("assets/images/DownloadAllAttachmentsImage.svg");

export const CrossTaskDetailsActivityImage = require("assets/images/CrossTaskDetailsActivityImage.svg");
export const DuedateChange = require("assets/images/DuedateChange.png");
export const changedate = require("assets/images/changedate.svg");

export const CrossSearchPopoverChips = require("assets/images/CrossSearchPopoverChips.png");
export const info = require("assets/images/info.png");

export const ViewArchivedItemsMoreOptions = require("assets/images/ViewArchivedItemsMoreOptions.svg");
export const ViewAsListMoreOptions = require("assets/images/ViewAsListMoreOptions.svg");
export const EditProjectMoreOptions = require("assets/images/EditProjectMoreOptions.svg");
export const AddListMoreOptions = require("assets/images/AddListMoreOptions.svg");
export const AddToFavouriteMoreOptions = require("assets/images/AddToFavouriteMoreOptions.svg");
export const ArchiveProjectMoreOptions = require("assets/images/ArchiveProjectMoreOptions.svg");
export const gridMoreOptions = require("assets/images/gridMoreOptions.svg");
export const SingleCheckLable = require("assets/images/SingleCheckLable.png");

export const grid = require("assets/images/grid.png");
export const bin = require("assets/images/bin.png");
export const edit = require("assets/images/edit.png");
export const data_not_found = require("assets/images/data_not_found.svg");
export const upcomming_data_not_found = require("assets/images/upcoming-task.svg");

export const Img6464 = require("assets/images/6464Img.svg");
// export const boardBanner = require("assets/images/boardbanner.jpeg");

export const close_Icon = require("assets/images/close_icon.svg");
export const threeDot_Icon = require("assets/images/threeDot_Icon.svg");
export const editList_icon = require("assets/images/editList_icon.svg");

export const archiveList_icon = require("assets/images/archiveList_icon.svg");
export const duplicateList_icon = require("assets/images/duplicateList_icon.svg");
export const deleteList_icon = require("assets/images/deleteList_icon.svg");
export const moveList_icon = require("assets/images/moveList_icon.svg");

export const people3 = require("assets/images/boardImages/people3.jpeg");

// E-mail icon
export const emailIcon = require("assets/images/email.png");

// export const REACT_APP_SOCKET_ENDPOINT = window?.location?.href?.includes("workplate.nl") ? "wss://app.workplate.nl/" : "wss://app.workplate.io/";
