import React, { useMemo, useState } from "react";
import {
  loginBack,
  iconDemo,
  EMAIL_VERIFICATION_SENT,
  iconDemoBlue
} from "helper/constant";
import { connect } from "react-redux";
import { compose } from "redux";
import { Link, withRouter } from "react-router-dom";
import enhancer from "./enhancer/RegisterFormEnhancer";
import { getWebsiteDetails, signUpwithEmail } from "helper/services/authServices";
import Loader from "components/loader/Loader";
import Notifications, { notify } from "react-notify-toast";
import AuthActions from "redux/auth/actions";
import { v4 as uuidv4 } from "uuid";
import { eyeSlash, eye } from "helper/constant";
import IntlMessages from "util/intlMessages";
import InjectPlaceHolderMessage from "helper/methods/PlaceHolderText";
const { login, update_user_type } = AuthActions;
const Register = props => {
  const [newEmail, setNewEmail] = useState("");
  const [show, setShow] = useState(false);
  const [isLoader, setIsLoader] = useState(false);

  React.useEffect(() => {
    const params = new URLSearchParams(props.location.search);

    setNewEmail(params.get("email"));
  }, [props.location.search]);
  useMemo(() => getWebsiteDetails(), []);

  const handleShowChange = event => {
    setShow(!show);
  };

  const handleRegister = async e => {
    let { values, isValid, handleSubmit } = props;
    e.preventDefault();

    if (newEmail) {
      values.email = newEmail;
    }
    if (values.email && values.password && values.name) {
      if (isValid) {
        setIsLoader(true);
        try {
          // Capitalize First Letter in Value.Name
          const CapitalizeFirstLetter = values.name;
          const FirstLetter =
            CapitalizeFirstLetter.charAt(0).toUpperCase() +
            CapitalizeFirstLetter.slice(1);
          const deviceId = uuidv4();
          localStorage.removeItem("fcmToken");
          const RegisterUser = await signUpwithEmail({
            email: values.email,
            name: FirstLetter,
            password: values.password,
            deviceId: deviceId
          });
          if (RegisterUser) {
            setIsLoader(false);
            if (RegisterUser.error) {
              notify.show(RegisterUser.error.message, "error");
            }
            if (
              RegisterUser.message &&
              RegisterUser.message === EMAIL_VERIFICATION_SENT
            ) {
              props.history.push("/thank-you", {
                email: values.email
                // newUser: true
              });
            }
            if (RegisterUser.user && RegisterUser.token) {
              props.login({
                user: RegisterUser.user,
                token: RegisterUser.token,
                deviceId: deviceId
              });
              props.update_user_type(true);
              props.history.push("/today", {
                token: RegisterUser.token
                // newUser: true
              });
              // window.location.reload("/today");
            }
          }
        } catch (e) {
          setIsLoader(false);
        }
      }
    }
    handleSubmit();
  };

  const { handleChange, handleBlur, errors, touched, submitCount } = props;

  const loginContainer = {
    backgroundColor: "rgb(250,250,250)",
    backgroundPosition: "center center",
    backgroundSize: "cover",
    position: "fixed",
    overflow: "auto",
    top: 0,
    bottom: 0,
    height: "100vh",
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  };

  const ErrorComponment = props => {
    const field1 = props.field;
    if ((errors[field1] && touched[field1]) || submitCount > 0) {
      return (
        <span className={props.class ? props.class : "error-msg"}>
          {errors[field1]}
        </span>
      );
    } else {
      return <span />;
    }
  };

  return (
    <div className="container-fluid" style={loginContainer}>
      <Notifications />
      <div>
        <div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              marginBottom: 40
            }}
          >
            <img
              src={
                window.location.href.includes("workplate.nl")
                  ? iconDemo
                  : iconDemoBlue
              }
              alt="icon"
              height="50px"
              style={{ marginRight: 10 }}
            />
            {/* <div style={{ fontSize: 50, fontWeight: "600" }}>
              {process.env.REACT_APP_APP_NAME}
            </div> */}
          </div>

          <div className="form-container">
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                fontSize: 16,
                fontWeight: "600",
                color: "black",
                paddingTop: 30
              }}
            >
              <IntlMessages id={"register.registerdecription3"} />
              &nbsp;{props?.auth?.webDetails?.appName}
            </div>
            {/* <div
            className="login-icon"
            style={{ paddingTop: 60, paddingBottom: 10 }}
          >
            <img src={iconDemo} alt="icon" height="55px" />
          </div>
          <div
            className="login-title"
            style={{ fontSize: 20, color: "black", fontWeight: "600" }}
          >
            Create a New Account
          </div> */}
            <form className="plr-60 pb-35 pt-33 " onSubmit={handleRegister}>
              <div className="form-group">
                {/* <label
                style={{ fontSize: 14, color: "black", fontWeight: "600" }}
              >
                {" "}
                Name
              </label> */}
                <input
                  style={{ height: "40px" }}
                  type="text"
                  className="form-control react-form-input"
                  id="name"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  placeholder={InjectPlaceHolderMessage(
                    "register.name",
                    props?.LanguageSwitcher?.language?.languageId
                  )}
                />
                <ErrorComponment field="name" />
              </div>

              <div className="form-group">
                {/* <label
                style={{ fontSize: 14, color: "black", fontWeight: "600" }}
              >
                Email
              </label> */}
                {newEmail ? (
                  <>
                    <input
                      style={{ height: "40px" }}
                      type="email"
                      className="form-control react-form-input"
                      id="email"
                      disabled
                      value={newEmail}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      placeholder={InjectPlaceHolderMessage(
                        "register.email",
                        props?.LanguageSwitcher?.language?.languageId
                      )}
                    />
                  </>
                ) : (
                  <>
                    <input
                      style={{ height: "40px" }}
                      type="email"
                      className="form-control react-form-input"
                      id="email"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      placeholder={InjectPlaceHolderMessage(
                        "register.email",
                        props?.LanguageSwitcher?.language?.languageId
                      )}
                    />
                    <ErrorComponment field="email" />
                  </>
                )}
              </div>

              <div
                className="form-group"
                style={{ marginBottom: "20px", position: "relative" }}
              >
                {/* <label
                style={{ fontSize: 14, color: "black", fontWeight: "600" }}
              >
                Create Password
              </label> */}
                <input
                  style={{ height: "40px", paddingRight: 45 }}
                  type={show ? "text" : "password"}
                  className="form-control react-form-input"
                  id="password"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  placeholder={InjectPlaceHolderMessage(
                    "register.createpassword",
                    props?.LanguageSwitcher?.language?.languageId
                  )}
                />
                <img
                  src={show ? eyeSlash : eye}
                  width={20}
                  height={20}
                  alt="show password"
                  style={{
                    position: "absolute",
                    top: 10,
                    right: 14,
                    cursor: "pointer"
                  }}
                  onClick={handleShowChange}
                />
                <ErrorComponment field="password" />
              </div>

              <div style={{ margin: "0px 0px 20px 0px", fontSize: 12 }}>
                <IntlMessages id={"register.registerdecription"} />
                <br></br>
                <IntlMessages id={"register.registerdecription4"} />
                &nbsp;
                <Link
                  className="link-label-1"
                  to="/terms-of-use/"
                  target="_blank"
                  style={{
                    color: props?.auth?.webDetails?.appThemeColor,
                    fontSize: 12
                  }}
                >
                  <span>
                    <IntlMessages id={"register.registerdecription5"} />
                  </span>
                </Link>
                &nbsp;
                <IntlMessages id={"register.and"} />
                &nbsp;
                <Link
                  className="link-label-1"
                  to="/privacy-policy/"
                  target="_blank"
                  style={{
                    color: props?.auth?.webDetails?.appThemeColor,
                    fontSize: 12
                  }}
                >
                  <span>
                    <IntlMessages id={"register.registerdecription6"} />
                  </span>
                </Link>
              </div>

              {isLoader ? (
                <Loader height={30} width={28} style={{ color: "#fff" }} />
              ) : (
                <button
                  type="submit"
                  className="btn form-button"
                  style={{
                    borderRadius: "5px",
                    height: "40px",
                    background: props?.auth?.webDetails?.appThemeColor
                  }}
                >
                  <span
                    style={{
                      fontSize: "14px",
                      color: "white",
                      fontWeight: "600"
                    }}
                  >
                    <IntlMessages id={"register.signup"} />
                  </span>
                </button>
              )}
              <div
                style={{
                  padding: "20px 0px 10px 0px",
                  fontSize: 14,
                  color: "black"
                }}
                className="text-center"
              >
                <IntlMessages id={"register.registerdecription2"} />
                <span
                  style={{
                    fontSize: 14,
                    color: props?.auth?.webDetails?.appThemeColor
                  }}
                  className="link-label-1"
                  onClick={() => props.history.push("/login")}
                >
                  {" "}
                  <IntlMessages id={"sidebar.login"} />
                </span>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = state => {
  return {
    ...state,
    LanguageSwitcher: { ...state.LanguageSwitcher }
  };
};

export default compose(
  withRouter,
  enhancer,
  connect(mapStateToProps, { login, update_user_type })
)(Register);
