import React, { useEffect, useState } from "react";
import { UncontrolledPopover, PopoverBody } from "reactstrap";
import "./FavouritesPopoverWrapper.style.css";
import { getUserCharColor } from "helper/methods/PlaceholderColor";
import { connect } from "react-redux";

import {
  WorkspacePopoverCrossIcon,
  HeaderDownArrowImage,
  searchNotFound
} from "helper/constant";
import { getFavoriteProjects } from "helper/services/projectServices";
import BusinessListShimmer from "components/shimmer/BusinessListShimmer";
import IntlMessages from "util/intlMessages";

const FavouritesPopover = props => {
  const {
    isOpen,
    toggle,
    target,
    PRIMARY_COLOR,
    currentWorkspaceId,
    isAlertAction,
    setIsAlertAction,
    handleRedirection,
    isFavourites,
    setIsFavourites,
    setIsFavouritesSidebar,
    isFavouritesSidebar
  } = props;
  const [favouritesArrayList, setFavouritesArrayList] = useState([]);
  const [isLoader, setIsLoader] = useState(false);

  useEffect(() => {
    if (isOpen) {
      setIsAlertAction({ status: false, id: "", isTempClosePopover: false });
      fetchFavoriteProjects();
    }
    return () => {
      setIsLoader(false);
      setFavouritesArrayList([]);
    };
  }, [isOpen]);
  const fetchFavoriteProjects = async () => {
    try {
      setIsLoader(true);
      const response = await getFavoriteProjects(
        props.auth.accessToken || props.history.location.state.token
      );
      if (response) {
        if (response.data) {
          setIsLoader(false);
          if (response.data && response.data?.length)
            setFavouritesArrayList([...response.data]);
        }
        if (response.error) {
          setIsLoader(false);
        }
      }
    } catch (error) {
      setIsLoader(false);
    }
  };

  return (
    <div>
      {isOpen ? (
        <>
          <UncontrolledPopover
            trigger="legacy"
            isOpen={
              isAlertAction.status && isAlertAction.isTempClosePopover
                ? false
                : isOpen
            }
            hideArrow
            placement={"bottom-start"}
            toggle={e => {
              if (isFavourites == true) {
                setIsFavourites(!isFavourites);
              } else if (isFavouritesSidebar == true) {
                setIsFavouritesSidebar(!isFavouritesSidebar);
              }

              // if (!isAlertAction.status) {
              // toggle();
              // }
            }}
            target={target}
          >
            <PopoverBody>
              <div className="favouritesProjectPopover" style={{ width: 312 }}>
                <div
                  className="workspace-popover-header"
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    paddingBottom: "10px"
                  }}
                >
                  <div
                    style={{
                      width: "100%",
                      fontWeight: 400,
                      color: "#808080"
                    }}
                  >
                    <IntlMessages
                      id={"globalfavouriteprojects.favouriteprojects"}
                    />
                  </div>
                  <div>
                    <img
                      className="favouritesProjectPopover"
                      src={WorkspacePopoverCrossIcon}
                      style={{
                        height: 12,
                        width: 12,
                        cursor: "pointer"
                      }}
                      onClick={() => {
                        if (isFavourites == true) {
                          setIsFavourites(!isFavourites);
                        } else if (isFavouritesSidebar == true) {
                          setIsFavouritesSidebar(!isFavouritesSidebar);
                        }
                      }}
                    ></img>
                  </div>
                </div>

                {isLoader ? (
                  <BusinessListShimmer />
                ) : (
                  <>
                    {favouritesArrayList && favouritesArrayList?.length > 0 ? (
                      favouritesArrayList.map((item, i) => {
                        return (
                          <div
                            key={i}
                            className="favourites-list"
                            title={item.name}
                            onClick={() => handleRedirection(item)}
                          >
                            <div
                              className="default-workspace-list"
                              style={{ margin: "10px 0px", padding: "5px" }}
                            >
                              <div className="workspace-Avatar">
                                <div
                                  className=" workspace-placeholder-search-popover"
                                  style={{
                                    background: item.color,
                                    cursor: "pointer",
                                    objectFit: "cover"
                                  }}
                                >
                                  <span>
                                    {item.name &&
                                      item.name.charAt(0).toUpperCase()}
                                  </span>
                                </div>
                              </div>
                              <div className="workspace-details-container">
                                <div
                                  className="workspace-title-"
                                  style={{
                                    color: "black",
                                    width: "92%",
                                    overflowWrap: "break-word",
                                    margin: "0px",
                                    fontSize: "14px",
                                    fontWeight: "500",
                                    cursor: "pointer",
                                    marginLeft: 10
                                  }}
                                >
                                  {item.name}
                                </div>
                                <div
                                  className="workspace-title-"
                                  style={{
                                    color: "rgb(128, 128, 128)",
                                    width: "92%",
                                    overflowWrap: "break-word",
                                    margin: "0px",
                                    fontSize: 12,
                                    cursor: "pointer",
                                    marginLeft: 10
                                  }}
                                >
                                  in {item.business.title}
                                </div>
                              </div>
                            </div>
                          </div>
                        );
                      })
                    ) : (
                      <div>
                        <img
                          src={searchNotFound}
                          style={{
                            padding: "0px 20px",
                            marginTop: "45px",
                            width: "318px",
                            height: "142px"
                          }}
                        />
                        <div
                          style={{
                            textAlign: "center",
                            marginTop: "20px",
                            marginBottom: "70px",
                            color: "#808080"
                          }}
                        >
                          {" "}
                          <IntlMessages
                            id={"globalfavouriteprojects.nofavouriteprojects"}
                          />
                        </div>
                      </div>
                    )}
                  </>
                )}
              </div>
            </PopoverBody>
          </UncontrolledPopover>
        </>
      ) : (
        <></>
      )}
    </div>
  );
};
const mapStateToProps = state => {
  return {
    ...state,
    topbarTheme: { ...state.themeChanger.topbarTheme }
  };
};
export default connect(mapStateToProps, null)(FavouritesPopover);
