import handleResponse, { encryptPayload } from "./handleResponse";
import { BASE_URL, subtask_, REACT_APP_API_ENDPOINT } from "./apiConstants";

import { getHeader } from "./getHeaders";

export function addSubtask(data) {
  const encryptedData = encryptPayload(data);
  const encryptObj = { encPayload: encryptedData };
  const requestOptions = {
    method: "POST",
    headers: getHeader(),
    body: JSON.stringify(encryptObj)
  };
  return fetch(REACT_APP_API_ENDPOINT + subtask_, requestOptions)
    .then(handleResponse)
    .then(data => {
      return data;
    });
}

export function updateSubtask(data, id) {
  const encryptedData = encryptPayload(data);
  const encryptObj = { encPayload: encryptedData };
  const requestOptions = {
    method: "PUT",
    headers: getHeader(),
    body: JSON.stringify(encryptObj)
  };
  return fetch(REACT_APP_API_ENDPOINT + subtask_ + `${id}`, requestOptions)
    .then(handleResponse)
    .then(data => {
      return data;
    });
}

export function getAllSubtask(data) {
  const encryptedData = encryptPayload(data);
  const encryptObj = { encPayload: encryptedData };
  const requestOptions = {
    method: "POST",
    headers: getHeader(),
    body: JSON.stringify(encryptObj)
  };
  return fetch(REACT_APP_API_ENDPOINT + subtask_ + "retrieve", requestOptions)
    .then(handleResponse)
    .then(data => {
      return data;
    });
}
