import React, { useEffect, useState } from "react";
import {
  locakscreenBack,
  ProfileLockScreen,
  iconDemo,
  EMAIL_VERIFICATION_SENT,
  iconDemoBlue
} from "helper/constant";
import "./style.css";
import { connect } from "react-redux";
import {
  getWebsiteDetails,
  resendEmailVerification
} from "helper/services/authServices";
import Notifications, { notify } from "react-notify-toast";
import Loader from "components/loader/Loader";
const EmailVerification = props => {
  const [isEmailSent, setIsEmailSent] = useState(false);
  const [isLoader, setIsLoader] = useState(false);
  const [userEmail, setUserEmail] = useState("");
  useEffect(() => {
    getWebsiteDetails();
    if (props.location.state && props.location.state.email) {
      setUserEmail(props.location.state.email);
    }

    return () => {
      setIsEmailSent(false);
    };
  }, []);
  const Container = {
    margin: "53px auto",
    maxWidth: 600,
    textAlign: "left",
    padding: 20,
    background: "#fff"
  };

  const handleResendEmailVerification = async () => {
    // resendEmailVerification
    if (userEmail && isEmailValidate(userEmail) && !isEmailSent) {
      try {
        setIsLoader(true);
        const response = await resendEmailVerification({ email: userEmail });
        if (response) {
          console.log(response);
        }
        setIsEmailSent(true);
        if (response.errors) {
          setIsLoader(true);
          notify.show(response.errors.message, "error");
        }
        if (response.message === EMAIL_VERIFICATION_SENT) {
          setIsEmailSent(true);
        }
        setIsLoader(false);
      } catch (err) {
        setIsLoader(false);
        // notify.show(response.errors.message, "error")
      }
    }
  };
  const isEmailValidate = email => {
    const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
  };

  return (
    <div className="container-fluid" style={Container}>
      <Notifications />
      <p style={{ marginBottom: 60 }}>
        <div className="login-icon fs-25 text-bold" style={{ fontWeight: 600 }}>
          <img
            src={
              window.location.href.includes("workplate.nl")
                ? iconDemo
                : iconDemoBlue
            }
            alt="icon"
            height="50px"
            width="200px"
          />
        </div>
      </p>
      <div className="container">
        <div className="row">
          <h1>Please verify your email</h1>
        </div>
        <div className="row" style={{ paddingTop: 40 }}>
          <p className="fs-20">
            An account verification link is sent to{" "}
            <b>{userEmail && userEmail != "" ? userEmail : ""}</b>. Once you
            verify your email address, you and your team can get started in
            &nbsp;{props?.auth?.webDetails?.appName}.
          </p>
        </div>
        <div
          className="row text-center"
          style={{ paddingTop: 40, justifyContent: "center" }}
        >
          <div
            className=" flex-x text-center "
            style={{
              alignContent: "center",
              alignItems: "center",
              justifyContent: "center",
              background: props?.auth?.webDetails?.appThemeColor
            }}
          >
            <div
              className="login-button"
              style={{
                background: props?.auth?.webDetails?.appThemeColor
              }}
              onClick={() => props.history.push("/login")}
            >
              {/* <img
                src={iconDemo}
                alt="icon"
                height="25px"
                width="20px"
                style={{ paddingRight: 2 }}
              />{" "} */}
              Login now
            </div>
          </div>
        </div>
        <div className="row fs-20" style={{ paddingTop: 40 }}>
          <p className="fs-20 flex-x">
            Didn't receive an email?{" "}
            {!isEmailSent ? (
              <span
                className="link-underline flex-x"
                onClick={() => handleResendEmailVerification()}
              >
                {" "}
                &nbsp;Resend email
                {isLoader && <Loader height="30px" width="28px" />}.
              </span>
            ) : (
              <span className="email-sent">
                &nbsp;&nbsp;
                <i className="fa fa-check " /> Email Sent.
              </span>
            )}
          </p>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = state => {
  return {
    ...state.themeChanger,
    ...state,
    topbarTheme: { ...state.themeChanger.topbarTheme }
  };
};
export default connect(mapStateToProps, null)(EmailVerification);
