import React, { useEffect, useState } from "react";
import { threeDot_Icon } from "helper/constant";
import TaskCommentCardWrapper from "components/TaskDetails/TaskCommentCardWrapper.js";
import TaskCommentPopover from "components/TaskDetails/TaskCommentPopover.jsx";
import { profileBackground } from "helper/methods/getUserAvatarColor";
import moment from "moment";
import {
  ListGridCheckImage,
  CrossTaskDetailsActivityImage
} from "helper/constant";
import CommentAttachment from "components/projects/comments/CommentAttachment";
import profileModelAction from "redux/profileModel/actions";
import Shimmer from "components/shimmer/Shimmer";
import { addAttachmentcomment } from "helper/services/taskServices";
import ReactionsPopover from "components/projects/comments/ReactionsPopover";
import ReactionsEmoji from "components/projects/comments/ReactionsEmoji";
import TaskactivityListSingle from "components/scrumboard/TaskActivitySingle";
import {
  addReactions,
  deleteReactions,
  deleteAttachmentComment
} from "helper/services/taskServices";

import { addTaskcommentEmoji, deletTaskcommentEmoji } from "services/socket";
import { taskDetailsSocketRoomPrefix } from "constatnt";
import TrixEditor from "components/Editors/trix/TrixEditor";
import Loader from "components/loader/Loader";
import TrixEditorWrapper from "./TrixEditorWrapper.style";
import "assets/css/taskdetailspopover.css";
import { connect } from "react-redux";
import IntlMessages from "util/intlMessages";
import "../../assets/css/ogdisplay.css";
const { open_profile_modal } = profileModelAction;

const TaskCommentCard = props => {
  const {
    handleAddComment,
    currentUser,
    openCustomAlert,
    isFetchCommentsLoader,
    showNotifications,
    taskId,
    projectId,
    doRefreshComment,
    collaboratorListArr,
    ActivityHistory,
    setActivityHistory,
    PRIMARY_COLOR,
    mainDivRef,
    isTrixDragEnter,
    commentInput,
    setCommentInput
    // setAllFileDrop,
    // setIsDrag,
    // isDrag,
    // allFileDrop
  } = props;
  const [emojiArr, setEmojiArr] = useState([]);
  const [comment, setComment] = useState("");
  const [commentAttachmentsUrl, setCommentAttachmentsUrl] = useState([]);
  const [fileToUpload, setFileToUpload] = useState(null);
  // uploading count and uploaded count for attachments which are uploaded that is uploaded count
  // and which are uploading if both same than enable button else disabled button
  const [uploadingCount, setUploadingCount] = useState(0);
  const [uploadedCount, setUploadedCount] = useState(0);
  const [isTaskCommentsPopover, setIsTaskCommentsPopover] = useState({
    status: false,
    data: "",
    target: "",
    commentAttachments: false,
    isCurrentUser: false
  });

  /* Code Redux Implementation */

  const { isDrag, fileName, isFileDropAllowed } = props.fileDropActions;

  const [reduxFileObj, setreduxFileObj] = useState();

  // fileDropActions
  // const { isDrag, file,
  //   fileName,
  //   isFileDropAllowed } = useSelector(state => state.fileDropActions)

  // useEffect(() => {
  //   setCommentInput(isDrag ? isDrag : commentInput)
  //   // setIsTrixDragEnter(isDrag ? isDrag : isTrixDragEnter)
  //   if (isDrag === false && file != null && isFileDropAllowed) {
  //     console.log({
  //       message: "Trix Editor Config",
  //       isDrag,
  //       file,
  //       fileName,
  //       isFileDropAllowed
  //     })
  //     setreduxFileUrl(file)
  //     setreduxFileObj(fileName)
  //   }
  // }, [isDrag, file,
  //   fileName,
  //   isFileDropAllowed])

  const [isEditorCommentAddLoader, setIsEditorCommentAddloader] = useState(
    false
  );
  const [isCommentUploadLoader, setIsCommentUploadLoader] = useState(false);
  const [attcahmentProgress, setUploadAttcahmentProgress] = useState(false);
  const [uploadAttcahment, setUploadAttcahment] = useState(false);
  const [cancelToken, setCancelToken] = useState(false);
  const [isDragEnter, setIsDragEnter] = useState(false);
  const [isExceedAttachment, setIsExceedAttachment] = useState(false);

  const getDropBorderStyle = () => {
    if (isTrixDragEnter || isDrag) {
      return {
        border: "1px solid green",
        borderRadius: 5,
        borderStyle: "dashed",
        backgroundColor: "#1dbd053b"
      };
    }
    if (isExceedAttachment) {
      return {
        border: "1px solid red",
        borderRadius: 5,
        borderStyle: "dashed",
        backgroundColor: "#F8F8F8"
      };
    }
    return {
      border: "0px solid #ebebeb",
      borderRadius: 5,
      borderStyle: "normal",
      backgroundColor: ""
    };
  };

  useEffect(() => {
    setCommentInput(isDrag ? isDrag : commentInput);
    if (isDrag === false && fileName !== null && isFileDropAllowed) {
      setreduxFileObj(fileName);
    }
    return () => {
      setIsDragEnter(false);
      setCommentAttachmentsUrl([]);
    };
  }, [isDrag, fileName, isFileDropAllowed]);
  const updateProgress = percentComplete => {
    setUploadAttcahmentProgress(percentComplete);
    return 0;
  };

  useEffect(() => {
    mainDivRef &&
      mainDivRef.current &&
      mainDivRef.current.scrollIntoView({ block: "end" });
  }, [isTrixDragEnter]);

  const cancelRequest = () => {
    cancelToken.cancel();
    setFileToUpload(null);
    setIsCommentUploadLoader(false);
    setUploadAttcahmentProgress(false);
  };

  const toggleTaskCommentsMenu = (data, target, commentMeta, commentBy) => {
    // console.log(currentUser);
    if (isTaskCommentsPopover.status) {
      setIsTaskCommentsPopover({
        status: false,
        data: "",
        target: "",
        commentAttachments: false
      });
    } else {
      setIsTaskCommentsPopover({
        status: true,
        data: data || "",
        target: target || "",
        commentAttachments:
          commentMeta && commentMeta !== null ? commentMeta : false,
        isCurrentUser: currentUser?.id === commentBy?.id
      });
    }
    // console.log(data, target);
  };
  const handleDocumentDownload = async (url, name) => {
    const image = await fetch(url);
    const imageBlog = await image.blob();
    const imageURL = URL.createObjectURL(imageBlog);

    const link = document.createElement("a");
    link.href = imageURL;
    link.download = name;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };
  const uploadAttachment = async (
    fileData,
    temporaryId,
    progressCallback,
    successCallback,
    errorCallback,
    setCancelTokenAttachment
  ) => {
    if (fileData) {
      setUploadingCount(c => (c == 0 ? 1 : c + 1));

      const Response = await addAttachmentcomment(
        {
          attachment: fileData,
          comment: null,
          taskId: taskId,
          projectId: projectId.toString()
        },
        progressCallback,
        cancelToken,
        data => {
          setCancelToken(data);
          setCancelTokenAttachment(data);
        }
      );
      if (Response) {
        if (Response.data) {
          successCallback(Response);
          if (Response.data) {
            setUploadedCount(c => (c == 0 ? 1 : c + 1));
          }
        }
        if (Response.error) {
          setUploadingCount(c => (c == 0 ? 0 : c - 1));
          showNotifications();
        }
      }
    }
  };
  const doDeleteAttachmentComment = async (attachmentsId, successCallback) => {
    // if([...deleteAttachmentsId].includes(attachmentsId)){}
    if (attachmentsId) {
      const response = await deleteAttachmentComment(attachmentsId);
      if (response) {
        if (response.message) {
          successCallback();
        }
        if (response.error) {
        }
      }
    }
  };

  const handleUploadAttachmentComment = async fileData => {
    try {
      setUploadAttcahment(fileData);
      setFileToUpload(null);
      const MaxFileSize = 20 * 1024 * 1024; /* 20 mb limit */

      if (fileData && projectId && taskId && fileData.size <= MaxFileSize) {
        setUploadAttcahmentProgress(1);
        setIsCommentUploadLoader(true);
        // await scrollToBottom();

        const Response = await addAttachmentcomment(
          {
            attachment: fileData,
            comment: null,
            taskId: taskId,
            projectId: projectId.toString()
          },
          updateProgress,
          cancelToken,
          data => setCancelToken(data)
        );

        setIsCommentUploadLoader(false);
        setUploadAttcahmentProgress(false);

        if (Response) {
          if (Response.error) {
            // handleResponse(Response.error.message);

            showNotifications();
          } else {
            document.getElementById("taskCommentsAttachment").value = "";
            doRefreshComment();
            // var comment = { projectComments };
            // comment[0].projectComment.push(Response.data);
            // setNewComment("");
            // document.getElementById("commentInput").value = "";
            setFileToUpload(null);
            // projectcommentRefresh({
            //   roomname: `project_comment_${projectId}`
            // });
            // fetchComments();
          }
          setIsEditorCommentAddloader(false);
        }
      } else {
        // scrollToBottom();
        toggleAttachmentLimitExceedError();
      }
    } catch (E) {
      // console.log(E);

      showNotifications();
    }
  };
  const toggleAttachmentLimitExceedError = () => {
    setIsExceedAttachment(true);
    setTimeout(() => {
      setIsExceedAttachment(false);
    }, 3000);
    showNotifications(
      <IntlMessages id={"task-details.filesizeerror"} />,
      "error"
    );
  };
  const doClear_Toggle_Comment = Response => {
    setIsEditorCommentAddloader(false);
    if (
      Response.status.toLowerCase() === "ok" ||
      Response.status.toLowerCase() === "success"
    ) {
      setComment("");
      setCommentInput(false);
    } else {
      showNotifications(Response.message);
    }
  };

  // Submit Trix Value

  const doSubmitValueOnkeyPress = () => {
    if (comment.length > 0 && uploadedCount == uploadingCount) {
      handleAddComment(comment, doClear_Toggle_Comment, commentAttachmentsUrl);
    }
  };

  const handleDropAttachment = (event, files) => {
    setFileToUpload(files);
    handleUploadAttachmentComment(files);
  };

  const getDay = date => {
    if (date) {
      const todayDate = moment(new Date());
      const givenDate = moment(date);
      if (givenDate.diff(todayDate, "days") == 0) {
        return "";
      } else {
        return moment(date).format("ddd");
      }
    }
  };
  const getDateTime = date => {
    if (date) {
      const todayDate = moment(new Date());
      const givenDate = moment(date);
      if (givenDate.diff(todayDate, "days") == 0) {
        return moment(date).format("hh:mm a");
      } else {
        return moment(date).format("MMM DD");
      }
    }
  };

  const checkEmojiExist = (unicode, taskCommentId) => {
    try {
      let activityHistory = ActivityHistory;
      // let activityHistory = taskComments;
      var status = { status: false, id: 0 };
      activityHistory.map((comment, i) => {
        if (comment.id == taskCommentId && comment && "comment" in comment) {
          if (
            (comment.hasOwnProperty("comment") ||
              hasOwnProperty("commentBy")) &&
            comment.hasOwnProperty("commentEmoji")
          ) {
            var emojiArr = [...activityHistory[i].commentEmoji];

            emojiArr.map(data => {
              if (data.emojiname == unicode && data.user.id == currentUser.id) {
                status = { status: true, id: data.id };
              }
            });
            if (!status.status) {
              status = { status: false };
            }
          } else {
            status = { status: false };
          }
        }
      });
      if (!status.status) {
        status = { status: false };
      }
      return status;
    } catch (E) {
      // console.log(E);
    }
  };
  const handleRemoveCommentReactions = async (id, taskCommentId) => {
    if (id) {
      try {
        let payload = {
          comment_id: id.toString(),
          roomname: `${taskDetailsSocketRoomPrefix}${taskId}`
        };
        deletTaskcommentEmoji(payload, cb => {
          if (cb.status == "Ok") {
          } else {
            showNotifications();
            // setIsUploadCommentLoader(false);
          }
        });

        if (false) {
          const Response = await deleteReactions(id);
          if (Response) {
            if (Response.error) {
            }
            if (Response.message) {
              let activityHistory = ActivityHistory;
              // let activityHistory = taskComments;

              activityHistory.map((data, i) => {
                if (data.id == taskCommentId) {
                  if (
                    data.hasOwnProperty("comment") ||
                    hasOwnProperty("commentBy")
                  ) {
                    var emojiArr = [...activityHistory[i].commentEmoji];
                    // var result = emojiArr.filter(data => data.id !== id);
                    emojiArr &&
                      emojiArr.map((comment, i) => {
                        if (comment.id == id) {
                          emojiArr.splice(i, 1);
                        }
                      });
                    activityHistory[i].commentEmoji = emojiArr;
                  }
                }
              });
              setActivityHistory([...activityHistory]);
              // setTaskComments([...activityHistory]);
              doRefreshComment();
            }
          }
        }
      } catch (e) {}
    }
  };
  const addCommentReactions = async (unicode, taskCommentId) => {
    try {
      if (unicode && taskCommentId) {
        // addTaskcommentEmoji(payload)
        let payload = {
          unicode,
          task_comment_id: taskCommentId.toString(),
          roomname: `${taskDetailsSocketRoomPrefix}${taskId}`
        };

        addTaskcommentEmoji(payload, cb => {
          if (cb.status == "Ok") {
          } else {
            // setIsUploadCommentLoader(false);
            showNotifications();
          }
        });
        if (false) {
          const Response = await addReactions({
            comment: unicode,
            task_comment_id: taskCommentId ? taskCommentId : ""
          });
          if (Response) {
            if (Response.error) {
            }
            if (Response.data) {
              let activityHistory = ActivityHistory;
              // let activityHistory = taskComments;

              activityHistory.map((data, i) => {
                if (data.id == taskCommentId) {
                  if (
                    data.hasOwnProperty("comment") ||
                    hasOwnProperty("commentBy")
                  ) {
                    var emojiArr = activityHistory[i].commentEmoji
                      ? [...activityHistory[i].commentEmoji]
                      : [];
                    emojiArr.push(Response.data);
                    activityHistory[i].commentEmoji = emojiArr;
                  }
                }
              });
              setActivityHistory([...activityHistory]);
              // setTaskComments(activityHistory ? [...activityHistory] : []);
              doRefreshComment();
            }
          }
        }
      }
    } catch (e) {}
  };

  const getCodeForEmoji = unicode => {
    {
      const replacedCode = unicode.replace("U+", "0x");
      return String.fromCodePoint(replacedCode);
    }
  };

  const AssignPlaceHolder = ({ commenter, type }) => {
    try {
      if (commenter) {
        if (commenter.thumbnail || commenter.url) {
          return (
            <img
              alt=""
              title={commenter.name}
              id="userAssigned"
              src={commenter.thumbnail ? commenter.thumbnail : null}
              style={{
                height: 30,
                width: 30,
                border: "1px solid white",
                borderRadius: "50%"
              }}
              onClick={() => {
                props.open_profile_modal({
                  profile: commenter
                });
              }}
            ></img>
          );
        } else {
          return (
            <div
              title={commenter.name}
              // id="userAssigned"
              style={profileBackground(commenter?.name, 30, 30, 15)}
            >
              {commenter.name[0].toUpperCase()}
            </div>
          );
        }
      }
      return <IntlMessages id={"task-details.something_went_wrong"} /> + type;
    } catch (E) {
      return "error";
    }
  };

  let propsData = {
    ...props,
    isTrixDragEnter
  };

  return (
    <>
      {isFetchCommentsLoader ? (
        // <BusinessListShimmer />
        <Shimmer />
      ) : (
        <div
          style={{
            padding: "0px 0px 0px 0px",
            // borderBottom: "1px solid #EBEBEB",
            borderTop: "1px solid #EBEBEB"
          }}
        >
          <div>
            {ActivityHistory &&
              ActivityHistory.map((activity, index) => {
                if (activity && activity.hasOwnProperty("activity_type")) {
                  if (
                    activity.activity_type == "duedatechange" &&
                    !activity.past_value
                  ) {
                    return;
                  }
                  return (
                    <>
                      <div
                        key={index}
                        className="row TaskCommentCardCoactivity"
                        style={{
                          padding: "18px 0px 18px 0px",
                          opacity: isDragEnter || isExceedAttachment ? 0.5 : 1,
                          display: "flex",
                          alignItems: "flex-start",
                          // borderTop: "1px solid #EBEBEB",
                          borderBottom: "1px solid #EBEBEB",
                          marginLeft: 0,
                          marginRight: 0
                        }}
                      >
                        <div
                          className="col-lg-2 col-md-2 col-sm-2 col-xs-2 col-2"
                          style={{
                            paddingRight: "0px",
                            fontSize: 12,
                            color: "#808080",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "flex-end"
                          }}
                        >
                          <div
                            style={{
                              marginRight: 10
                            }}
                          >
                            <div
                              style={{
                                height: 30,
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center"
                              }}
                            >
                              <div>
                                <div> {getDay(activity.createdAt)}</div>
                                <div>{getDateTime(activity.createdAt)}</div>
                              </div>
                            </div>
                          </div>
                          <div>
                            <AssignPlaceHolder
                              commenter={activity?.user}
                              type="458"
                            />
                          </div>
                        </div>
                        <div
                          className="col-lg-10 col-md-10 col-sm-10 col-xs-10 col-10"
                          style={{
                            padding: "3px 15px 3px 15px"
                          }}
                        >
                          <div>
                            <div
                              style={{
                                width: "100%",
                                fontSize: 14,
                                position: "relative",
                                padding: 0,
                                display: "flex",
                                alignItems: "flex-start"
                              }}
                            >
                              {activity.activity_type != "duedatechange" && (
                                <span
                                  style={{
                                    fontWeight: "bold",
                                    paddingRight: 3,
                                    display: "flex",
                                    alignItems: "center"
                                  }}
                                >
                                  {activity.value != "completed" ? (
                                    <img
                                      alt=""
                                      src={CrossTaskDetailsActivityImage}
                                      style={{
                                        height: "15px",
                                        width: "14px",
                                        margin: "0px 5px 0px 0px"
                                      }}
                                    ></img>
                                  ) : (
                                    <img
                                      alt=""
                                      src={ListGridCheckImage}
                                      style={{
                                        height: "14px",
                                        width: "14px",
                                        margin: "0px 5px 0px 0px"
                                      }}
                                    ></img>
                                  )}
                                  {activity?.user?.name}
                                </span>
                              )}
                              {activity.activity_type == "duedatechange" &&
                                !activity.past_value && (
                                  <span
                                    style={{
                                      fontWeight: "bold"
                                    }}
                                  >
                                    {activity?.user?.name}
                                  </span>
                                )}

                              <TaskactivityListSingle
                                key={index}
                                lastActivity={
                                  activity?.length - 1 == index ? true : false
                                }
                                taskActivityArr={activity}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </>
                  );
                }
                if (activity && activity.hasOwnProperty("commentBy")) {
                  return (
                    <div
                      key={index}
                      className="row TaskCommentCardCoactivity"
                      // style={{ padding: "10px 0px 25px 0px" }}
                      style={{
                        padding: "18px 0px 18px 0px",
                        opacity: isDragEnter || isExceedAttachment ? 0.5 : 1,
                        borderBottom: "1px solid #EBEBEB",
                        marginLeft: 0,
                        marginRight: 0
                      }}
                    >
                      <div
                        className="col-lg-2 col-md-2 col-sm-2 col-xs-2 col-2"
                        style={{
                          paddingRight: "0px",
                          fontSize: 12,
                          color: "#808080",
                          display: "flex",
                          alignItems: "flex-start",
                          // justifyContent: "center",
                          justifyContent: "flex-end"
                        }}
                      >
                        <div style={{ marginRight: 10 }}>
                          <div
                            style={{
                              height: 30,
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center"
                            }}
                          >
                            <div>
                              <div> {getDay(activity.createdAt)}</div>
                              <div>{getDateTime(activity.createdAt)}</div>
                            </div>
                          </div>
                        </div>
                        <div>
                          <AssignPlaceHolder
                            type={"548" + activity?.commentBy}
                            commenter={activity?.commentBy}
                          />
                          {/* <img
                      src={download}
                      style={{
                        borderRadius: "50%",
                        height: 30,
                        width: 30
                      }}
                    ></img> */}
                        </div>
                      </div>
                      <div
                        className="col-lg-9 col-md-9 col-sm-9 col-xs-9 col-9"
                        style={{
                          padding: "0px 15px 0px 16px"
                        }}
                      >
                        <div>
                          <div
                            style={{
                              width: "100%",
                              fontWeight: "bold",
                              fontSize: 16,
                              position: "relative",
                              top: "-3px",
                              padding: 0,
                              height: 30,
                              display: "flex",
                              alignItems: "center"
                            }}
                          >
                            <span>{activity?.commentBy?.name}</span>
                            <div
                              style={{
                                paddingLeft: 5,
                                width: "35px"
                              }}
                            >
                              <ReactionsPopover
                                cId={activity.id}
                                emojiArr={emojiArr}
                                setEmojiArr={data => setEmojiArr(data)}
                                addReactions={(unicode, id) => {
                                  var check = checkEmojiExist(
                                    unicode,
                                    activity?.id ? activity.id : id
                                  );
                                  {
                                    check.status
                                      ? handleRemoveCommentReactions(
                                          check.id,
                                          comment.id
                                        )
                                      : addCommentReactions(unicode, id);
                                  }
                                }}
                              />
                            </div>
                          </div>

                          <TaskCommentCardWrapper>
                            {activity?.comment &&
                            activity?.comment !== "null" &&
                            activity?.comment !== null ? (
                              <div
                                style={{
                                  // backgroundColor: "#F8F8F8",
                                  // border: "1px solid #EBEBEB",
                                  borderRadius: "4px",
                                  padding: "0px",
                                  fontSize: 14,
                                  position: "relative",
                                  top: "-8px"
                                }}
                                dangerouslySetInnerHTML={{
                                  __html: activity?.comment
                                    ? activity.comment
                                    : ""
                                }}
                              ></div>
                            ) : (
                              ""
                            )}
                          </TaskCommentCardWrapper>

                          <CommentAttachment comment={activity} />
                        </div>
                        {activity.hasOwnProperty("commentEmoji") &&
                          activity.commentEmoji &&
                          activity.commentEmoji.length > 0 && (
                            <div
                              className=" "
                              style={{
                                padding: 10
                              }}
                            >
                              <ReactionsEmoji
                                cID={activity && activity.id}
                                userReactions={
                                  activity.commentEmoji && activity.commentEmoji
                                }
                                getCodeForEmoji={code => getCodeForEmoji(code)}
                                user={currentUser}
                                handleRemoveCommentReactions={id => {
                                  handleRemoveCommentReactions(id, activity.id);
                                }}
                                addCommentReactions={(unicode, id) => {
                                  addCommentReactions(unicode, activity.id);
                                }}
                              />
                            </div>
                          )}
                      </div>

                      <div
                        className="col-lg-1 col-md-1 col-sm-12 col-xs-12 col-12"
                        style={{
                          display: "flex",
                          alignItems: "self-start",
                          marginTop: "5px"
                        }}
                      >
                        {/* activity?.commentMeta &&
                          activity?.commentMeta !== null 
                            */}

                        {/* {(activity?.commentMeta !== null || */}
                        {currentUser?.id === activity.commentBy?.id && (
                          <div
                            className="hoverEffectpopover"
                            id={`CommentsPopover-${activity.id}-`}
                            onClick={() => {
                              toggleTaskCommentsMenu(
                                {
                                  id: activity.id
                                },
                                `CommentsPopover-${activity.id}-`,
                                activity?.commentMeta
                                  ? activity?.commentMeta
                                  : null,
                                activity.commentBy
                              );
                            }}
                          >
                            <img
                              alt=""
                              src={threeDot_Icon}
                              style={{
                                height: 6,
                                width: 22,
                                cursor: "pointer",
                                margin: "14px 0px 2px 8px"
                              }}
                            ></img>
                          </div>
                        )}
                      </div>
                    </div>
                  );
                }
              })}
          </div>
          <div></div>
          {(isDragEnter || isExceedAttachment || isCommentUploadLoader) && (
            <div
              className="row TaskCommentCardContainerMediaQuery"
              style={{ paddingBottom: "20px " }}
            >
              <div
                className="col-lg-2 col-md-2 col-sm-2 col-xs-2 col-2"
                style={{
                  paddingRight: "0px",
                  fontSize: 12,
                  color: "#808080",
                  display: "flex",
                  alignItems: "flex-start",
                  // justifyContent: "center",
                  justifyContent: "flex-end"
                }}
              >
                <div>
                  {currentUser?.userMeta ? (
                    <img
                      alt=""
                      src={
                        currentUser.userMeta
                          ? currentUser.userMeta.thumbnail
                          : ""
                      }
                      style={{
                        borderRadius: "50%",
                        height: 30,
                        width: 30,
                        border: "1px solid white"
                      }}
                      onClick={() => {
                        props.open_profile_modal({
                          profile: currentUser.user
                        });
                      }}
                    ></img>
                  ) : (
                    <div
                      title={currentUser.name}
                      // id="userAssigned"
                      style={profileBackground(currentUser?.name, 30, 30, 15)}
                    >
                      {currentUser.name[0].toUpperCase()}
                    </div>
                  )}
                </div>
              </div>
              <div
                className="col-lg-9 col-md-9 col-sm-12 col-xs-12 col-12 "
                style={{
                  padding: "0px 15px",
                  width: "100%"
                }}
              >
                <div style={{ width: "100%" }}>
                  <div
                    style={{
                      width: "100%",
                      fontWeight: "bold",
                      fontSize: 14,
                      position: "relative",
                      top: "-3px",
                      padding: 0,
                      height: 30,
                      display: "flex",
                      alignItems: "center"
                    }}
                  >
                    {currentUser.name}
                  </div>
                </div>
                <div>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      backgroundColor: "#F8F8F8",
                      border: "1px solid #EBEBEB",
                      borderRadius: "4px",
                      padding: "5px"
                    }}
                  >
                    <div style={{ width: "100%" }}>
                      {/* {isCommentUploadLoader && (
                          <AttachmentUploadProgress
                            PRIMARY_COLOR={PRIMARY_COLOR}
                            cancelRequest={cancelRequest}
                            isOpen={isCommentUploadLoader}
                            name={uploadAttcahment && uploadAttcahment.name}
                            attProgress={attcahmentProgress}
                          />
                        )} */}
                      {/* {isDragEnter && <DropFiles isOpen={isDragEnter} />}
                        {isExceedAttachment && (
                          <ExceedLimit
                            isOpen={isExceedAttachment}
                            errorMessage={"File size exceeds 20MB limit"}
                          />
                        )} */}
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-1 col-md-1 col-sm-12 col-xs-12 col-12 "></div>
            </div>
          )}
          <div
            className="row TaskCommentCardContainer"
            style={{
              opacity: isDragEnter || isExceedAttachment ? 0.5 : 1,
              marginTop: 30,
              marginLeft: 0,
              marginRight: 0,
              paddingBottom: 32,
              // borderBottom: "1px solid #EBEBEB",
              marginBottom: 30
            }}
          >
            {/* <div className="col-lg-1 col-md-1 col-sm-12 col-xs-12 col-12"></div> */}
            <div
              className="col-lg-2 col-md-2 col-sm-2 col-xs-12 col-12"
              style={{
                paddingRight: "0px",
                fontSize: 12,
                color: "#808080",
                display: "flex",
                alignItems: "flex-start",
                // justifyContent: "center",
                justifyContent: "flex-end"
              }}
            >
              <div>
                {currentUser?.userMeta ? (
                  <img
                    alt=""
                    src={
                      currentUser.userMeta ? currentUser.userMeta.thumbnail : ""
                    }
                    style={{
                      borderRadius: "50%",
                      height: 30,
                      width: 30,
                      border: "1px solid white"
                    }}
                    onClick={() => {
                      props.open_profile_modal({
                        profile: currentUser
                      });
                    }}
                  ></img>
                ) : (
                  <div
                    title={currentUser.name}
                    // id="userAssigned"
                    style={profileBackground(currentUser?.name, 30, 30, 15)}
                  >
                    {currentUser.name[0].toUpperCase()}
                  </div>
                )}
              </div>
            </div>
            <div
              className="col-lg-9 col-md-9 col-sm-12 col-xs-12 col-9"
              style={{
                display: "flex",
                alignItems: "flex-start",
                justifyContent: "flex-start",
                padding: "0px 0px 0px 16px",
                width: "100%"
              }}
            >
              <div style={{ width: "100%" }}>
                {/* <div
                    style={{
                      width: "100%",
                      fontWeight: "bold",
                      fontSize: 14,
                      position: "relative",
                      top: "-3px",
                      padding: 0,
                      height: 30,
                      display: "flex",
                      alignItems: "center"
                    }}
                  >
                    {currentUser.name}
                  </div> */}
                <div style={{ width: "100%" }}>
                  {commentInput ? (
                    <>
                      <div style={getDropBorderStyle()}>
                        {isTrixDragEnter || isDrag ? (
                          <div
                            style={{
                              position: "absolute",
                              top: "40%",
                              left: "40%"
                            }}
                          >
                            Drop your files here
                          </div>
                        ) : (
                          ""
                        )}

                        {isExceedAttachment && (
                          <div
                            style={{
                              position: "absolute",
                              top: "40%",
                              left: "30%",
                              color: "red"
                            }}
                          >
                            <IntlMessages id={"task-details.filesizeerror"} />
                          </div>
                        )}
                        <TrixEditorWrapper {...propsData}>
                          <div
                            style={{
                              opacity:
                                isTrixDragEnter || isExceedAttachment ? 0 : 1
                            }}
                          >
                            <TrixEditor
                              toggleAttachmentLimitExceedError={
                                toggleAttachmentLimitExceedError
                              }
                              commentAttachmentsUrl={commentAttachmentsUrl}
                              setCommentAttachmentsUrl={data => {
                                setCommentAttachmentsUrl(data);
                              }}
                              collaboratorsList={collaboratorListArr}
                              value={comment}
                              comment={comment}
                              onChange={setComment}
                              taskId={taskId}
                              projectId={projectId}
                              uploadAttachment={uploadAttachment}
                              deleteAttachment={doDeleteAttachmentComment}
                              decreseUploadingCountByOne={() =>
                                setUploadingCount(c => (c == 0 ? 0 : c - 1))
                              }
                              doSubmitValueOnkeyPress={doSubmitValueOnkeyPress}
                              reduxFileObj={reduxFileObj}
                              LanguageSwitcher={
                                props.LanguageSwitcher.language.languageId
                              }
                              // setAllFileDrop={setAllFileDrop}

                              // setIsDrag={setIsDrag}
                              // isFileDropAllowed
                              // isDrag={props?.fileDropActions?.isFileDropAllowed ? props?.fileDropActions?.isFileDropAllowed : false}
                              // fileName={props?.fileDropActions?.fileName ? props?.fileDropActions?.fileName : null}

                              // allFileDrop={props?.fileDropActions?.file ? props?.fileDropActions?.file : null}
                            />
                          </div>
                        </TrixEditorWrapper>
                      </div>
                      <div
                        style={{
                          margin: "10px 0px",
                          display: "flex"
                        }}
                      >
                        {isEditorCommentAddLoader ? (
                          <Loader height={25} width={20} />
                        ) : (
                          <>
                            <button
                              className="c-btn"
                              style={{
                                fontWeight: "100",
                                border: "1px solid #dedede",
                                textAlign: "center",
                                color: "#fff",
                                backgroundColor: PRIMARY_COLOR,
                                boxShadow: "none",
                                borderRadius: 19
                              }}
                              // disabled={value.length > -1 && value != "<p><br></p>" ? false : true}
                              disabled={
                                comment.length > 0 &&
                                uploadedCount == uploadingCount
                                  ? false
                                  : true
                              }
                              onClick={() => {
                                handleAddComment(
                                  comment,
                                  doClear_Toggle_Comment,
                                  commentAttachmentsUrl
                                );
                                // fetchMetaInfo()
                              }}
                            >
                              <IntlMessages
                                id={"task-details.add_comment_button"}
                              />
                            </button>
                          </>
                        )}

                        <button
                          className="btn btn"
                          style={{
                            boxShadow: "none",
                            marginLeft: 10,
                            border: `1px solid ${PRIMARY_COLOR}`,
                            color: PRIMARY_COLOR,
                            borderRadius: 17
                          }}
                          onClick={() => {
                            setComment("");
                            setCommentInput(!commentInput);
                          }}
                        >
                          <IntlMessages id={"task-details.cancel_button"} />
                        </button>
                      </div>
                      {/* <TaskCommentCardWrapper>
                          <CustomEditor
                            collaboratorsList={collaboratorListArr}
                            // type={"comment"}
                            value={comment}
                            placeholder={"Type your comment here..."}
                            onChangeValue={setComment}
                            handleSubmitData={() => {
                              setIsEditorCommentAddloader(true);
                              handleAddComment(comment, doClear_Toggle_Comment);
                            }}
                            toggleInput={() => {
                              setComment("");
                              setCommentInput(!commentInput);
                            }}
                            onConfirmText={"Add this comment"}
                            isLoaderButton={isEditorCommentAddLoader || false}
                          />
                        </TaskCommentCardWrapper> */}
                    </>
                  ) : (
                    <button
                      className="dialog-chip "
                      style={{
                        width: "100%",
                        maxWidth: "100%",
                        fontWeight: "100",
                        backgroundColor: "transparent",
                        border: "0px solid #EBEBEB",
                        textAlign: "left",
                        cursor: "text",
                        color: "grey",
                        borderRadius: 5,
                        padding: "3px 10px 10px 0px ",
                        marginTop: 0
                      }}
                      onClick={() => setCommentInput(true)}
                    >
                      <span
                        style={{
                          fontSize: "16px",
                          color: "#808080"
                        }}
                      >
                        {" "}
                        <IntlMessages
                          id={"task-details.addcomment_placeholder"}
                        />
                      </span>
                    </button>
                  )}
                </div>
              </div>
            </div>

            <div
              className="col-lg-1 col-md-1 col-sm-12 col-xs-12 col-12"
              style={{
                padding: "0px 0px",
                display: "flex",
                alignItems: commentInput ? "center" : "flex-end"
              }}
            >
              {/* <label
                  htmlFor="taskCommentsAttachment"
                  style={{
                    padding: "5px 0px 0px 0px",
                    display: "flex",
                    alignItems: "flex-start",
                    justifyContent: "center",
                    height: 45,
                    width: 45,
                    // backgroundColor: "#F8F8F8",
                    borderRadius: "50%",
                    // border: "1px solid #EBEBEB",
                    cursor: "pointer",
                    margin: 0
                  }}
                >
                  <img src={attachmentnew} height="20" width="20" />
                </label>
                <input
                  style={{ display: "none", cursor: "pointer" }}
                  type="file"
                  id="taskCommentsAttachment"
                  name="taskCommentsAttachment"
                  onChange={e => {
                    if (e.target.files.length > 0) {
                      setFileToUpload(e.target.files[0]);

                      handleDropAttachment(e, e.target.files[0]);
                    }
                  }}
                /> */}
            </div>
          </div>

          <TaskCommentPopover
            target={
              isTaskCommentsPopover.status ? isTaskCommentsPopover.target : ""
            }
            data={
              isTaskCommentsPopover.status ? isTaskCommentsPopover.data : ""
            }
            isOpen={isTaskCommentsPopover.status || false}
            toggle={toggleTaskCommentsMenu}
            handleDownload={() =>
              handleDocumentDownload(
                isTaskCommentsPopover.commentAttachments?.url,
                isTaskCommentsPopover.commentAttachments?.originalname
              )
            }
            isCurrentUser={isTaskCommentsPopover.isCurrentUser}
            isDownloadAttachemnt={
              isTaskCommentsPopover.status
                ? isTaskCommentsPopover.commentAttachments
                : false
            }
            deleteComment={id => openCustomAlert(true, "delete", "comment", id)}
          />
        </div>
      )}
    </>
  );
};

const mapStateToProps = state => {
  return {
    fileDropActions: state.fileDropActions,
    LanguageSwitcher: { ...state.LanguageSwitcher }
  };
};
export default connect(mapStateToProps, { open_profile_modal })(
  TaskCommentCard
);
