import React, { useState, useEffect } from "react";
import { Draggable } from "react-beautiful-dnd";
import { Popover, PopoverBody } from "reactstrap";
import NaturalDragAnimation from "./NaturalDragAnimation";
import classNames from "classnames";
import { profileBackground } from "helper/methods/getUserAvatarColor";
import TaskDialog from "components/scrumboard/TaskDialog";
import RoyTooltip from "components/common/RoyTooltip";
import { getCollabData, onChangeTaskAssign } from "helper/methods/QuickActions";
import Assignee from "components/assignee";
import moment from "moment";
import { joinRoom } from "services/socket";
import profileModelAction from "redux/profileModel/actions";
import { ReactComponent as RepeatSvgIcon } from "assets/images/recurrenceIcon.svg";

import ToastMessage from "components/notifications/ToastMessage";
import { useToasts } from "react-toast-notifications";
import ApiCalendar from "react-google-calendar-api";
import { addTask, archiveTask, deleteTask, updateTask } from "services/socket";
import { projectSocketRoomPrefix } from "constatnt";
import * as _ from "lodash";
import {
  commentnew,
  MoreOptionsDotIcons,
  CardTaskCalenderImage,
  ListGridCheckImage,
  ListGridCircleImage,
  assignnew,
  basecamp
} from "helper/constant";
import { allPriority, getPriorityPath, taskSocketRoomPrefix } from "constatnt";
import CustomAlert from "util/CustomAlert";
import { getColorCode } from "components/projects/utils/color";
import TaskMenu from "components/projects/Menus/TaskMenu";
import DatePickerNoInput from "components/DatePickers/DatePickerNoInput";
import AssigneeDropdown from "components/newTask/AssigneeDropdown";
import PriorityPopover from "components/priority/priorityPopover";
import { repeatTypeOptions } from "util/data/taskDetailsutilData";
import { connect } from "react-redux";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
const getItemStyle = (isDragging, draggableStyle) => ({
  userSelect: "none",
  ...draggableStyle
});
const { open_profile_modal, close_profile_modal } = profileModelAction;

const CardTask = props => {
  const { addToast } = useToasts();
  const [activeTaskDetail, setActiveTaskDetail] = useState(null);
  const [taskModel, setTaskModel] = useState(false);
  const [taskMenu, setTaskMenu] = useState(false);
  const [sectionId, setSectionId] = useState(null);
  const [sectionTitle, setSectionTitle] = useState(null);
  const [projectId, setProjectId] = useState(null);
  const [todoId, setTodoId] = useState(null);
  const {
    task,
    index,
    openTaskDetailsHandler,
    deleteTaskHandler,
    PRIMARY_COLOR,
    setIsEditTask,
    toggleEditTask,
    doArchiveTask,
    reqTaskId,
    updateIsCompleted,
    // doUpdateTaskPriority,
    // doUpdateTaskAssign,
    // doUpdateTaskScheduleDate,
    // isQuickActionTask,
    // setIsQuickActionTask,
    setAddCardFlag,
    setIsSectionScroll,
    isSectionScroll,
    isGoogleCalenderData,
    isEditTask
  } = props;
  const [activeTaskMenu, setActiveTaskMenu] = useState(null);
  const [isCustomAlert, setCustomAlert] = useState(false);
  const [sectionalertMessage, setSectionAlertMessage] = useState(
    "Task will be deleted!"
  );
  const [isQuickActionTask, setIsQuickActionTask] = useState({
    status: false,
    data: "",
    selectedData: "",
    action: "",
    target: ""
  });
  const { id } = useParams();

  useEffect(() => {
    if (task) {
      setSectionId(props.sectionId);
      setSectionTitle(props.sectionTitle);
      setProjectId(props.projectId);
      // setActiveTaskDetail(task);
      handleChangeProps();
      if (
        reqTaskId &&
        reqTaskId.status &&
        reqTaskId?.id &&
        task?.id == reqTaskId?.id
      ) {
        setTaskModel(true);
        setActiveTaskDetail(task);
      }
    }
  }, [task, reqTaskId]);

  useEffect(() => {
    if (isSectionScroll == true) {
      setIsSectionScroll(false);
      if (taskMenu) {
        toggleTaskMenu();
      }
    }
  }, [isSectionScroll]);

  const refreshTodoList = newTodo => {
    props.refreshTodoList(newTodo);
  };
  const handleChangeProps = async () => {
    if (activeTaskDetail) {
      if (task.id == activeTaskDetail.id) {
        setActiveTaskDetail(task);
      }
    }
  };

  const toggleTaskMenu = (target, taskData) => {
    setAddCardFlag(false);
    if (taskMenu) {
      setTaskMenu(!taskMenu);
      setActiveTaskMenu(null);
      setActiveTaskDetail(null);
    } else {
      setTaskMenu(!taskMenu);
      setActiveTaskMenu(target);
      setActiveTaskDetail(taskData);
    }
  };
  const openTaskDetails = task => {
    // if(!task.hasOwnProperty("tempId") ){
    if (_.isNumber(task.id)) {
      props.history.push(`/task-details/${task.id}`);
    }
    // }
    // setActiveTaskDetail(task);
    // setTodoId(task.id);
    // Join task socket room
    // joinRoom(`${taskSocketRoomPrefix}${task.id}`);
    // setTaskModel(true);
  };
  const doCloseQuickAction = () => {
    setIsQuickActionTask({
      status: false,
      data: "",
      target: "",
      selectedData: ""
    });
  };
  const getTodoStyle = id => {
    if (id == isQuickActionTask.taskId && isQuickActionTask.status) {
      return {
        width: 332,
        borderRadius: 5,
        boxShadow: "rgb(0 0 0 / 54%) 1px 4px 5px -2px",
        webkitBoxShadow: "rgb(0 0 0 / 54%) 1px 4px 5px -2px",
        mozBoxShadow: "rgb(0 0 0 / 54%) 1px 4px 5px -2px",
        border: "1px solid lightgray",
        transition: "box-shadow 0.3s ease-in-out",
        backgroundColor: "#F4F5F7"
      };
    }
    return {
      width: 332,
      border: "0px",
      borderRadius: 5,
      transition: "box-shadow 0.3s ease-in-out",
      backgroundColor: "#FFF"
    };
  };

  const doUpdateTaskPriority = async (updatedPriority, taskDetails) => {
    const handleResponse = response => {
      if (response && response?.status.toLowerCase() == "ok") {
        setIsQuickActionTask({
          status: false,
          data: "",
          selectedData: "",
          action: "",
          target: ""
        });
      }
    };
    if (updatedPriority) {
      let payload = {
        priority: updatedPriority,
        task_id: isQuickActionTask?.data.id.toString()
      };

      doUpdateTask(payload, handleResponse);
    }
  };
  const doUpdateTaskScheduleDate = async (updatedDueDate, taskDetails) => {
    const handleResponse = response => {
      if (response && response?.status.toLowerCase() == "ok") {
        setIsQuickActionTask({
          status: false,
          data: "",
          selectedData: "",
          action: "",
          target: ""
        });
      }
    };
    let payload = {
      scheduledAt: updatedDueDate,
      task_id: isQuickActionTask?.data.id.toString()
    };

    doUpdateTask(payload, handleResponse);
  };
  const doUpdateTaskAssign = async udpatedAssignee => {
    const handleResponse = response => {
      if (response && response?.status.toLowerCase() == "ok") {
        setIsQuickActionTask({
          status: false,
          data: "",
          selectedData: "",
          action: "",
          target: ""
        });
      }
    };

    let payload = {
      user_id: udpatedAssignee,
      task_id: isQuickActionTask?.data.id.toString()
    };

    doUpdateTask(payload, handleResponse);
  };

  const doUpdateTask = (payloadInput, handleResponse) => {
    if (payloadInput) {
      try {
        let payload = {
          ...payloadInput,
          project_id: props.projectId.toString(),
          section_id: props.sectionId ? props.sectionId.toString() : "",
          roomname: `${projectSocketRoomPrefix}${props.projectId}`
        };

        if (!payloadInput.reminderAt && isQuickActionTask.data.reminderAt) {
          payload = {
            ...payload,
            reminderAt: isQuickActionTask.data.reminderAt
          };
        }

        if (
          !payloadInput.hasOwnProperty("scheduledAt") &&
          isQuickActionTask.data.scheduledAt
        ) {
          payload = {
            ...payload,
            scheduledAt: isQuickActionTask.data.scheduledAt
          };
        }

        updateTask(payload, cb => {
          if (cb.status == "Ok") {
            if (handleResponse) {
              handleResponse({
                status: cb.status
              });
            }
            setIsEditTask({ status: false, data: "" });
            if (payload?.scheduledAt && payload?.scheduledAt != null) {
              SyncTasktoGoogle(payload);
            }
          } else {
            if (cb.status || cb.message) {
              props.handleResponse(cb.message);
            }
            /**
             * @TODO
             * Handle error callback from socket
             * sample error cb: { status: "Failed", message: "error message" };
             */

            props.showNotifications();
          }
        });
      } catch (e) {}
    }
  };

  const SyncTasktoGoogle = async taskData => {
    if (taskData) {
      return;
    }
    var eventFromNow = {
      summary: taskData?.title?.toString(),
      description: taskData?.description?.toString(),
      start: {
        dateTime: moment(new Date(taskData.scheduledAt)),
        timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone
      },
      end: {
        dateTime: moment(new Date(taskData.scheduledAt)),
        timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone
      }
    };
    if (isGoogleCalenderData == false) {
      return ApiCalendar.createEvent(eventFromNow).then(result => {
        if (result.error) {
          addToast(ToastMessage(result.error.message), {
            appearance: "error",
            autoDismiss: true
          });
        }
        addToast(ToastMessage("Sync to Google"), {
          appearance: "success",
          autoDismiss: true
        });
        return result.result.id;
      });
      // .catch(error => {
      //   addToast(ToastMessage("Something went wrong"), {
      //     appearance: "error",
      //     autoDismiss: true
      //   });
      // });
    } else {
      if (isEditTask && isEditTask?.data && isEditTask?.data?.calendar_id) {
        ApiCalendar.updateEvent(
          eventFromNow,
          isEditTask?.data?.calendar_id
        ).then(result => {
          if (result.error) {
            addToast(ToastMessage(result.error.message), {
              appearance: "error",
              autoDismiss: true
            });
          }
          addToast(ToastMessage("Event is Update"), {
            appearance: "success",
            autoDismiss: true
          });
          return true;
        });
        // .catch(error => {
        //   addToast(ToastMessage("Something went wrong"), {
        //     appearance: "error",
        //     autoDismiss: true
        //   });
        // });
      } else {
        return ApiCalendar.createEvent(eventFromNow).then(result => {
          if (result.error) {
            addToast(ToastMessage(result.error.message), {
              appearance: "error",
              autoDismiss: true
            });
          }
          addToast(ToastMessage("Sync to Google"), {
            appearance: "success",
            autoDismiss: true
          });
          return result.result.id;
        });
        // .catch(error => {
        //   addToast(ToastMessage("Something went wrong"), {
        //     appearance: "error",
        //     autoDismiss: true
        //   });
        // });
      }
    }
  };

  return (
    <>
      <Draggable
        key={task.id}
        draggableId={task.id}
        index={index}
        type="task"
        isDragDisabled={taskMenu}
      >
        {(provided, snapshot) => (
          <NaturalDragAnimation
            style={getItemStyle(
              snapshot.isDragging,
              provided.draggableProps.style
            )}
            snapshot={snapshot}
            {...props}
          >
            {style => (
              <div
                ref={provided.innerRef}
                {...provided.draggableProps}
                {...provided.dragHandleProps}
                style={style}
              >
                <div
                  className="board-task-card taskHover"
                  style={getTodoStyle(task.id)}
                >
                  <div
                    className="flex-x"
                    style={{
                      alignItems: "center",
                      paddingRight: 8,
                      minHeight: 29
                    }}
                  >
                    {!task.isCompleted ? (
                      <img
                        alt=""
                        onClick={() =>
                          updateIsCompleted(
                            sectionId,
                            task.id,
                            !task.isCompleted,
                            task
                          )
                        }
                        src={ListGridCircleImage}
                        style={{
                          height: 14,
                          width: 14,
                          marginRight: 5,
                          marginTop: 8,
                          marginLeft: 12
                        }}
                      ></img>
                    ) : (
                      <img
                        alt=""
                        onClick={() =>
                          updateIsCompleted(
                            sectionId,
                            task.id,
                            !task.isCompleted,
                            task
                          )
                        }
                        src={ListGridCheckImage}
                        style={{
                          height: 14,
                          width: 14,
                          marginRight: 5,
                          marginTop: 8,
                          marginLeft: 12
                        }}
                      ></img>
                    )}
                    {/* <i
                      onClick={() =>
                        updateIsCompleted(sectionId, task.id, !task.isCompleted)
                      }
                      style={{
                        marginLeft: 0,
                        border: task.isCompleted ? "" : "1px solid #808080",
                        borderRadius: "50%",
                        marginRight: 5,
                        height: 18,
                        width: 18,
                        marginRight: 5,
                        color: task.isCompleted ? "#00c584" : "white"
                      }}
                      id="subtaskstatus"
                      className={`fas fa-check-circle ${task.isCompleted &&
                        "fs-18"}`}
                    ></i> */}
                    <div
                      className="task-title"
                      style={{
                        width: "100%",
                        paddingTop: 6
                      }}
                      onClick={() => openTaskDetails(task)}
                    >
                      <div
                        title={task.title}
                        className="title"
                        style={{ width: "240px" }}
                      >
                        <span
                          style={{
                            color: "black",
                            fontSize: 14,
                            fontWeight: 600
                          }}
                          onClick={() => openTaskDetails(task)}
                        >
                          {" "}
                          {task.title}
                        </span>
                      </div>
                    </div>
                    <div
                      className="more_icon task-hover"
                      onClick={e => {
                        e.stopPropagation();
                        toggleTaskMenu(`taskmenu-${task.id}`, task);
                      }}
                    >
                      {_.isNumber(task.id) && (
                        <div
                          className="taskOptions menu-hover fs-14"
                          id={`taskmenu-${task.id}`}
                        >
                          <img
                            alt=""
                            // className="fas fa-ellipsis-h menu-hover"
                            style={{ padding: "5px" }}
                            src={MoreOptionsDotIcons}
                          />
                        </div>
                      )}

                      <div>
                        {/*     
                                    shadow for edit quick actions task
                                    box-shadow: 0px 3px 6px 1px rgb(0 0 0 / 54%);
    -webkit-box-shadow: 0px 4px 3px 0px rgb(0 0 0 / 54%);
    -moz-box-shadow: 0px 3px 6px 1px rgb(0 0 0 / */}
                        <div
                          onClick={() => openTaskDetails(task)}
                          id={`taskmenu-labels-${task.id}`}
                        />
                        <div
                          onClick={() => openTaskDetails(task)}
                          id={`taskmenu-priority-${task.id}`}
                        />

                        {isQuickActionTask &&
                          isQuickActionTask.status &&
                          isQuickActionTask.action == "assign" &&
                          isQuickActionTask.target ==
                            `taskmenu-assign-${task.id}` && (
                            <>
                              <AssigneeDropdown
                                isDisableDropDownIcon
                                TargetId="assing-"
                                setDropdownOpen={() => {
                                  doCloseQuickAction();
                                }}
                                dropdownOpen={
                                  isQuickActionTask && isQuickActionTask.status
                                }
                                currentLoggedIn={props.auth?.user?.id}
                                projectCollaborators={props.collaboratorList}
                                userAssigned={
                                  isQuickActionTask.selectedData
                                    ? isQuickActionTask.selectedData
                                    : ""
                                }
                                setUserAssigned={data => {
                                  onChangeTaskAssign(data, doUpdateTaskAssign);
                                  // onChangeTaskAssign(data);
                                }}
                                setTaskMenu={setTaskMenu}
                              />
                            </>
                          )}
                        {isQuickActionTask &&
                          isQuickActionTask.status &&
                          isQuickActionTask.action == "schedule" &&
                          isQuickActionTask.target ==
                            `taskmenu-schedule-${task.id}` && (
                            <div
                              style={{
                                position: "static"
                              }}
                              // onClick={() => openTaskDetails(task)}
                              onClick={e => e.stopPropagation()}
                            >
                              <DatePickerNoInput
                                // AllowAddPreviousDateTask={AllowAddPreviousDateTask}
                                dueDate={
                                  isQuickActionTask.selectedData
                                    ? isQuickActionTask.selectedData
                                    : ""
                                }
                                onUpdateDate={doUpdateTaskScheduleDate}
                                toggle={doCloseQuickAction}
                                isOpen={isQuickActionTask.status}
                                PRIMARY_COLOR={props.PRIMARY_COLOR}
                              />
                            </div>
                          )}
                      </div>
                      <TaskMenu
                        projectTask
                        isMenu={taskMenu}
                        task={task}
                        id={id}
                        toggleMenu={() => toggleTaskMenu()}
                        props={props}
                        target={activeTaskMenu}
                        activeTaskDetail={activeTaskDetail}
                        viewTaskDetails={() => {
                          openTaskDetails(task);
                          // setActiveTaskDetail({
                          //   ...task,
                          //   action: "taskactivity"
                          // });

                          setTaskMenu(false);
                          setActiveTaskMenu("");
                        }}
                        editLabels={() => {
                          setActiveTaskDetail(task);
                          toggleEditTask(activeTaskDetail);
                          setIsEditTask({
                            status: true,
                            data: activeTaskDetail,
                            action: "label"
                          });
                        }}
                        // editAssign={() => {
                        //   toggleEditTask(activeTaskDetail);
                        //   setIsEditTask({
                        //     status: true,
                        //     data: activeTaskDetail,
                        //     action: "taskassign"
                        //   });
                        // }}
                        // editDate={() => {
                        //   setActiveTaskDetail(task);
                        //   toggleEditTask(activeTaskDetail);
                        //   setIsEditTask({
                        //     status: true,
                        //     data: activeTaskDetail,
                        //     action: "schedule"
                        //   });
                        // }}
                        // editPriority={() => {
                        //   setActiveTaskDetail(task);
                        //   toggleEditTask(activeTaskDetail);
                        //   setIsEditTask({
                        //     status: true,
                        //     data: activeTaskDetail,
                        //     action: "priority"
                        //   });
                        // }}

                        editAssign={() => {
                          // taskmenu-assign-
                          if (activeTaskDetail && activeTaskDetail.id) {
                            setIsQuickActionTask({
                              status: true,
                              action: "assign",
                              target: `taskmenu-assign-${activeTaskDetail.id}`,
                              selectedData:
                                activeTaskDetail && activeTaskDetail.taskAssign
                                  ? {
                                      user: activeTaskDetail?.taskAssign
                                    }
                                  : "",
                              data: activeTaskDetail
                            });
                          }
                          // setIsEditTask({
                          //   status: true,
                          //   data: activeTaskDetail,
                          //   action: "taskassign"
                          // });
                        }}
                        editPriority={() => {
                          if (activeTaskDetail && activeTaskDetail.id) {
                            setIsQuickActionTask({
                              status: true,
                              action: "priority",
                              target: `taskmenu-priority-${activeTaskDetail.id}`,
                              selectedData:
                                activeTaskDetail && activeTaskDetail.priority,
                              data: activeTaskDetail
                            });
                          }
                          // setIsEditTask({
                          //   status: true,
                          //   data: activeTaskDetail,
                          //   action: "priority"
                          // });
                        }}
                        editDate={() => {
                          setIsQuickActionTask({
                            status: true,
                            taskId: activeTaskDetail.id,
                            action: "schedule",
                            target: `taskmenu-schedule-${activeTaskDetail.id}`,
                            selectedData:
                              activeTaskDetail && activeTaskDetail.scheduledAt,
                            data: activeTaskDetail
                          });
                        }}
                        doArchiveTask={() => doArchiveTask(task?.id)}
                        editTask={() => {
                          if (taskMenu) {
                            setTaskMenu(false);
                            toggleEditTask(task);
                            setIsEditTask({
                              status: true,
                              data: task
                            });
                            setTaskModel(!taskModel);
                          } else {
                            setIsEditTask({
                              status: false,
                              data: ""
                            });
                          }
                          // setTaskModel(!taskModel);
                          setTaskMenu(!taskMenu);
                          // setActiveTaskDetail(null);
                        }}
                        deleteTask={id => {
                          setTaskMenu(!taskMenu);
                          setIsEditTask({
                            status: false,
                            data: ""
                          });
                          setCustomAlert(true);
                        }}
                      />
                    </div>
                  </div>

                  <div
                    className="status-block"
                    onClick={() => openTaskDetails(task)}
                  >
                    {task?.labels?.length > 0 &&
                      task.labels.map((e, i) => {
                        return (
                          <div
                            style={{
                              paddingLeft: 4,
                              marginBottom: 5
                            }}
                          >
                            <div
                              key={i}
                              title={e.name}
                              className={classNames(
                                "task-status card-label-block",
                                e.color
                              )}
                              style={{
                                backgroundColor: getColorCode(e.color),
                                marginRight: 1,
                                padding: "0px 5px",
                                // display: "flex",
                                alignItems: "center"
                              }}
                            >
                              {e.name}
                            </div>
                          </div>
                        );
                      })}
                  </div>

                  <div
                    className="task-options"
                    style={{
                      paddingBottom: 8,
                      paddingLeft: 12,
                      paddingRight: 8
                    }}
                    onClick={() => openTaskDetails(task)}
                  >
                    <div
                      style={{
                        marginRight: 0,
                        width: "100%",
                        paddingLeft: 1,
                        display: "flex",
                        alignItems: "center"
                      }}
                    >
                      {task.scheduledAt && (
                        <>
                          <img
                            src={CardTaskCalenderImage}
                            style={{
                              height: "12px",
                              width: "12px",
                              marginRight: "4px",

                              textAlign: "center"
                            }}
                            alt=""
                            onClick={() => openTaskDetails(task)}
                          ></img>
                          {task.scheduledAt &&
                            task.hasOwnProperty("repeatTill") &&
                            task.repeatType !== repeatTypeOptions[0].value && (
                              <RepeatSvgIcon
                                style={{
                                  fill: "green",
                                  height: "15px",
                                  width: "15px"
                                }}
                                onClick={() => openTaskDetails(task)}
                              />
                            )}
                          <span
                            style={{
                              fontSize: 13,
                              marginLeft: 0,
                              margin: "0px 5px",
                              color: "#808080"
                            }}
                            onClick={() => openTaskDetails(task)}
                          >
                            {moment(new Date(task.scheduledAt)).format(
                              "DD MMM YYYY"
                            )}
                          </span>
                        </>
                      )}
                      {task?.taskAssign?.length > 0 && (
                        // <RoyTooltip
                        //   id={"taskTaskAssignName"}
                        //   title={task.taskAssign.name}
                        //   placement="bottom"
                        // >
                        <div
                          //  id={"taskTaskAssignName"}
                          style={{
                            display: "flex",
                            alignItems: "center"
                          }}
                        >
                          {task.taskAssign.map(ta => {
                            if (ta.hasOwnProperty("name")) {
                              return (
                                <>
                                  {ta.thumbnail ? (
                                    <img
                                      title={ta.name}
                                      id="userAssigned"
                                      src={ta.thumbnail ? ta.thumbnail : null}
                                      style={{
                                        height: 20,
                                        width: 20,
                                        border: "1px solid white",
                                        borderRadius: "50%",
                                        marginRight: "5px"
                                      }}
                                      onClick={e => {
                                        if (e.target == e.currentTarget) {
                                          e.stopPropagation();
                                          props.open_profile_modal({
                                            profile: ta
                                          });
                                        }
                                      }}
                                    ></img>
                                  ) : (
                                    <div
                                      title={ta.name}
                                      // id="userAssigned"
                                      style={profileBackground(
                                        ta?.name ? ta.name[0] : ta.email[0],
                                        20,
                                        20,
                                        12
                                      )}
                                      onClick={e => {
                                        if (e.target == e.currentTarget) {
                                          e.stopPropagation();
                                          props.open_profile_modal({
                                            profile: ta
                                          });
                                        }
                                      }}
                                    >
                                      <div
                                        onClick={e => {
                                          if (e.target == e.currentTarget) {
                                            e.stopPropagation();
                                            props.open_profile_modal({
                                              profile: ta
                                            });
                                          }
                                        }}
                                      >
                                        {" "}
                                        {ta.name
                                          ? ta.name[0].toUpperCase()
                                          : ta.email[0].toUpperCase()}
                                      </div>
                                    </div>
                                  )}
                                </>
                              );
                            }
                          })}
                        </div>
                        // </RoyTooltip>
                      )}
                    </div>

                    <div
                      className="icon-space"
                      onClick={() => openTaskDetailsHandler(task)}
                    >
                      {/* <i
                        title="You can watch this task"
                        className="fas fa-eye"
                        style={{border: "1px solid red"}}
                      ></i> */}
                    </div>
                    {task.priority !== "no_priority" && (
                      <img
                        src={getPriorityPath(task.priority)}
                        height="15"
                        width="15"
                        className=""
                        alt="Priority"
                      />
                    )}
                    <div className="icon-space">
                      <img
                        src={commentnew}
                        style={{
                          height: "13px",
                          width: "13px",
                          marginRight: "0px",
                          marginTop: "-5px",
                          textAlign: "center"
                        }}
                      ></img>
                    </div>
                    <div className="count-space" style={{ marginTop: "-3px" }}>
                      {task.commentCount ? task.commentCount : 0}
                    </div>
                    {/* {task.basecampTodoId != null ||
                    task.basecampTodoId != undefined ? (
                      <div className="count-space">
                        {" "}
                        <img
                          src={basecamp}
                          style={{
                            height: "13px",
                            width: "13px",
                            marginRight: "0px",
                            marginTop: "-5px",
                            textAlign: "center",
                            borderRadius: "5px"
                          }}
                        ></img>
                      </div>
                    ) : (
                      ""
                    )} */}
                    <div className="icon-space"></div>
                  </div>
                </div>
              </div>
            )}
          </NaturalDragAnimation>
        )}
      </Draggable>
      {activeTaskDetail && (
        <TaskDialog
          updateLabelToParent={props.updateLabelToParent}
          {...props}
          showTaskAssign={true}
          taskModel={taskModel}
          setTaskModel={() => {
            setTaskModel(!taskModel);
            setActiveTaskDetail(null);
          }}
          taskDetail={activeTaskDetail}
          projectId={projectId}
          sectionId={sectionId}
          sectionTitle={sectionTitle}
          todoId={todoId || activeTaskDetail.id}
          updateTodoList={newTodo => refreshTodoList(newTodo)}
          collaboratorList={props.collaboratorList}
          updateActiveTaskDetail={data => {
            setActiveTaskDetail(data);
          }}
          showNotifications={message => props.showNotifications(message)}
        />
      )}
      {isQuickActionTask &&
        isQuickActionTask.status &&
        isQuickActionTask.action == "priority" && (
          <PriorityPopover
            isOpen={isQuickActionTask.status}
            toggle={doCloseQuickAction}
            target={
              isQuickActionTask.status && isQuickActionTask.target
                ? isQuickActionTask.target
                : "priority-task-details"
            }
            selectedPriority={
              isQuickActionTask?.status && isQuickActionTask.selectedData
                ? isQuickActionTask.selectedData
                : "no_priority"
            }
            updatePriority={doUpdateTaskPriority}
            PRIMARY_COLOR={props.PRIMARY_COLOR}
          />
        )}
      <CustomAlert
        CustomMessage={task.title}
        action="delete"
        messageType="task"
        PRIMARY_COLOR={PRIMARY_COLOR}
        show={isCustomAlert}
        onConfirm={() => {
          deleteTaskHandler(task, projectId, task.calendar_id);
          setCustomAlert(false);
        }}
        onCancel={() => setCustomAlert(false)}
        alertMessage={sectionalertMessage}
      />
    </>
  );
};

export default connect(null, { open_profile_modal })(CardTask);
