import { io } from "socket.io-client";
import { REACT_APP_SOCKET_ENDPOINT } from "../helper/services/apiConstants";

function getAuth() {
  const auth = JSON.parse(localStorage.getItem("persist:root"));
  return auth;
}
const token = getAuth() ? JSON.parse(getAuth().auth).accessToken : null;

var socket;
export const projectInit = (
  addSectionCB,
  updateSectionCB,
  deleteSectionCB,
  addTaskCB,
  updateTaskCB,
  deleteTaskCB,
  moveTaskCB,
  reorderTaskCB,
  reorderSectionCB,
  addCollaboratorCB,
  moveSectionCB,
  archiveSectionCB,
  duplicateSectionCB,
  archiveTaskCB,
  tokens
) => {
  socket = io(REACT_APP_SOCKET_ENDPOINT, {
    path: process.env.REACT_APP_SOCKET_PATH,
    transports: ["websocket"],

    query: {
      authToken: tokens ? tokens : token
    }
  });

  socket.on("connect", () => {});

  socket.on("add section", data => {
    addSectionCB(data);
  });

  socket.on("update section", data => {
    updateSectionCB(data);
  });

  socket.on("add collaborator", data => {
    addCollaboratorCB(data);
  });

  socket.on("delete section", data => {
    deleteSectionCB(data);
  });

  socket.on("add task", data => {
    addTaskCB(data);
  });

  socket.on("update task", data => {
    updateTaskCB(data);
  });

  socket.on("delete task", data => {
    deleteTaskCB(data);
  });

  socket.on("move task", data => {
    moveTaskCB(data);
  });
  socket.on("reorder task", data => {
    reorderTaskCB(data);
  });
  socket.on("reorder section", data => {
    reorderSectionCB(data);
  });
  socket.on("move section", data => {
    moveSectionCB(data);
  });
  socket.on("archive section", data => {
    archiveSectionCB(data);
  });
  socket.on("archive task", data => {
    archiveTaskCB(data);
  });
  socket.on("duplicate section", data => {
    duplicateSectionCB(data);
  });
};
export const taskDetailsInit = (
  updateTaskCB,
  deleteTaskCB,
  moveTaskCB,
  reorderTaskCB,
  addCollaboratorCB,
  archiveTaskCB,
  updateCommentCB,
  addSubTaskCB,
  updateSubTaskCB,
  addChecklistCB,
  updateChecklistCB,
  deleteChecklistCB,
  subtaskAssignCB,
  addTaskCommentCB,
  deleteTaskCommentCB,
  updateTaskCommentCB,
  deleteTaskcommentEmojiCB,
  addTaskcommentEmojiCB,
  tokens
) => {
  socket = io(REACT_APP_SOCKET_ENDPOINT, {
    path: process.env.REACT_APP_SOCKET_PATH,
    transports: ["websocket"],

    query: {
      authToken: tokens ? tokens : token
    }
  });

  socket.on("connect", () => {});
  socket.on("add subtask", data => {
    addSubTaskCB(data);
  });
  socket.on("add collaborator", data => {
    addCollaboratorCB(data);
  });

  socket.on("update task", data => {
    updateTaskCB(data);
  });

  socket.on("delete task", data => {
    deleteTaskCB(data);
  });

  socket.on("move task", data => {
    moveTaskCB(data);
  });
  socket.on("reorder task", data => {
    reorderTaskCB(data);
  });
  socket.on("archive task", data => {
    archiveTaskCB(data);
  });
  socket.on("update comment", data => {
    updateCommentCB(data);
  });
  socket.on("task:add comment", data => {
    addTaskCommentCB(data);
  });
  socket.on("task:delete comment", data => {
    deleteTaskCommentCB(data);
  });
  socket.on("task:update comment", data => {
    updateTaskCommentCB(data);
  });

  socket.on("task:delete comment emoji", data => {
    deleteTaskcommentEmojiCB(data);
  });
  socket.on("task:add comment emoji", data => {
    addTaskcommentEmojiCB(data);
  });

  socket.on("update subtask", data => {
    updateSubTaskCB(data);
  });
  socket.on("subtask assign", data => {
    subtaskAssignCB(data);
  });
  socket.on("add checklist", data => {
    addChecklistCB(data);
  });

  socket.on("update checklist", data => {
    updateChecklistCB(data);
  });
  socket.on("delete checklist", data => {
    deleteChecklistCB(data);
  });
};
export const addTaskInit = tokens => {
  socket = io(REACT_APP_SOCKET_ENDPOINT, {
    path: process.env.REACT_APP_SOCKET_PATH,
    transports: ["websocket"],

    query: {
      authToken: tokens ? tokens : token
    }
  });
  socket.on("connect", () => {});
};
export const inboxInit = (
  addSectionCB,
  updateSectionCB,
  deleteSectionCB,
  addTaskCB,
  updateTaskCB,
  deleteTaskCB,
  tokens
) => {
  socket = io(REACT_APP_SOCKET_ENDPOINT, {
    path: process.env.REACT_APP_SOCKET_PATH,
    transports: ["websocket"],

    query: {
      authToken: tokens ? tokens : token
    }
  });

  socket.on("connect", () => {});

  socket.on("add section", data => {
    addSectionCB(data);
  });

  socket.on("update section", data => {
    updateSectionCB(data);
  });

  socket.on("delete section", data => {
    deleteSectionCB(data);
  });
  // socket.on("add task", data => {
  //   addTaskCB(data);
  // });

  socket.on("update task", data => {
    updateTaskCB(data);
  });
  socket.on("archive task", data => {
    updateTaskCB(data);
  });

  socket.on("delete task", data => {
    deleteTaskCB(data);
  });
};
export const businessInit = (updateBusinessCB, tokens) => {
  socket = io(REACT_APP_SOCKET_ENDPOINT, {
    path: process.env.REACT_APP_SOCKET_PATH,
    transports: ["websocket"],

    query: {
      authToken: tokens ? tokens : token
    }
  });

  socket.on("connect", () => {});

  socket.on("update business", data => {
    updateBusinessCB(data);
  });
};
export const notificationInit = (notificationsRefreshCB, tokens) => {
  socket = io(REACT_APP_SOCKET_ENDPOINT, {
    path: process.env.REACT_APP_SOCKET_PATH,
    transports: ["websocket"],

    query: {
      authToken: tokens ? tokens : token
    }
  });

  socket.on("connect", () => {});

  socket.on("notification refresh", data => {
    notificationsRefreshCB(data);
  });
};
export const businessDashboardInit = (fetchBusinessDashboardCB, tokens) => {
  socket = io(REACT_APP_SOCKET_ENDPOINT, {
    path: process.env.REACT_APP_SOCKET_PATH,
    transports: ["websocket"],

    query: {
      authToken: tokens ? tokens : token
    }
  });

  socket.on("connect", () => {});

  socket.on("business Dashboard", data => {
    fetchBusinessDashboardCB(data);
  });
};
export const taskInit = (
  addSubTaskCB,
  updateSubTaskCB,
  addChecklistCB,
  updateChecklistCB,
  deleteChecklistCB,
  updateCommentCB,
  subtaskAssignCB
) => {
  socket.on("add subtask", data => {
    addSubTaskCB(data);
  });

  socket.on("update subtask", data => {
    updateSubTaskCB(data);
  });
  socket.on("subtask assign", data => {
    subtaskAssignCB(data);
  });
  socket.on("add checklist", data => {
    addChecklistCB(data);
  });
  socket.on("update comment", data => {
    updateCommentCB(data);
  });

  socket.on("update checklist", data => {
    updateChecklistCB(data);
  });
  socket.on("delete checklist", data => {
    deleteChecklistCB(data);
  });
};
export const labelInit = (
  tokens,
  updateTaskCB,
  removeTaskLabelCB,
  addTaskCB,
  deleteTaskCB
) => {
  socket = io(REACT_APP_SOCKET_ENDPOINT, {
    path: process.env.REACT_APP_SOCKET_PATH,
    transports: ["websocket"],

    query: {
      authToken: tokens ? tokens : token
    }
  });

  socket.on("connect", () => {});
  // socket.on("label:update", data => {
  //   updateLabelCb(data);
  // });
  socket.on("update task label", data => {
    updateTaskCB(data);
  });

  socket.on("label refresh", data => {
    updateTaskCB(data);
  });

  socket.on("label refresh remove", data => {
    removeTaskLabelCB(data);
  });

  socket.on("add task label", data => {
    addTaskCB(data);
  });

  socket.on("delete task label", data => {
    deleteTaskCB(data);
  });
};
export const priorityInit = (
  tokens,
  updateTaskCB,
  removeTaskCB,
  deleteTaskCB
) => {
  socket = io(REACT_APP_SOCKET_ENDPOINT, {
    path: process.env.REACT_APP_SOCKET_PATH,
    transports: ["websocket"],

    query: {
      authToken: tokens ? tokens : token
    }
  });

  socket.on("connect", () => {});
  socket.on("priority refresh", data => {
    updateTaskCB(data);
  });
  socket.on("priority refresh remove", data => {
    removeTaskCB(data);
  });
  socket.on("update task priority", data => {
    updateTaskCB(data);
  });

  socket.on("add task priority", data => {
    // addTaskCB(data);
  });

  socket.on("delete task priority", data => {
    deleteTaskCB(data);
  });
};
export const todayDateInit = (
  tokens,
  addTaskDateFilterCB,
  updateTaskDateFilterCB,
  refreshTodayDateFilterCB
) => {
  socket = io(REACT_APP_SOCKET_ENDPOINT, {
    path: process.env.REACT_APP_SOCKET_PATH,
    transports: ["websocket"],

    query: {
      authToken: tokens ? tokens : token
    }
  });

  socket.on("connect", () => {});
  socket.on("add task", data => {
    addTaskDateFilterCB(data);
  });
  socket.on("update task", data => {
    updateTaskDateFilterCB(data);
  });
  socket.on("today refresh", data => {
    refreshTodayDateFilterCB(data);
  });
};
export const upcommingInit = (
  tokens,
  addTaskDateFilterCB,
  updateTaskDateFilterCB,
  refreshUpcommingDateFilterCB
) => {
  socket = io(REACT_APP_SOCKET_ENDPOINT, {
    path: process.env.REACT_APP_SOCKET_PATH,
    transports: ["websocket"],

    query: {
      authToken: tokens ? tokens : token
    }
  });

  socket.on("connect", () => {});
  socket.on("update task", data => {
    updateTaskDateFilterCB(data);
  });
  socket.on("add task", data => {
    addTaskDateFilterCB(data);
  });
  socket.on("upcoming refresh", data => {
    refreshUpcommingDateFilterCB(data);
  });
};
export const todoInit = tokens => {
  socket = io(REACT_APP_SOCKET_ENDPOINT, {
    path: process.env.REACT_APP_SOCKET_PATH,
    transports: ["websocket"],

    query: {
      authToken: tokens ? tokens : token
    }
  });

  socket.on("connect", () => {});
};
export const projectCommentInit = (
  addCommentCB,
  deleteCommentCB,
  refreshCommentCB,
  tokens
) => {
  socket = io(REACT_APP_SOCKET_ENDPOINT, {
    path: process.env.REACT_APP_SOCKET_PATH,
    transports: ["websocket"],

    query: {
      authToken: tokens ? tokens : token
    }
  });
  socket.on("project:comment refresh", data => {
    refreshCommentCB(data);
  });
  socket.on("project:add comment", data => {
    addCommentCB(data);
  });
  socket.on("project:delete comment", data => {
    deleteCommentCB(data);
  });
  socket.on("connect", () => {});
};
// Join room
export const joinRoom = room => {
  socket && socket.emit("join room", room);
};

// Leave room
export const leaveRoom = room => {
  socket && socket.emit("leave room", room);
  // socket && socket.disconnect();
};

// disconnect
export const disconnect = room => {
  socket && socket.disconnect();
};

// Add section
export const addSection = (payload, cb) => {
  socket && socket.emit("add section", JSON.stringify(payload), cb);
};

// Update section
export const updateSection = (payload, cb) => {
  socket && socket.emit("update section", JSON.stringify(payload), cb);
};

// move section
export const moveSection = (payload, cb) => {
  socket && socket.emit("move section", JSON.stringify(payload), cb);
};
// archive section
export const archiveSection = (payload, cb) => {
  socket && socket.emit("archive section", JSON.stringify(payload), cb);
};
// duplicate section
export const duplicateSection = (payload, cb) => {
  socket && socket.emit("duplicate section", JSON.stringify(payload), cb);
};

//reorder section
export const reorderSection = (payload, cb) => {
  socket && socket.emit("reorder section", JSON.stringify(payload), cb);
};

export const deleteSection = (payload, cb) => {
  socket && socket.emit("delete section", JSON.stringify(payload), cb);
};

// Add task
export const addTask = (payload, cb) => {
  socket && socket.emit("add task", JSON.stringify(payload), cb);
};

// Update task
export const updateTask = (payload, cb) => {
  socket && socket.emit("update task", JSON.stringify(payload), cb);
};
// Update task
export const archiveTask = (payload, cb) => {
  socket && socket.emit("archive task", JSON.stringify(payload), cb);
};

export const deleteTask = (payload, cb) => {
  socket && socket.emit("delete task", JSON.stringify(payload), cb);
};

// Move task
export const moveTask = (payload, cb) => {
  socket && socket.emit("move task", JSON.stringify(payload), cb);
};

// Reorder task
export const reorderTask = (payload, cb) => {
  socket && socket.emit("reorder task", JSON.stringify(payload), cb);
};

// Add subtask
export const addSubTask = (payload, cb) => {
  socket && socket.emit("add subtask", JSON.stringify(payload), cb);
};

// Update subtask
export const updateSubTask = (payload, cb) => {
  socket && socket.emit("update subtask", JSON.stringify(payload), cb);
};
//  subtask assign
export const subtaskAssign = (payload, cb) => {
  socket && socket.emit("subtask assign", JSON.stringify(payload), cb);
};

// Add checklist
export const addChecklist = (payload, cb) => {
  socket && socket.emit("add checklist", JSON.stringify(payload), cb);
};
// Update checklist
export const updateChecklist = (payload, cb) => {
  socket && socket.emit("update checklist", JSON.stringify(payload), cb);
};
export const deleteChecklist = (payload, cb) => {
  socket && socket.emit("delete checklist", JSON.stringify(payload), cb);
};
export const updateComment = (payload, cb) => {
  socket && socket.emit("update comment", JSON.stringify(payload), cb);
};
export const updateBusiness = (payload, cb) => {
  socket && socket.emit("update business", JSON.stringify(payload), cb);
};
export const addCollaborator = (payload, cb) => {
  socket && socket.emit("add collaborator", JSON.stringify(payload), cb);
};
export const fetchBusinessDashboard = (payload, cb) => {
  socket && socket.emit("business dashboard", JSON.stringify(payload), cb);
};
export const updateLabel = (payload, cb) => {
  socket && socket.emit("update task label", JSON.stringify(payload), cb);
};
export const updatPriority = (payload, cb) => {
  socket && socket.emit("update task priority", JSON.stringify(payload), cb);
};
export const updateTaskDateFilter = (payload, cb) => {
  socket && socket.emit("update task", JSON.stringify(payload), cb);
};
export const updateTaskUpcomming = (payload, cb) => {
  socket && socket.emit("update task", JSON.stringify(payload), cb);
};
export const notificationsRefresh = (payload, cb) => {
  socket && socket.emit("notification refresh", JSON.stringify(payload), cb);
};
export const projectcommentRefresh = (payload, cb) => {
  socket && socket.emit("project:comment refresh", JSON.stringify(payload), cb);
};
export const addProjectcomment = (payload, cb) => {
  socket && socket.emit("project:add comment", JSON.stringify(payload), cb);
};
export const deleteProjectcomment = (payload, cb) => {
  socket && socket.emit("project:delete comment", JSON.stringify(payload), cb);
};
export const addTaskcomment = (payload, cb) => {
  socket && socket.emit("task:add comment", JSON.stringify(payload), cb);
};
export const deleteTaskcomment = (payload, cb) => {
  socket && socket.emit("task:delete comment", JSON.stringify(payload), cb);
};
export const updateTaskcomment = (payload, cb) => {
  socket && socket.emit("task:update comment", JSON.stringify(payload), cb);
};
export const deletTaskcommentEmoji = (payload, cb) => {
  socket &&
    socket.emit("task:delete comment emoji", JSON.stringify(payload), cb);
};
export const addTaskcommentEmoji = (payload, cb) => {
  socket && socket.emit("task:add comment emoji", JSON.stringify(payload), cb);
};
