/** @format */

import React, { useEffect, useState, useRef } from "react";
import { connect } from "react-redux";
import { getToday, getOverdue } from "helper/services/todayServices";
import CustomNotification from "util/CustomNotification";
import NewTaskModal from "components/newTask/NewTaskModal";
import { data_not_found } from "helper/constant";
import {
  isNewDateFuture,
  isNewDateToday,
  isNewDatePast,
  isNewDateOverDue,
  doPopFromArray,
  doUpdate_Or_PushToArray,
  doUpdateTodoArr,
  doPushToArray
} from "helper/methods/dateFilterFunctions";
import TodayListView from "components/TodoListview/TodayListView";
import OverdueListView from "components/TodoListview/OverdueListView";
import moment from "moment";

import Loader from "components/loader/Loader";

import Shimmer from "components/shimmer/Shimmer";
import { useToasts } from "react-toast-notifications";
import ApiCalendar from "react-google-calendar-api";
import ToastMessage from "components/notifications/ToastMessage";
import { todaySocketRoomPrefix } from "constatnt/index";
import {
  todayDateInit,
  disconnect,
  joinRoom,
  leaveRoom,
  addTask,
  updateTask
} from "services/socket";
import alertActions from "redux/globalAlert/actions";
import { toggleArchive } from "helper/services/businessServices";
import authActions from "redux/auth/actions";
import { checkIsArchivedMessage } from "helper/methods/workspacestatus";
import IntlMessages from "util/intlMessages";
const { open_workpsace_alert, open_project_alert, close_alert } = alertActions;
const { update_business_archive } = authActions;

const Today = props => {
  const { addToast } = useToasts();
  const [todayTodo, setTodayTodo] = useState([]);
  const [overdueTasks, setOverdueTasks] = useState([]);

  const [isLoader, SetIsLoader] = useState(false);
  const [isArchiveLoader, setIsArchiveloader] = useState(false);
  const [toastMessage, setToastMessage] = useState(null);
  const [overDuePages, setOverDuePages] = useState({ current: 1, total: 1 });
  const [isViewMoreLoader, setIsViewMoreLoader] = useState(false);
  const [toast, setToast] = useState(false);
  const [isQuickActionTask, setIsQuickActionTask] = useState({
    status: false,
    data: "",
    selectedData: "",
    action: "",
    target: ""
  });
  const [isSearch, setIsSearch] = useState(false);
  const [searchInput, setSearchInput] = useState(null);
  const [isErrorAlert, setIsErrorAlert] = useState({
    status: false,
    messageType: ""
  });
  const [isnewTask, setIsNewTask] = useState(false);
  const [isEditTask, setIsEditTask] = useState({
    status: false,
    data: "",
    action: ""
  });
  const [SearchTodoQueryResult, setSearchTodoQueryResult] = useState(null);
  const [
    SearchOverdueTodoQueryResult,
    setSearchOverdueTodoQueryResult
  ] = useState(null);
  const todayRef = React.useRef(todayTodo);
  const todayOverdueRef = React.useRef(overdueTasks);
  const TodayListViewRef = useRef(null);

  const scrollToTop = () => {
    TodayListViewRef.current.scrollIntoView({
      block: "center",
      inline: "nearest"
    });
  };

  React.useEffect(() => {
    todayRef.current = todayTodo;
    todayOverdueRef.current = overdueTasks;
  });
  const handleResponse = message => {
    try {
      if (message == "NOT_WORKSPACE_COLLABORATOR") {
        if (props?.auth?.user?.isNewUserBusinessAdded) {
          // status:action.alert.status, message:action.alert.message,action:'open_businesslist'

          props.open_workpsace_alert({
            status: true,
            message: "workspace"
          });
        }
      }
      if (message == "USER_NOT_PROJECT_COLABORATOR") {
        props.open_project_alert({
          status: true,
          message: "project"
        });
      }
    } catch (e) {}
  };
  useEffect(() => {
    scrollToTop();
    if (props.auth.user.businessName !== null) {
      fetchTodayTask();
      fetchOverdueTask();
    }

    todayDateInit(
      props.auth.accessToken,
      addTaskDateFilterCB,
      updateTaskDateFilterCB,
      refreshTodayDateFilterCB
    );
    handleSocketRoom();
    return () => {
      setIsViewMoreLoader(false);
      setOverDuePages({ current: 1, total: 1 });
      leaveRoom(`${todaySocketRoomPrefix}${props.auth.user.businessId}`);
      setIsErrorAlert({ status: false, messageType: "" });
      disconnect();
    };
  }, [props.auth.user.businessId]);
  const updateTaskDateFilterCB = async data => {
    if (data && data.id && data.isDeleted === false) {
      refreshTodoList(data);
      refreshOverDueTodoList(data);
    }
    if (
      data &&
      data.id &&
      (data.isDeleted === true || data.isArchived == true)
    ) {
      let todoArr = todayRef.current;
      let todoOverdueArr = todayOverdueRef.current;
      let updatedTodoArr = removeTodoFromList(todoArr, data);
      let updatedTodoOverdueArr = removeTodoFromList(todoOverdueArr, data);
      updatedTodoArr && setTodayTodo([...updatedTodoArr]);
      updatedTodoOverdueArr && setOverdueTasks([...updatedTodoOverdueArr]);
    }
  };
  const addTaskDateFilterCB = async newTodo => {
    if (newTodo && isNewDateToday(new Date(), newTodo?.scheduledAt)) {
      let userSelectedDate = moment().format("DD-MM-YYYY");
      let newTodoScheduleAt =
        newTodo.scheduledAt && moment(newTodo.scheduledAt).format("DD-MM-YYYY");
      let todayTaskArr = todayRef.current;
      if (newTodo) {
        let status = false;
        let newTodoToarr = [newTodo];
        todayTaskArr &&
          todayTaskArr.map((task, i) => {
            if (task.id == newTodo.id) {
              todayTaskArr[i] = { ...todayTaskArr[i], ...newTodoToarr[0] };
              status = true;
            }
          });
        if (!status) {
          if (
            userSelectedDate &&
            newTodo &&
            newTodoScheduleAt < userSelectedDate
          ) {
            refreshOverDueTodoList(newTodo);
          } else {
            setTodayTodo([...todayTaskArr, newTodo]);
          }
        } else {
          setTodayTodo([...todayTaskArr]);
        }
      }
    }
    if (newTodo && isNewDatePast(new Date(), newTodo?.scheduledAt)) {
      handleUpdateTodo(newTodo);
    }
  };
  const findTodo = (todo, todoArr) => {
    if (todoArr && todoArr?.length > 0 && todo && todo?.id) {
      const todoArrFilter =
        todoArr && todoArr.filter((find, i) => find.id == todo.id);

      if (todoArrFilter && todoArrFilter.length > 0) {
        return todoArrFilter;
      }

      return false;
    }

    return null;
  };
  const removeOverDueTaskFromList = taskId => {
    const overDueArr = todayOverdueRef.current;
    const TodoArr = todayOverdueRef.current;

    overDueArr.map((data, i) => {
      if (data.id == taskId) {
        TodoArr.splice(i, 1);
      }
    });
    setOverdueTasks([...TodoArr]);
  };
  const refreshTodayDateFilterCB = async newTodo => {
    let userSelectedDate = moment().format("DD-MM-YYYY");
    let newTodoScheduleAt =
      newTodo.scheduledAt && moment(newTodo.scheduledAt).format("DD-MM-YYYY");
    let todayTaskArr = todayRef.current;
    if (newTodo) {
      let status = false;
      let newTodoToarr = [newTodo];
      todayTaskArr &&
        todayTaskArr.map((task, i) => {
          if (task.id == newTodo.id) {
            todayTaskArr[i] = { ...todayTaskArr[i], ...newTodoToarr[0] };
            status = true;
          }
        });
      if (!status) {
        if (
          userSelectedDate &&
          newTodo &&
          newTodoScheduleAt < userSelectedDate
        ) {
          refreshOverDueTodoList(newTodo);
        } else {
          setTodayTodo([...todayTaskArr, newTodo]);
        }
      } else {
        setTodayTodo([...todayTaskArr]);
      }
    }
  };
  const handleSocketRoom = () => {
    // Join room
    joinRoom(`${todaySocketRoomPrefix}${props.auth.user.businessId}`);
  };
  const handleSearch = query => {
    /* fetching the input and checking if exist in customerlistarray */
    let filteredTodos,
      overdueFilteredTodos = [];
    if (!query) {
      // setSearchContact(false);
      setSearchTodoQueryResult(null);
    } else {
      filteredTodos = todayTodo.filter(tasks => {
        return tasks.title.toLowerCase().indexOf(query.toLowerCase()) !== -1;
      });
      overdueFilteredTodos =
        overdueTasks &&
        overdueTasks.filter(tasks => {
          return tasks.title.toLowerCase().indexOf(query.toLowerCase()) !== -1;
        });

      // setSearchContact(true);
      setSearchTodoQueryResult(filteredTodos);
      setSearchOverdueTodoQueryResult(overdueFilteredTodos);
    }
  };
  /* 
  - add task if its today than push to today
  - changing date which is previous than push to overdue
  - from today updating date to future than pop 
  - updating date to today from overdue than pop from overdue and push to today
  - updating date from overdue pop it
  
  
  any cb comes first check where does it exist and then perform all above functions
  if  its for add task then check where does it exist and 
  check date scheduled at if its in today than update to today 
  else if its in overdue than pop it
  
  
  */

  const fetchTodayTask = async isLoading => {
    try {
      SetIsLoader(isLoading);

      const Response = await getToday(
        props.auth.accessToken || props.history.location.state.token,
        moment(new Date()).format("YYYY-MM-DD")
      );
      if (Response) {
        if (Response.error) {
          handleResponse(Response.error.message);
        }
        if (Response.data) {
          setTodayTodo([...Response.data]);
        }
        SetIsLoader(false);
      }
    } catch (e) {
      SetIsLoader(false);
    }
  };
  const handleViewMoreOverdueTasks = () => {
    if (overDuePages.current < overDuePages.total) {
      fetchOverdueTask(true);
    }
  };
  const fetchOverdueTask = async (viewMore = false, isLoader) => {
    try {
      SetIsLoader(!viewMore && true);
      viewMore && setIsViewMoreLoader(true);
      const Response = await getOverdue(
        props.auth.accessToken || props.history.location.state.token,
        moment(new Date()).format("YYYY-MM-DD"),
        5,
        viewMore ? overDuePages.current + 1 : overDuePages.current
      );
      if (Response) {
        SetIsLoader(false);
        setIsViewMoreLoader(false);
        if (Response.error) {
          handleResponse(Response.error.message);
        }
        if (Response.data) {
          setOverDuePages({
            current: parseInt(Response.currentPage),
            total: parseInt(Response.totalPages)
          });

          if (viewMore) {
            setOverdueTasks([...overdueTasks, ...Response.data]);
          } else {
            setOverdueTasks([...Response.data]);
          }
        }
      }
    } catch (e) {
      SetIsLoader(false);
      setIsViewMoreLoader(false);
    }
  };

  const showNotifications = (message, status) => {
    addToast(ToastMessage(message), {
      appearance: status ? status : "error",
      autoDismiss: true
    });
  };

  const SyncTasktoGoogle = async taskData => {
    if (taskData) console.log(moment(new Date(taskData.scheduledAt)));
    var eventFromNow = {
      summary: taskData.title.toString(),
      description: taskData.description.toString(),
      start: {
        dateTime: moment(new Date(taskData.scheduledAt)),
        timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone
      },
      end: {
        dateTime: moment(new Date(taskData.scheduledAt)),
        timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone
      }
    };

    ApiCalendar.createEvent(eventFromNow)
      .then(result => {
        if (result.error) {
          addToast(ToastMessage(result.error.message), {
            appearance: "error",
            autoDismiss: true
          });
        }
        addToast(ToastMessage("Sync to Google"), {
          appearance: "success",
          autoDismiss: true
        });
        return true;
      })
      .catch(error => {
        addToast(
          ToastMessage(
            <IntlMessages id={"task-details.Something_went_wrong"} />
          ),
          {
            appearance: "error",
            autoDismiss: true
          }
        );
      });
  };

  const updateTaskHandler = async (payloadInput, syncGoogle) => {
    try {
      let manupletadObj = {
        title: payloadInput?.title,
        description: payloadInput?.description,
        label_ids: payloadInput?.label_ids,
        reminderAt: payloadInput?.reminderAt,
        priority: payloadInput?.priority,
        user_id: payloadInput?.user_id,
        scheduledAt: payloadInput?.scheduledAt,
        section_id: payloadInput?.section_id,
        project_id: payloadInput?.project_id,
        isDeleted: payloadInput.isDeleted,
        isCompleted: payloadInput.isCompleted,
        task_id: payloadInput?.task_id.toString(),
        isArchived: payloadInput?.isArchived
      };
      if (payloadInput.hasOwnProperty("isDeleted")) {
        manupletadObj = {
          ...manupletadObj,
          task_id: payloadInput?.task_id.toString()
        };
      }
      if (manupletadObj && manupletadObj.project_id) {
        let payload = {
          ...manupletadObj,
          roomname: `${todaySocketRoomPrefix}${props.auth.user.businessId}`
        };
        if (
          manupletadObj?.title &&
          isEditTask &&
          isEditTask.status &&
          isEditTask?.data?.id
        ) {
          payload = {
            ...payload,
            task_id: isEditTask?.data.id.toString()
          };
        }

        updateTask(payload, cb => {
          if (cb.status == "Ok") {
            if (
              payloadInput.hasOwnProperty("isDeleted") &&
              payloadInput.isDeleted == true
            ) {
              let manuplatedArray = todayTodo.filter((data, key) => {
                return data.id != payload.task_id;
              });
              setTodayTodo(manuplatedArray);
            }
            setIsEditTask({ status: false, data: "" });
            if (
              payload?.scheduledAt &&
              payload?.scheduledAt != null &&
              syncGoogle
            ) {
              SyncTasktoGoogle(payload);
            }
          } else {
            if (cb.status || cb.message) {
              handleResponse(cb.message);
            }
            /**
             * @TODO
             * Handle error callback from socket
             * sample error cb: { status: "Failed", message: "error message" };
             */

            showNotifications();
          }
        });
      }
    } catch (e) {}
  };
  const doCloseQuickAction = () => {
    setIsQuickActionTask({
      status: false,
      data: "",
      target: "",
      selectedData: ""
    });
  };
  const doUpdateTaskPriority = async (updatedPriority, taskDetails) => {
    const handleResponse = response => {
      if (response && response?.status.toLowerCase() == "ok") {
        setIsQuickActionTask({
          status: false,
          data: "",
          selectedData: "",
          action: "",
          target: ""
        });
      }
    };
    if (updatedPriority) {
      let payload = {
        priority: updatedPriority,
        task_id: isQuickActionTask?.data.id.toString(),
        project_id: isQuickActionTask.data.project.id.toString(),
        section_id: isQuickActionTask.data.section.id
          ? isQuickActionTask.data.section.id.toString()
          : ""
      };

      doUpdateQuickActionTask(payload, handleResponse);
    }
  };
  const doUpdateTaskScheduleDate = async (updatedDueDate, taskDetails) => {
    const handleResponse = response => {
      if (response && response?.status.toLowerCase() == "ok") {
        setIsQuickActionTask({
          status: false,
          data: "",
          selectedData: "",
          action: "",
          target: ""
        });
      }
    };
    let payload = {
      scheduledAt: new Date(updatedDueDate),
      task_id: isQuickActionTask?.data.id.toString(),
      project_id: isQuickActionTask.data.project.id.toString(),
      section_id: isQuickActionTask.data.section.id
        ? isQuickActionTask.data.section.id.toString()
        : ""
    };

    doUpdateQuickActionTask(payload, handleResponse);
  };
  const doUpdateTaskAssign = async udpatedAssignee => {
    const handleResponse = response => {
      if (response && response?.status.toLowerCase() == "ok") {
        setIsQuickActionTask({
          status: false,
          data: "",
          selectedData: "",
          action: "",
          target: ""
        });
      }
    };

    let payload = {
      user_id: udpatedAssignee,
      task_id: isQuickActionTask?.data.id.toString(),
      project_id: isQuickActionTask.data.project.id.toString(),
      section_id: isQuickActionTask.data.section.id
        ? isQuickActionTask.data.section.id.toString()
        : ""
    };

    doUpdateQuickActionTask(payload, handleResponse);
  };
  const doUpdateQuickActionTask = (payloadInput, handleResponse) => {
    if (payloadInput) {
      try {
        let payload = {
          ...payloadInput,
          roomname: `${todaySocketRoomPrefix}${props.auth.user.businessId}`
        };

        if (!payloadInput.reminderAt && isQuickActionTask.data.reminderAt) {
          payload = {
            ...payload,
            reminderAt: isQuickActionTask.data.reminderAt
          };
        }

        if (
          !payloadInput.hasOwnProperty("scheduledAt") &&
          isQuickActionTask.data.scheduledAt
        ) {
          payload = {
            ...payload,
            scheduledAt: isQuickActionTask.data.scheduledAt
          };
        }

        updateTask(payload, cb => {
          if (cb.status == "Ok") {
            if (handleResponse) {
              handleResponse({
                status: cb.status
              });
            }
            setIsEditTask({ status: false, data: "" });
            // if (
            //   payload?.scheduledAt &&
            //   payload?.scheduledAt != null &&
            //   syncGoogle
            // ) {
            //   SyncTasktoGoogle(payload);
            // }
            // setTaskinput("");
          } else {
            if (cb.status || cb.message) {
              handleResponse(cb.message);
            }
            /**
             * @TODO
             * Handle error callback from socket
             * sample error cb: { status: "Failed", message: "error message" };
             */

            showNotifications();
          }
        });
      } catch (e) {}
    }
  };
  const addTaskHandler = async (payloadInput, syncGoogle) => {
    try {
      let manupletadObj = {
        tempId: payloadInput?.tempId,
        title: payloadInput?.title,
        description: payloadInput?.description,
        label_ids: payloadInput?.label_ids,
        reminderAt: payloadInput?.reminderAt,
        priority: payloadInput?.priority,
        user_id: payloadInput?.user_id,
        scheduledAt: payloadInput?.scheduledAt,
        section_id: payloadInput?.section_id,
        project_id: payloadInput?.project_id
      };
      if (manupletadObj && manupletadObj.title) {
        let payload = {
          ...manupletadObj,
          roomname: `${todaySocketRoomPrefix}${props.auth.user.businessId}`
        };

        addTask(payload, cb => {
          if (cb.status == "Ok") {
            if (
              manupletadObj?.scheduledAt &&
              manupletadObj?.scheduledAt != null &&
              syncGoogle
            ) {
              SyncTasktoGoogle(payload);
            }
          } else {
            if (cb.status || cb.message) {
              handleResponse(cb.message);
            }
            /**
             * @TODO
             * Handle error callback from socket
             * sample error cb: { status: "Failed", message: "error message" };
             */

            showNotifications();
          }
        });
      }
    } catch (e) {}
  };

  const updateIsCompleted = async (projectId, todoId, isCompleted, data) => {
    try {
      let payload = {
        project_id: projectId.toString(),
        isCompleted: isCompleted,
        isDeleted: false,
        // scheduledAt: new Date(),
        task_id: todoId.toString()
      };

      if (data.scheduledAt) {
        payload = { ...payload, scheduledAt: data.scheduledAt };
      }
      if (data.reminderAt) {
        payload = { ...payload, reminderAt: data.reminderAt };
      }

      updateTaskHandler(payload);
    } catch (e) {}
  };
  const handleUpdateTodo = newTodo => {
    let todoArr = todayRef.current;
    let todoOverdueArr = todayOverdueRef.current;
    const isTodoExistInToday = findTodo(newTodo, todoArr);
    const isTodoExistInOverdue = findTodo(newTodo, todoOverdueArr);

    if (isTodoExistInToday && isTodoExistInOverdue) {
      // check if its future date than pop from both array
      if (isTodoExistInToday[0].scheduledAt && newTodo.scheduledAt) {
        if (isNewDateFuture(new Date(), newTodo?.scheduledAt)) {
          let data = doPopFromArray(newTodo, todoArr);
          setTodayTodo([...data]);

          let overDueData = doPopFromArray(newTodo, todoOverdueArr);
          setOverdueTasks([...overDueData]);
        }

        // if its past than than update to overdue and pop from today
        if (isNewDateOverDue(new Date(), newTodo?.scheduledAt)) {
          let data = doPopFromArray(newTodo, todoArr);
          setTodayTodo([...data]);

          let updatedOverDueData = doUpdate_Or_PushToArray(
            newTodo,
            todoOverdueArr
          );
          setOverdueTasks([...updatedOverDueData]);
        }

        // if its today than update to today and pop from overdue
        if (isNewDateToday(new Date(), newTodo?.scheduledAt)) {
          let updateData = doUpdateTodoArr(newTodo, todoArr);
          setTodayTodo([...updateData]);
          let overDueData = doPopFromArray(newTodo, todoOverdueArr);
          setOverdueTasks([...overDueData]);
        }
      }
    }
    if (isTodoExistInToday && isTodoExistInToday.length == 1) {
      if ((isTodoExistInToday[0]?.scheduledAt, newTodo?.scheduledAt)) {
        if (isNewDateFuture(new Date(), newTodo?.scheduledAt)) {
          let data = doPopFromArray(newTodo, todoArr);
          setTodayTodo([...data]);
        }
        if (isNewDateToday(new Date(), newTodo?.scheduledAt)) {
          let updateData = doUpdateTodoArr(newTodo, todoArr);
          setTodayTodo([...updateData]);
        }
        if (isNewDatePast(new Date(), newTodo.scheduledAt)) {
          // pop and push to overdue
          // pop from today
          let updatedDataAfterPop = doPopFromArray(newTodo, todoArr);
          setTodayTodo([...updatedDataAfterPop]);
          // push to overdue
          let updatedOverDueData = doUpdate_Or_PushToArray(
            newTodo,
            todoOverdueArr
          );
          setOverdueTasks([...updatedOverDueData]);
        }
      }
    }
    if (isTodoExistInOverdue) {
      // check data is past date than update
      if (isNewDateOverDue(new Date(), newTodo?.scheduledAt)) {
        let updatedOverDueData = doUpdate_Or_PushToArray(
          newTodo,
          todoOverdueArr
        );
        setOverdueTasks([...updatedOverDueData]);
      }
      // if its today  than push
      // if its today than update to today and pop from overdue
      if (isNewDateToday(new Date(), newTodo?.scheduledAt)) {
        let updateData = doUpdate_Or_PushToArray(newTodo, todoArr);
        setTodayTodo([...updateData]);
        let overDueData = doPopFromArray(newTodo, todoOverdueArr);
        setOverdueTasks([...overDueData]);
      }
      //  else pop it
      // if ((isTodoExistInOverdue[0]?.scheduledAt, newTodo?.scheduledAt)) {
      if (isNewDateFuture(new Date(), newTodo?.scheduledAt)) {
        let data = doPopFromArray(newTodo, todoOverdueArr);
        setOverdueTasks([...data]);
      }
    }
    if (!isTodoExistInToday && !isTodoExistInOverdue) {
      if (isNewDateFuture(new Date(), newTodo?.scheduledAt)) {
      }
      if (isNewDateToday(new Date(), newTodo.scheduledAt)) {
        let data = doPushToArray(newTodo, todoArr);
        setTodayTodo([...data]);
      }
      if (isNewDatePast(new Date(), newTodo.scheduledAt)) {
        let data = doPushToArray(newTodo, todoOverdueArr);
        setOverdueTasks([...data]);
      }
    }
  };
  const refreshTodoList = newTodo => {
    if (newTodo) {
      handleUpdateTodo(newTodo);
    }
  };
  const refreshOverDueTodoList = newTodo => {
    if (newTodo) {
      handleUpdateTodo(newTodo);
    }
  };
  const removeTodoFromList = (existingList, removeTodoData) => {
    if (existingList && existingList?.length && removeTodoData?.id) {
      return existingList.filter(data => data.id !== removeTodoData?.id);
    }
    return false;
  };
  const addNewTodoList = newTodo => {
    let todoArr = todayRef.current;
    let status = false;
    let newTodoToarr = [newTodo];
    todoArr.map((item, index) => {
      if (item.id == newTodoToarr[0].id) {
        status = true;
        todoArr[index] = { ...todoArr[index], ...newTodoToarr[0] };
      }
    });
    if (!status) {
      todoArr.push(newTodo);
    }
    setTodayTodo([...todoArr]);
  };
  const handleArchiveWorkpsace = async () => {
    if (props?.auth?.user?.businessId) {
      try {
        setIsArchiveloader(true);
        const response = await toggleArchive(props?.auth?.user?.businessId);
        if (response) {
          if (response.message) {
            setIsArchiveloader(false);

            if (!checkIsArchivedMessage(response.message)) {
              props.update_business_archive(false);
            }
          }
          if (response.error) {
            handleResponse(response.error.message);
            setIsArchiveloader(false);
            showNotifications();
          }
        }
      } catch (e) {
        setIsArchiveloader(false);
        showNotifications();
      }
    }
  };
  const checkArchive = () => {
    if (props?.auth?.user?.isArchived) {
      return (
        <>
          <div className="workspace-archived">
            <div className="workspace-archived-border flex-x fs-14">
              This workspace has been&nbsp;archived.&nbsp;{""}
              {props?.auth?.user?.businessId &&
                props?.auth?.user?.isBusinessOwner && (
                  <>
                    Click here to&nbsp;
                    <div
                      style={{
                        textDecoration: "underline",
                        cursor: "pointer",
                        display: "flex"
                      }}
                      onClick={handleArchiveWorkpsace}
                    >
                      <b>Unarchive</b>{" "}
                      {isArchiveLoader && <Loader height="18px" width="15px" />}
                    </div>{" "}
                  </>
                )}
            </div>
          </div>
        </>
      );
    }
  };
  return (
    <>
      <div className="row" style={{ backgroundColor: "white" }}>
        <div className="col-lg-1 col-md-1 col-sm-0 col-xs-0"></div>
        <div className="col-lg-10 col-md-10 col-sm-12 col-xs-12">
          {" "}
          {checkArchive()}
        </div>
        <div className="col-lg-1 col-md-1 col-sm-0 col-xs-0"></div>
      </div>
      <div className="content-panel">
        <div className="row" style={{ marginBottom: 50 }}>
          <div
            className="col-lg-1 col-md-1 col-sm-0 col-xs-0"
            style={{ padding: 0 }}
          >
            <div
              className="today"
              style={{
                backgroundColor: "white",
                height: 60,
                borderBottom: "1px solid #EBEBEB"
              }}
            ></div>
          </div>
          <div
            className="col-lg-10 col-md-10 col-sm-12 col-xs-12"
            style={{ padding: 0 }}
          >
            <div className="roe-card-style">
              <div
                ref={TodayListViewRef}
                className="roe-card-header"
                style={{
                  height: "60px",
                  display: "flex",
                  alignItems: "center",
                  backgroundColor: "white",
                  borderBottom: "1px solid #EBEBEB",
                  padding: "8px 0px"
                }}
              >
                <div
                  className="file-manager-toolbar flex-x"
                  style={{ width: "100%" }}
                >
                  <div
                    className="flex-1"
                    style={{ display: "flex", alignItems: "center" }}
                  >
                    <span
                      className="fs-15 demi-bold-text"
                      style={{ fontWeight: 600, color: "black" }}
                    >
                      {<IntlMessages id={"today.today"} />}
                      <small
                        className="fs-13"
                        style={{
                          marginLeft: 6,
                          color: "#B8B8B8",
                          fontSize: 13
                        }}
                      >
                        {moment(new Date()).format("ddd DD MMM")}
                      </small>
                    </span>
                  </div>
                  <div>
                    {/* {isSearch && ( */}
                    {/* <div className="searchStyle pos-relative">
                  <LableWrapper>
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          height: 35,
                          backgroundColor: " #F5F5F5",
                          borderTopLeftRadius: 5,
                          borderBottomLeftRadius: 5
                        }}
                      >
                        <img
                          src={SearchHeaderPriorityLableImage}
                          style={{
                            height: 16,
                            width: 22,
                            marginLeft: 3
                          }}
                        ></img>
                      </div>
                      <span
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          setSearchInput(null);
                          setIsSearch(!isSearch);
                        }}
                        className="search-contant "
                      >
                        <Input
                          autoFocus
                          value={searchInput}
                          placeholder="Search"
                          className="react-form-search-input"
                          onChange={e => handleSearch(e.target.value)}
                        />
                        
                      </span>
                    </div>
                  </LableWrapper>
                </div> */}
                    {/* )} */}
                    {/* {!isSearch && (
                  <div className="flex center">
                    <div className="mr-1">
                      <span
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          setSearchInput(null);
                          setIsSearch(!isSearch);
                        }}
                        className="search-contant "
                      >
                        <i className="fas fa-search text-muted"></i>
                      </span>
                    </div>
                  </div>
                )} */}
                  </div>
                </div>
              </div>
            </div>

            {isLoader ? (
              <div className="roe-card-style mtb-15 todo-board">
                <Shimmer />
              </div>
            ) : (
              <>
                {overdueTasks?.length || todayTodo?.length ? (
                  <>
                    <OverdueListView
                      doCloseQuickAction={doCloseQuickAction}
                      doUpdateTaskPriority={doUpdateTaskPriority}
                      doUpdateTaskScheduleDate={doUpdateTaskScheduleDate}
                      doUpdateTaskAssign={doUpdateTaskAssign}
                      isQuickActionTask={isQuickActionTask}
                      setIsQuickActionTask={setIsQuickActionTask}
                      history={{ ...props.history }}
                      taskType={"date_filter"}
                      updateTaskHandler={updateTaskHandler}
                      isEditTask={isEditTask}
                      removeTaskFromList={removeOverDueTaskFromList}
                      setIsEditTask={setIsEditTask}
                      addTaskHandler={addTaskHandler}
                      handleViewMoreOverdueTasks={handleViewMoreOverdueTasks}
                      isViewMoreLoader={isViewMoreLoader}
                      overDuePages={overDuePages}
                      token={props.auth.accessToken}
                      businessId={props.auth.user.businessId}
                      PRIMARY_COLOR={props.topbarTheme.buttonColor}
                      updateIsCompleted={(
                        projectId,
                        todoId,
                        isCompleted,
                        data
                      ) =>
                        updateIsCompleted(projectId, todoId, isCompleted, data)
                      }
                      refreshTodoList={data => refreshTodoList(data)}
                      todoList={overdueTasks}
                      collaboratorList={[]}
                      dummyData={false}
                      showNotifications={showNotifications}
                      // for common component for today and priority to check if data is available or not by checking isDeleted==false
                      for={"Today"}
                      auth={{ ...props.auth }}
                    />
                    <TodayListView
                      doCloseQuickAction={doCloseQuickAction}
                      doUpdateTaskPriority={doUpdateTaskPriority}
                      doUpdateTaskScheduleDate={doUpdateTaskScheduleDate}
                      doUpdateTaskAssign={doUpdateTaskAssign}
                      isQuickActionTask={isQuickActionTask}
                      setIsQuickActionTask={setIsQuickActionTask}
                      updateTaskHandler={updateTaskHandler}
                      isEditTask={isEditTask}
                      setIsEditTask={setIsEditTask}
                      addTaskHandler={addTaskHandler}
                      isnewTask={isnewTask}
                      setIsNewTask={setIsNewTask}
                      token={props.auth.accessToken}
                      businessId={props.auth.user.businessId}
                      PRIMARY_COLOR={props.topbarTheme.buttonColor}
                      updateIsCompleted={(
                        projectId,
                        todoId,
                        isCompleted,
                        data
                      ) =>
                        updateIsCompleted(projectId, todoId, isCompleted, data)
                      }
                      isLabelToday={overdueTasks?.length}
                      refreshTodoList={data => refreshTodoList(data)}
                      todoList={todayTodo}
                      collaboratorList={[]}
                      dummyData={false}
                      showNotifications={showNotifications}
                      // for common component for today and priority to check if data is available or not by checking isDeleted==false
                      for={"Today"}
                      history={{ ...props.history }}
                      auth={{ ...props.auth }}
                    />
                  </>
                ) : (
                  <div
                    style={{
                      // height: "80vh",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      margin: "50px auto"
                    }}
                  >
                    <div style={{}}>
                      <div
                        className="text-muted"
                        style={{
                          fontSize: 14,
                          display: "flex",
                          justifyContent: "center"
                        }}
                      >
                        <img src={data_not_found} height="250" width="250" />
                        <br />
                      </div>
                      <div
                        className=""
                        style={{
                          fontSize: 14,
                          display: "flex",
                          justifyContent: "center"
                        }}
                      >
                        <span
                          style={{
                            fontWeight: "600",
                            fontSize: 16,
                            color: "black",
                            paddingTop: 20
                          }}
                        >
                          <IntlMessages
                            id={"today.get_a_clear_view_of_the_day_ahead"}
                          />
                        </span>
                      </div>
                      <div
                        className="text-muted"
                        style={{
                          fontSize: 14,
                          display: "flex",
                          justifyContent: "center"
                        }}
                      >
                        <span style={{ fontSize: 13, fontWeight: "500" }}>
                          <>
                            <IntlMessages
                              id={
                                "today.all_your_tasks_that_are_due_today_will_show_up_here"
                              }
                            />
                          </>
                        </span>
                      </div>
                      <div
                        className="text-muted"
                        style={{
                          paddingTop: 20,
                          fontSize: 14,
                          display: "flex",
                          justifyContent: "center"
                        }}
                      >
                        <button
                          type="submit"
                          className="btn btn-lg"
                          style={{
                            backgroundColor: props.topbarTheme.buttonColor,
                            border: "none",
                            color: "#FFF"
                          }}
                          onClick={() => setIsNewTask(!isnewTask)}
                        >
                          <IntlMessages id={"today.addtask"} />
                        </button>
                        {/* <button
                  className="btn button"
                  onClick={() => setIsNewTask(!isnewTask)}
                >
                  Add Task
                </button> */}
                      </div>
                    </div>
                  </div>
                )}
              </>
            )}
            <CustomNotification
              show={toast}
              message={toastMessage}
              closeNotification={() => setToast(false)}
            />
            <NewTaskModal
              setmodal={() => setIsNewTask(!isnewTask)}
              modal={isnewTask}
              showNotifications={showNotifications}
              refreshTodoList={() => {
                fetchTodayTask(false);
              }}
              defaultTodayDate
            />
          </div>
          <div
            className="col-lg-1 col-md-1 col-sm-0 col-xs-0"
            style={{ padding: 0 }}
          >
            <div
              style={{
                backgroundColor: "white",
                height: 60,
                borderBottom: "1px solid #EBEBEB"
              }}
            ></div>
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = state => {
  return {
    ...state.themeChanger,
    ...state,
    topbarTheme: { ...state.themeChanger.topbarTheme },
    language: { ...state.LanguageSwitcher.language }
  };
};

export default connect(mapStateToProps, {
  open_workpsace_alert,
  open_project_alert,
  close_alert,
  update_business_archive
})(Today);
