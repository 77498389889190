import Layout from "layouts/DashboardLayout.jsx";
import {
  LockScreen,
  Login,
  Register,
  Error400,
  ForgotPassword,
  ResetPassword
} from "./../views/pages/index";
import Landing from "../views/pages/landing/index";

import AuthLayout from "../routes/AuthLayout";
import EmailVerificationSent from "views/pages/authentication/EmailVerificationSent";
import BrowserNotSupported from "views/Not_suported/BrowserNotSupported";
import ShareLink from "views/pages/ShareLink";
import PrivacyPolicy from "views/pages/authentication/privacy-policy/PrivacyPolicy";
import TermsOfService from "views/pages/authentication/terms-of-use/TermsOfUse";

const indexRoutes = [
  { path: "/login", component: AuthLayout },
  { path: "/reset-password", component: ResetPassword },
  { path: "/app", component: Landing },
  { path: "/register", component: AuthLayout },
  { path: "/thank-you", component: EmailVerificationSent },
  { path: "/email-verification/:token", component: AuthLayout },
  { path: "/public-sharablelink", component: ShareLink },
  { path: "/browser-not-supported", component: BrowserNotSupported },
  { path: "/forgotPassword", component: ForgotPassword },
  { path: "/privacy-policy/", component: PrivacyPolicy },
  { path: "/terms-of-use/", component: TermsOfService },
  { path: "/", component: Layout }
];

export default indexRoutes;
