import React, { useMemo } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { Link, withRouter } from "react-router-dom";
import "../privacy-policy/PrivacyPolicy.style.css";
import { getWebsiteDetails } from "helper/services/authServices";

const TermsOfService = props => {
  useMemo(() => getWebsiteDetails(), []);
  return (
    <>
      <div style={{ height: "100vh", overflowY: "scroll" }}>
        <nav
          className="navbar navbar-expand-md navbar-dark fixed-top inner-page "
          style={{ backgroundColor: "#fff", padding: "15px" }}
        >
          <div className="container">
            <a className="navbar-brand logo" href="javascript:void(0);">
              <h1
                style={{
                  fontWeight: "700",
                  color: props?.auth?.webDetails?.appThemeColor
                }}
                className="appThemeColor"
              >
                Terms of Use
              </h1>
            </a>
          </div>
        </nav>

        <main>
          <section id="steps-to-cancle">
            <div className="container">
              <div class="entry-content">
                <p>
                  These Terms of Service (“Terms”) apply when you access or use
                  our website,
                  <a href={`https://${props?.auth?.webDetails?.website}`}>
                    {props?.auth?.webDetails?.website}
                  </a>
                  (“Website”), and the services provided through the Website and
                  App (collectively, “Services”). The Service is provided to you
                  by Workplate. (“Company,” “we,” “us” and “our”). These Terms
                  explain the terms and conditions that will govern your use of
                  the Services.
                </p>
                <p>
                  By accessing or using our Services, you indicate that you
                  agree to be bound by these Terms. If you do not wish to be
                  bound by these Terms, please do not use the Services.
                </p>

                <p>
                  <strong>
                    NOTICE REGARDING DISPUTE RESOLUTION:  These Terms contain
                    provisions that govern how claims between you and the
                    Company are resolved (see Section 12 below), including an
                    agreement to arbitrate disputes, which will, subject to
                    limited exceptions, require you to submit claims you have
                    against us to binding arbitration, unless you opt-out of
                    arbitration within 30 days of the date you first agree to
                    these Terms in accordance with Section 12(E).  Unless you
                    opt-out of arbitration: (a) you will only be permitted to
                    pursue claims against us on an individual basis, not as part
                    of any class or representative action or proceeding, and (b)
                    you will only be permitted to seek relief (including
                    monetary, injunctive, and declaratory relief) on an
                    individual basis.
                  </strong>
                </p>

                <h3>Eligibility</h3>

                <p>
                  When you use the Services, you represent that: (a) you are at
                  least 16 years of age (or of the age of consent in your
                  jurisdiction),(b) the information you submit is truthful and
                  accurate; (c) your use of the Services do not violate any
                  applicable law or regulation; (d) you are of sufficient legal
                  age or otherwise have the legal capacity to legally enter into
                  these Terms.
                </p>

                <h3>Updates to these Terms of Service</h3>

                <p>
                  We may modify these Terms of Service from time to time.  We
                  will notify you of material changes to these Terms of Service
                  by posting the amended terms on the Services before the
                  effective date of the changes.  In addition, you will be
                  required to accept the new Terms of Service the first time you
                  visit the Service and log in to your user account after the
                  new Terms of Service take effect.  If you do not agree with
                  the proposed changes, you should discontinue your use of the
                  Service prior to the time the new Terms of Service take
                  effect.  If you continue using the Service after the new terms
                  take effect, you will be bound by the modified Terms of
                  Service.
                </p>

                <h3>Privacy Policy</h3>

                <p>
                  In connection with your use of the Service, please review our
                  Privacy Policy to understand how we use the information we
                  collect from you when you access, visit or use the Service.
                  The Privacy Policy is a part of these Terms, and by agreeing
                  to these Terms, you agree that we may use information
                  collected from or about you in accordance with the Privacy
                  Policy.
                </p>

                <h4>Account Registration</h4>

                <p>
                  To access certain features of our Services, you may be
                  required to register for an account with our Services. You can
                  sign up for an account by completing the registration process
                  when prompted in our App.  If you register an account, you
                  agree: (a) to provide true, accurate, current and complete
                  information about yourself, whether provided by entering
                  information in the Service’s registration form or by
                  authorizing your social media account to provide us with your
                  information (the “Registration Data”); (b) to maintain and
                  promptly update the Registration Data to keep it true,
                  accurate, current and complete; and (c) that you will not
                  engage in any of the prohibited activities in Section 5 below.
                </p>

                <p>
                  You agree that you will not share your account login and
                  password and are responsible for maintaining their
                  confidentiality. You assume all responsibility for actions
                  taken under your account, including any financial liability
                  incurred.  As part of your member account, you will be able to
                  create a user profile, sync your activity across devices, and
                  access certain features restricted to registered members only.
                </p>

                <h4>Prohibited Activities</h4>

                <p>
                  You agree that you in connection with your use of the
                  Services, you will not:
                </p>

                <ol>
                  <li>
                    use the Services for any improper, illegal, or unauthorized
                    purpose, including collecting usernames and/or email
                    addresses of other users by electronic or other means, using
                    the system to send unsolicited or commercial emails or other
                    communications, or engaging in unauthorized framing,
                    mirroring, or linking to, the Services without our express
                    written consent;
                  </li>
                  <li>
                    post, upload, publish, submit or transmit any Content that:
                    (i) infringes, misappropriates or violates a
                    third-party&rsquo;s patent, copyright, trademark, trade
                    secret, moral rights or other intellectual property rights,
                    or rights of publicity or privacy; (ii) violates, or
                    encourages any conduct that would violate any applicable law
                    or regulation or would give rise to civil liability; (iii)
                    is fraudulent, false, misleading or deceptive; (iv) is
                    defamatory, obscene, pornographic, vulgar or offensive; (v)
                    promotes discrimination, bigotry, racism, hatred, harassment
                    or harm against any individual or group; (vi) is violent or
                    threatening or promotes violence or actions that are
                    threatening to any person or entity, or (vii) promotes
                    illegal or harmful activities or substances;
                  </li>
                  <li>
                    take any action that would interfere with, disrupt, or
                    create an undue burden on the Service or the networks,
                    infrastructure, or services connected to the Services;
                  </li>
                  <li>
                    use any meta tags, &ldquo;hidden text,&rdquo; agents,
                    robots, scripts, spiders, crawlers or other tools or means,
                    whether manual or automated, to collect, retrieve, scrape,
                    index, mine, republish, redistribute, transmit, sell,
                    license, download, access or manage the Services;
                  </li>
                  <li>
                    distribute, transmit, distribute, reproduce, or otherwise
                    make available any software viruses, malware, program, code,
                    file, or other technology or material intended to interfere
                    with or cause harm to the Services or users of the Services;
                  </li>
                  <li>
                    impersonate any other person or entity, sell or let others
                    use your profile or password, or provide false or misleading
                    identification or address information;
                  </li>
                  <li>
                    decompile, disassemble, modify, translate, adapt, reverse
                    engineer, create derivative works from or sublicense the
                    Service, or any portion thereof;
                  </li>
                  <li>
                    violate or interfere with any rights of us, our users, or
                    any other third parties, including intellectual property,
                    privacy, or publicity rights; or
                  </li>
                  <li>
                    circumvent, disable or otherwise interfere with
                    security-related features of the Services or features that
                    prevent or restrict the use, access, or copying, or to
                    enforce other limitations of use on the Services.
                  </li>
                </ol>

                <h4>Availability of the Services</h4>

                <p>
                  We do not make any guarantees with respect to the availability
                  of our Services. Our Services may be suspended temporarily
                  without notice to you for security purposes, maintenance or
                  repair, system failures, or other similar circumstances
                  (collectively, &ldquo;Service Interruptions&rdquo;). You
                  acknowledge and agree that you are not entitled to a refund or
                  rebate related to such Service Interruptions.
                </p>

                <h4>Termination</h4>

                <p>
                  Without limiting any other provision of these Terms, we
                  reserve the right to, in our sole discretion, and without
                  notice or liability, deny access to our Services to any person
                  for any reason, including, without limitation, for engaging in
                  the prohibited activities, breach of any representation,
                  warranty or covenant contained in these Terms, or breach of
                  any applicable law or regulation.
                </p>

                <h3>Subscriptions</h3>

                <h4>Payments and Fees</h4>

                <p>
                  The app offers certain premium features of the Services that
                  you can purchase as a monthly, yearly or lifetime subscription
                  (&ldquo;Subscription&rdquo;). You can find descriptions of the
                  features and benefits associated with the Subscriptions on the
                  App. To purchase a Subscription, you will have to provide a
                  valid, accepted Payment Method (e.g., debit or credit card) to
                  either the Apple App Store or Google Play Store. By submitting
                  such information, you agree and warrant that the Apple App
                  Store or Google Play Store has the right to use or provide the
                  information to third parties for payment processing. You may
                  be subject to additional terms and conditions imposed by Apple
                  or Google.
                </p>

                <h4>Automatically Renewing Subscriptions</h4>

                <p>
                  When you purchase a Subscription, your Subscription will
                  automatically renew based on the term you select (e.g., on a
                  monthly or yearly basis) until terminated. You authorize us to
                  charge your Payment Method up to 24 hours before the end of
                  the current subscription term. You can cancel your
                  Subscription in your Apple App Store or Google Play Store
                  settings at least 24 hours in advance of the end of your
                  current subscription term.
                </p>

                <h4>No Refunds</h4>

                <p>
                  Payments for Subscriptions are nonrefundable. We do not
                  provide partial refunds or credits for unused periods.
                  Following any cancellation, however, you will continue to have
                  access to the premium features of the Services associated with
                  the Subscription you purchased through the end of your
                  Subscription term.
                </p>

                <h4>Changes to the Price and Subscription Terms</h4>

                <p>
                  We reserve the right to change our Subscription terms or
                  pricing at any time, which we may determine in our sole
                  discretion. Any Subscription terms or pricing changes will not
                  apply retroactively and will only take effect following notice
                  to you.
                </p>

                <h4>Free Trial</h4>

                <p>
                  You may be offered a Free Trial of premium features associated
                  with a paid Subscription. Free Trial eligibility shall be
                  determined by us in our sole discretion, and we may limit
                  eligibility or duration to prevent abuse. At the end of your
                  Free Trial term, your subscription may automatically renew, as
                  stated in the notice that you accept at the time you sign up
                  for the Free Trial.
                </p>

                <h4>
                  For the European Union (&ldquo;EU&rdquo;) and United Kingdom
                  (&ldquo;UK&rdquo;) residents only.
                </h4>

                <p>
                  Notwithstanding the foregoing, if you are a resident of the EU
                  or UK: you have 14 days from the date you signed up for a
                  Subscription to cancel for any reason and receive a refund,
                  provided that we may charge you or withhold from your refund
                  the value of any Subscription used through your account during
                  such period. Please note that if you signed up for a Free
                  Trial, then the 14-day period runs from the date that you
                  signed up for the Free Trial.
                </p>

                <p>
                  To exercise this right, you must inform us of your unequivocal
                  decision to withdraw from your contract by email at
                  <a href={`mailto:${props?.auth?.webDetails?.website}`}>
                    {props?.auth?.webDetails?.website}
                  </a>
                  .
                </p>

                <h3>Intellectual Property Rights</h3>

                <p>
                  Our Services, including the underlying content and
                  information, are protected by copyright, trademarks, and other
                  intellectual property rights under the United States and
                  foreign laws and internationa and its licensors. Subject to
                  your compliance with these Terms, the app grants you a
                  limited, non-exclusive, non-transferable license to download
                  and install a copy of the App on a mobile device or computer
                  that you own or control and to run such copy of the App solely
                  for your own personal, non-commercial purposes. the app
                  reserves all rights in and to the App not expressly granted to
                  you under these Terms. You may not copy the App, except for
                  making one copy for backup or archival purposes. If you make a
                  copy for your own backup or archival purposes, you must retain
                  all trademark, copyright and other proprietary notices
                  contained in and on the Services.
                </p>

                <p>
                  If you submit any ideas, suggestions, feedback, or other
                  content to the app, you automatically grant to the app (or
                  warrant that the owner of such information and material has
                  expressly granted to the app) a royalty-free, worldwide,
                  perpetual, irrevocable, unrestricted, right and license to
                  use, copy, display, perform, modify, adapt, publish, and
                  distribute, or otherwise make available such content
                  (including any associated intellectual property rights), in
                  whole or in part, for any purpose.
                </p>

                <h3>Third-Party App Stores</h3>

                <p>
                  You acknowledge and agree that the availability of the
                  Services may be dependent on third-party websites from which
                  you download the Service, e.g., the Apple App Store or Google
                  Play Store (each a “Third-Party App Store”).  You acknowledge
                  that these Terms of Service are between you and the app and
                  not with the applicable Third-Party App Store.  Each
                  Third-Party App Store may have its own terms and conditions to
                  which you must agree before downloading the Service from it.
                  You agree to comply with, and your license to use the Service
                  is conditioned upon, your compliance with, the applicable
                  Third-Party App Store terms and conditions.  To the extent
                  that other terms and conditions from the applicable
                  Third-Party App Store are less restrictive than, or otherwise
                  conflict with, the terms and conditions of these Terms of
                  Service, the more restrictive or conflicting terms and
                  conditions in these Terms of Service will apply.
                </p>

                <h3>Third-Party Sites</h3>

                <p>
                  The Service may contain links to websites operated by third
                  parties (“Third-Party Sites”).  For example, you may be able
                  to share information with Third-Party Sites through links on
                  the Services; however, we do not own or operate the
                  Third-Party Sites, and we have not reviewed, and cannot
                  review, all of the material, including goods or services, made
                  available through Third-Party Sites.  The availability of
                  these links on the Services does not represent, warrant or
                  imply that we endorse any Third-Party Sites or any materials,
                  opinions, goods or services available on them. Third-Party
                  materials accessed through or used by means of the Third-Party
                  Sites may also be protected by copyright and other
                  intellectual property laws.
                </p>

                <p>
                  These terms of service do not apply to third-party sites.
                  Before visiting a Third-Party Site through links or other
                  means provided on or through the service, you should review
                  the Third-Party Site’s terms and conditions and privacy
                  policy, and inform yourself of the regulations, policies, and
                  practices of these Third-Party Sites.
                </p>

                <h3>Legal Disputes and Arbitration Agreement</h3>

                <p>
                  <strong>
                    Please Read This Following Clause Carefully — It May
                    Significantly Affect Your Legal Rights, Including Your Right
                    to File a Lawsuit in Court
                  </strong>
                </p>

                <p style={{ textAlign: "center" }}>
                  Initial Dispute Resolution.
                </p>
                <p>
                  We are available by email at
                  <a href={`mailto:${props?.auth?.webDetails?.website}`}>
                    {props?.auth?.webDetails?.website}
                  </a>
                  to address any concerns you may have regarding your use of the
                  Service.  Most concerns may be quickly resolved in this
                  manner. Both you and the Company agree to use best efforts to
                  settle any dispute, claim, question, or disagreement directly
                  through consultation and good faith negotiations which shall
                  be a precondition to either party initiating a lawsuit or
                  arbitration.
                </p>

                <h3>Agreement to Binding Arbitration</h3>

                <p>
                  If we do not reach an agreed-upon solution within a period of
                  thirty (30) days from the time informal dispute resolution is
                  pursued pursuant to section 11(A) above, then either party may
                  initiate binding arbitration.  All claims arising out of or
                  relating to these Terms of Service (including its formation,
                  performance and breach), the parties’ relationship with each
                  other and/or your use of the Service shall be finally settled
                  by binding arbitration administered on a confidential basis by
                  the American Arbitration Association in accordance with the
                  provisions of the Consumer Arbitration Rules of the American
                  Arbitration Association (the “AAA”), excluding any rules or
                  procedures governing or permitting class actions.  The
                  arbitrator, and not any federal, state or local court or
                  agency, shall have exclusive authority to resolve all disputes
                  arising out of or relating to the interpretation,
                  applicability, enforceability or formation of these Terms of
                  Service, including, but not limited to, any claim that all or
                  any part of these Terms of Service is void or voidable.  The
                  arbitrator shall be empowered to grant whatever relief would
                  be available in a court under law or in equity.  The
                  arbitrator’s award shall be binding on the parties and may be
                  entered as a judgment in any court of competent jurisdiction.
                  The interpretation and enforcement of these Terms of Service
                  shall be subject to the Federal Arbitration Act.  The seat of
                  the arbitration will be California,United States.
                </p>

                <p>
                  You and the Company each further agree that any arbitration
                  shall be conducted in your respective individual capacities
                  only and not as a class action or other representative action,
                  and you and the Company each expressly waive your respective
                  right to file a class action or seek relief on a class basis.
                  If any court or arbitrator determines that the class action
                  waiver set forth in this paragraph is void or unenforceable
                  for any reason or that arbitration can proceed on a class
                  basis, then the arbitration provision set forth above in
                  Section 12(B) shall be deemed null and void in its entirety
                  and the parties shall be deemed to have not agreed to
                  arbitrate disputes.
                </p>

                <h4>General Terms</h4>

                <h4>Non-Waiver</h4>

                <p>
                  Our failure to exercise or enforce any right or provision of
                  these Terms of Service shall not operate as a waiver of the
                  applicable right or provision.
                </p>

                <h4>Severability</h4>

                <p>
                  These Terms of Service operate to the fullest extent
                  permissible by law.  If any provision or part of a provision
                  of these Terms of Service is unlawful, void, or unenforceable,
                  that provision or part of the provision is deemed severable
                  from these Terms of Service and shall not affect the validity
                  and enforceability of any remaining provisions.
                </p>

                <h4>Assignment</h4>

                <p>
                  You may not assign or transfer these Terms, by operation of
                  law or otherwise, without our prior written consent. Any
                  attempt by you to assign or transfer these Terms, without such
                  consent, will be null and of no effect. We may freely assign
                  or transfer these Terms without restriction. Subject to the
                  foregoing, these Terms will bind and inure to the benefit of
                  the parties, their successors and permitted assigns.
                </p>

                <h4>No Modifications by Our Employees</h4>

                <p>
                  If any of our employees offers to modify the terms of these
                  Terms of Service, he or she is not acting as an agent for us
                  or speaking on our behalf.  You may not rely upon, and should
                  not act in reliance on, any statement or communication from
                  our employees or anyone else purporting to act on our behalf.
                </p>

                <h4>Contact Information.</h4>

                <p>
                  If you have any questions about these Terms of Service or your
                  account, please contact us at
                  <a href={`mailto:${props?.auth?.webDetails?.website}`}>
                    {props?.auth?.webDetails?.website}
                  </a>
                  .
                </p>
              </div>
            </div>
          </section>
        </main>

        <footer>
          <div className="container">
            <p>© 2023 Workplate. All rights reserved.</p>
          </div>
        </footer>
      </div>
    </>
  );
};

const mapStateToProps = state => {
  return {
    ...state,
    websiteDetails: { ...state.auth.webDetails }
  };
};

export default compose(withRouter, connect(mapStateToProps))(TermsOfService);

// export default TermsOfService;
